<template>
  <div>
    <div class=" relative h-[305px] shadow-chart">
      <div v-show="is_getting_weather_data" class="blink_loading"></div>
      <div
        id="inshore_chart"
        v-if="weather_data_list?.length"
        class="h-full"
      ></div>
      <div v-else class="h-full flex items-center justify-center">
        暫無資料
      </div>
    </div>
  </div>
</template>

<script>
import { inject, markRaw, ref, watch } from 'vue'
import { useInshoreChart } from '@/compositions/chart/inshore_chart'
import { cb_alert } from '@/libs/cb-theme'

export default {
  name: 'InshoreChart',

  setup(props) {
    const LayerStore = inject('LayerStore')
    const InshoreStore = inject('InshoreStore')

    const weather_data_list = ref(null)
    const is_getting_weather_data = ref(false)

    const get_weather_data_list = async (area_id) => {
      is_getting_weather_data.value = true
      try {
        let inshore_area_data = await InshoreStore.get_inshore_area_data(
          area_id
        )
        if (inshore_area_data) {
          weather_data_list.value = markRaw(inshore_area_data)
        }
      } catch {
        cb_alert('天氣資料載入失敗')
      }
      is_getting_weather_data.value = false
    }

    const inshore_chart = useInshoreChart('inshore_chart')

    watch(
      () => LayerStore.state.selected_target,
      (inshore_area) => {
        gtag('event', 'open_inshore_chart')
        get_weather_data_list(inshore_area.id)
      },
      {
        immediate: true,
      }
    )

    watch(weather_data_list, (_weather_data_list) => {
      inshore_chart.refresh_chart(_weather_data_list)
    })

    return {
      is_getting_weather_data,
      weather_data_list,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
