const render_gradient_colors = function(ctx, color_table, scale, fade_required=false) {
  // fade_required true: 顏色會往下淡出

  let grd = null

  grd = ctx.createLinearGradient(
    0, scale.canvas_range[0], 0, scale.canvas_range[1])

  for (let key in color_table) {
    let stop = scale.rate(key)
    // 因key並非由小而大照順序排列，若有多個為0或為1的stop會覆蓋前一個顏色，造成漸層不連續
    // 因此只取 0 < stop < 1 的顏色
    if (stop > 0 && stop < 1) {
      if (color_table[key].split(',').length > 1) {
        if (fade_required) {
          grd.addColorStop(stop, `rgba(${color_table[key]}, ${stop})`)
        }
        else {
          grd.addColorStop(stop, `rgb(${color_table[key]})`)
        }
      }
      else {
        if (fade_required) {
          console.warn("If you need area fade out, color must match this format: 'r, g, b'.");
          grd.addColorStop(stop, color_table[key])
        }
        else {
          grd.addColorStop(stop, color_table[key])
        }
      }
    }
  }
  return grd
}


export {
  render_gradient_colors
}
