import { watch } from 'vue'

const ICON_IMG_URL = require('@/assets/imgs/tide_station.png')

export const useTideVLayer = (map, DetailStore, TideStore) => {
  const layer_group = L.featureGroup().addTo(map)

  const icon = L.icon({
    iconUrl: ICON_IMG_URL,
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })

  const icon_in_collections = L.icon({
    iconUrl: ICON_IMG_URL,
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
    shadowUrl: require('@/assets/imgs/check_hook.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 20],
  })

  const draw_marker = (features) => {
    layer_group.clearLayers()
    if (features) {
      for (let stn of features) {
        let station_marker = L.marker(
          {lat: stn.lat, lon: stn.lon},
          {icon: get_icon(stn.unique_key), shadowPane: 'favoritesPane'}
        ).addTo(layer_group)
        station_marker.setZIndexOffset(1000)
        station_marker.on("click", (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return
          if (is_checked(stn.unique_key)) {
            DetailStore.remove_temp_selected_features(stn)
            station_marker.setIcon(icon)
          } else {
            DetailStore.add_temp_selected_features(stn)
            station_marker.setIcon(icon_in_collections)
          }
        })
      }
    }
  }

  if (!TideStore.state.features) {
    TideStore.get_features()
  }

  const is_checked = (unique_key) => {
    return DetailStore.state.temp_unique_key_properties_mapping[unique_key] ? true : false
  }

  const get_icon = unique_key => {
    if (is_checked(unique_key)) {
      return icon_in_collections
    }
    return icon
  }

  watch(() => TideStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate: true
  })
}
