import { watch, computed, nextTick } from 'vue'

import { round_to_five } from '../../libs/utils'

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `cctv,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `cctv,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}

export const useCctvVLayer = (map, LayerStore, CctvStore, FavoritesStore) => {
  const layer_group = L.featureGroup()

  const Icon = L.Icon.extend({
    options: {
      iconSize: [36, 36],
      iconAnchor: [18, 18],
    }
  })

  const FavoriteIcon = L.Icon.extend({
    options: {
      shadowUrl: require('@/assets/imgs/love--click.png'),
      shadowSize: [20, 20],
      iconSize: [36, 36],
      shadowAnchor: [0, 20],
      iconAnchor: [18, 18],
    }
  })

  /*
  目前提供四種不同類型的圖示，分別為
  1. 可以設最愛的交通
  2. 不可以設最愛的交通
  3. 可以設最愛的水利署
  4. 不可以設最愛的景點
  */

  const traffic_icon = new Icon({iconUrl: require('@/assets/imgs/CCTV_traffic.png')})
  const cannot_added_traffic_icon = new Icon({iconUrl: require('@/assets/imgs/CCTV_traffic--cannot_be_favor.png')})
  const wra_icon = new Icon({iconUrl: require('@/assets/imgs/CCTV_WRA.png')})
  const cannot_added_spot_icon = new Icon({iconUrl: require('@/assets/imgs/CCTV_attractions--cannot_be_favor.png')})
  
  // 加入到我的最愛的圖示
  const traffic_icon_in_favorites = new FavoriteIcon({iconUrl: require('@/assets/imgs/CCTV_traffic.png')})
  const wra_icon_in_favorites = new FavoriteIcon({iconUrl: require('@/assets/imgs/CCTV_WRA.png')})

  const org_use_mapping = {
    // Divide uses into three categories, traffic, spot, wra
    "台北市": 'traffic',
    "新北市": 'traffic',
    "桃園市": 'traffic',
    "宜蘭": 'traffic',
    "台中市": 'traffic',
    "彰化縣": 'traffic',
    "雲林縣": 'traffic',
    "嘉義市": 'traffic',
    "嘉義縣": 'traffic',
    "台南市": 'traffic',
    "高雄市": 'traffic',
    "台東縣": 'traffic',
    "公路總局": 'traffic',
    "高速公路局": 'traffic',
    "水利署": 'wra',
    "河川局": 'wra',
    null: 'spot',
  }

  const get_icon = (use, method) => {
    if (use === "wra") {
      return wra_icon
    }
    else if (use === "spot") {
      return cannot_added_spot_icon
    }
    else if (use === "traffic") {
      if (method === "FIXED_IMG") {
        return traffic_icon
      }
      else if (method === "STREAMING_IMG") {
        return traffic_icon
      }
      else if (method === "IFRAME") {
        return cannot_added_traffic_icon
      }
    }
  }

  const get_favorite_icon = (use) => {
    if (use === "wra") {
      return wra_icon_in_favorites
    }
    else if (use === "traffic") {
      return traffic_icon_in_favorites
    }
  }

  const marker_layer_map = new Map()

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-cctv/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-cctv text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      features.forEach(element => {
        const unique_key = get_unique_key(element)
        const org = element.org
        const method = element.method

        const marker = L.marker({
          lat: element.lat,
          lon: element.lon
        }, {
          icon: get_icon(org_use_mapping[org], method),
          shadowPane: 'favoritesPane'
        }).on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          LayerStore.set_selected_target({
            'id': element.id,
            'type': 'cctv',
            'name': element.name,
            'lat': element.lat,
            'lon': element.lon,
            'unique_key': unique_key
          })
        }).addTo(cluster_group)
        marker_layer_map.set(unique_key, {"marker": marker, "org": org, "method": method})
      })

      layer_group.addLayer(cluster_group)
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  const refresh_icons = () => {
    marker_layer_map.forEach((info, key) => {
      const obj = favorites_mapping.value[key]
      let marker = info.marker
      let org = info.org
      let method = info.method
      if (obj) {
        marker.setIcon(get_favorite_icon(org_use_mapping[org]))
      } else {
        marker.setIcon(get_icon(org_use_mapping[org], method))
      }
    })
  }

  const favorites_mapping = computed(() => FavoritesStore.state.favorites_mapping)

  watch(() => LayerStore.state.checked_layers.cctv, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate:true
  })

  watch(() => LayerStore.state.selected_target, async (target) => {
    await nextTick()
    if (target?.type === 'cctv') {
      layer_group.eachLayer(cctvlayer => {
        cctvlayer.eachLayer(layer =>{
          let latlon = layer.getLatLng()
          if (latlon.lat == target.lat && latlon.lng == target.lon) {
            cctvlayer.zoomToShowLayer(layer, ()=>{
              map.panBy([1, 1])
            })
          }
        })
      })
    }
  }, {
    immediate:true
  })

  watch(() => CctvStore.state.features, (_features) => {
    draw_marker(_features)
    refresh_icons()
  }, {
    immediate:true
  })

  watch(favorites_mapping, () => {
    refresh_icons()
  })
}
