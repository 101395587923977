import { MarineRepo } from '@/repositories/marine.js'

import { reactive, readonly, provide, watch, computed, markRaw } from 'vue'

export const useTideStore = (LayerStore) => {
  const state = reactive({
    features: null,
  })

  const get_features = async () => {
    try {
      let payload = await MarineRepo.station.get({
        category: 0
      })
      state.features = markRaw(payload.data)
    }
    finally {
      set_layer_loading(false)
    }
  }

  const get_tide_station_data = async (station_id) => {
    let payload = await MarineRepo.station_data.get({
      station_id: station_id,
      from: new Date().zero_minute().addHours(-6).isostrz(),
      to: new Date().zero_minute().isostrz()
    })

    for (let data of payload.data) {
      if (data.dtime) {
        data.dtime = new Date(data.dtime)
      }
    }
    return payload.data[payload.data.length - 1]
  }

  const set_layer_loading = (val) => LayerStore.set_loading(val, 'tide')

  /* watch */
  watch(() => LayerStore.state.checked_layers.tide, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })


  const store = {
    state: readonly(state),
    get_tide_station_data,
    get_features
  }

  provide('TideStore', store)

  return store
}
