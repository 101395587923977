import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { InshoreRepo }from '@/repositories/inshore'

export const useInshoreStore = (LayerStore) => {
  const state = reactive({
    features: null
  })

  const get_inshore_area_data = async (area_id) => {
    let response = await InshoreRepo.inshore_area_data.get({
      "area_id": area_id
    })
    datetime_format(response.data)
    return response.data
  }

  const datetime_format = (datas) => {
    for (let detail_data of datas) {
      if (detail_data.time) {
        detail_data.time = new Date(detail_data.time)
      }
    }
    return datas
  }

  const get_inshore_areas = async () => {
    set_loading(true)
    try {
      let payload = await InshoreRepo.inshore_areas.get()
      state.features = markRaw(payload.data)
    }
    finally {
      set_loading(false)
    }
  }

  const set_loading = (val) => LayerStore.set_loading(val, 'inshore')

  watch(() => LayerStore.state.checked_layers.inshore, (is_checked) => {
    if (is_checked && !state.features) {
      get_inshore_areas()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    get_inshore_area_data,
    get_inshore_areas
  }

  provide('InshoreStore', store)

  return store
}
