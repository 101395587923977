<template>
  <div
    class="safe_padding_top h-full pb-[60px] md:pb-[90px] overflow-y-auto scrollbar-hidden"
  >
    <div
      class="relative w-full p-1 flex justify-center items-center flex-wrap gap-2 py-2 px-10 md:py-1"
    >
      <div
        class="vertical_center left-2 cursor-pointer"
        @click="$router.go(-1)"
      >
        <i class="icon-symbol_left text-2xl"></i>
      </div>
      <div class="rounded-lg p-1 flex justify-center gap-2">
        <Datepicker
          inline
          autoApply
          v-model="selected_date"
          :maxDate="new Date()"
          :minDate="new Date().addDays(-365)"
          :yearRange="[new Date().getFullYear() - 1, new Date().getFullYear()]"
          :enableTimePicker="false"
          format="yyyy/MM/dd"
        ></Datepicker>
      </div>
    </div>
    <div class=" relative h-[287px] shadow-chart">
      <div v-show="is_chart_loading" class="blink_loading"></div>
      <div
        id="station_stage_container"
        class=" relative w-full h-[300px] overflow-x-auto"
      >
        <canvas id="station_stage_chart" class=" my-4 mx-2"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import {
  onBeforeUnmount,
  ref,
  computed,
  watch,
  inject,
  reactive,
  toRefs,
  nextTick,
  onMounted,
} from 'vue'
import 'chartjs-adapter-moment'
import { Tooltip } from 'chart.js'
import { useRoute } from 'vue-router'

export default {
  name: 'WaterStageChart',

  setup(props) {
    const WaterStationStore = inject('WaterStationStore')
    const station_stage_data = WaterStationStore.state.data

    const route = useRoute()
    const station_code = route.params.id

    let today_and_yesterday_stage = computed(() => WaterStationStore.state.yesterday2today_data)
    let shift_today_and_yesterday_stage = {
      obs: [],
    }

    const selected_date = ref(new Date())
    const is_chart_loading = ref(false)

    const get_yesterday2now_station_stage = async () => {
      let dt_to = new Date().zero_hour().addHours(23)
      let dt_from = dt_to.addDays(-1).zero_hour()
      is_chart_loading.value = true
      await WaterStationStore.get_yesterday2now_station_stage(
        station_code,
        dt_from,
        dt_to
      )
      is_chart_loading.value = false
    }

    let history_satation_stage = ref({})
    const get_history_station_stage_data = async () => {
      is_chart_loading.value = true
      let dt_from = selected_date.value.addDays(-1).zero_hour()
      let dt_to = new Date(selected_date.value.zero_hour().setHours(23))

      const data = await WaterStationStore.get_station_stage(station_code, dt_from, dt_to)
      is_chart_loading.value = false
      history_satation_stage.value = data.obs
    }

    watch(
      selected_date,
      (_selected_date) => {
        gtag('event', 'view_history_water_stage')
        get_history_station_stage_data()
      }
    )

    const get_chart_stage_mode = () => {
      let mode = null
      if (station_stage_data.stage_mode) {
        mode = station_stage_data.stage_mode
      }
      let data = []
      let end = selected_date.value.zero_hour().addDays(1)
      let start = selected_date.value.zero_hour().addDays(-1)
      data = [
        {
          x: start,
          y: mode,
        },
        {
          x: end,
          y: mode,
        },
      ]
      return data
    }

    const warning_levls = WaterStationStore.state.features.find(e => e.station_code == station_code)

    const get_chart_warning_lv1 = () => {
      let data = []
      let end = selected_date.zero_hour().addDays(1)
      let start = selected_date.zero_hour().addDays(-1)
      data = [
        {
          x: start,
          y: warning_levls.warning_lv1,
        },
        {
          x: end,
          y: warning_levls.warning_lv1,
        },
      ]
      return data
    }

    const get_chart_warning_lv2 = () => {
      let data = []
      let end = selected_date.zero_hour().addDays(1)
      let start = selected_date.zero_hour().addDays(-1)
      data = [
        {
          x: start,
          y: warning_levls.warning_lv2,
        },
        {
          x: end,
          y: warning_levls.warning_lv2,
        },
      ]
      return data
    }

    const get_chart_warning_lv3 = () => {
      let data = []
      let end = selected_date.value.zero_hour().addDays(1)
      let start = selected_date.value.zero_hour().addDays(-1)
      data = [
        {
          x: start,
          y: warning_levls.warning_lv3,
        },
        {
          x: end,
          y: warning_levls.warning_lv3,
        },
      ]
      return data
    }

    const _get_chart_obs_data = () => {
      let data = []
      if (station_stage_data.obs.length) {
        station_stage_data.obs.forEach((obs) => {
          data.push({
            x: obs.dtime,
            y: obs.stage,
          })
        })
      }
      return data
    }

    const _get_chart_label = () => {
      let end = selected_date.value.zero_hour().addDays(1)
      let start = selected_date.value.zero_hour().addDays(-1)
      let label = [start, end]
      return label
    }

    const _get_chart_today_data = () => {
      let data = []
      if (shift_today_and_yesterday_stage.obs.length) {
        shift_today_and_yesterday_stage.obs.forEach((obs) => {
          data.push({
            x: obs.dtime,
            y: obs.stage,
          })
        })
      }
      return data
    }

    const _get_chart_data = () => {
      let datasets = []
      let options = {
        obs: {
          backgroundColor: '#4285F4',
          borderColor: '#4285F4',
        },
        today: {
          backgroundColor: 'rgba(153, 225, 225, 0.3)',
          borderColor: 'rgba(153, 225, 225, 0.3)',
        },
      }
      if (station_stage_data.obs && station_stage_data.obs.length) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: options.obs.backgroundColor,
          borderColor: options.obs.borderColor,
          data: _get_chart_obs_data(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      if (shift_today_and_yesterday_stage.obs) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: options.today.backgroundColor,
          borderColor: options.today.borderColor,
          data: _get_chart_today_data(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      if (station_stage_data.warning_lv1) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: '#EA0000',
          borderColor: '#EA0000',
          data: get_chart_warning_lv1(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      if (station_stage_data.warning_lv2) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: '#FF8000',
          borderColor: '#FF8000',
          data: get_chart_warning_lv2(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      if (station_stage_data.warning_lv3) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: '#F9F900',
          borderColor: '#F9F900',
          data: get_chart_warning_lv3(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      if (station_stage_data.stage_mode) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: 'black',
          borderColor: 'black',
          data: get_chart_stage_mode(),
          fill: false,
          borderDash: [6, 6],
          pointRadius: 0,
          borderWidth: 1.5,
          pointHitRadius: 5,
        })
      }

      let labels = _get_chart_label()

      return {
        labels: labels,
        datasets: datasets,
      }
    }

    const _get_yaxis_min = (datas) => {
      let today_and_yesterday_stage = shift_today_and_yesterday_stage.obs
      let stage_mode = station_stage_data.stage_mode
      let warning_lv1 = warning_levls.warning_lv1
      let warning_lv2 = warning_levls.warning_lv2
      let warning_lv3 = warning_levls.warning_lv3
      let total_stage_list = [
        warning_lv1,
        warning_lv2,
        warning_lv3,
        stage_mode,
      ]

      // 將觀測與計算水位的最小值進行比較，取得y軸的最小值
      let min_value = null
      if (datas.length) {
        total_stage_list.push(datas.reduce((a, b) => (a.y < b.y ? a : b)).y)
      }
      if (today_and_yesterday_stage.length) {
        total_stage_list.push(
          today_and_yesterday_stage.reduce((a, b) =>
            a.stage < b.stage ? a : b
          ).stage
        )
      }

      // 年平均水位需求恆顯示， 所以再跟最小值做比較 如果小於最小值就取代
      if (total_stage_list.length) {
        let _total_stage_list = total_stage_list.filter((x) => x)
        min_value = Math.min(..._total_stage_list)
      }

      if (min_value) {
        let float_value = min_value % 1
        if (min_value > 0) {
          return min_value - float_value
        } else {
          let mod = 1 + float_value
          return min_value - mod
        }
      }
      return 0
    }

    const _get_yaxis_max = (datas) => {
      let stage_mode = station_stage_data.stage_mode
      // 將觀測與計算水位的最大值進行比較，取得y軸的最大值
      let max_value = null
      if (datas.length) {
        max_value = datas.reduce((a, b) => (a.y > b.y ? a : b)).y
      }
      // 年平均水位需求恆顯示，所以再跟最大值比較 如果超過最大值就取代
      if (max_value < stage_mode) {
        max_value = stage_mode
      }

      if (max_value) {
        let float_value = max_value % 1
        let mod = 1 - float_value
        return max_value + mod
      }
      return 0
    }

    const define_tooltips_position = () => {
      Tooltip.positioners.custom = function(elements, position) {
        if (!elements.length) {
          return false
        }
        return {
          x: position.x,
          y: 0,
        }
      }
    }

    let chart = null
    const draw_chart = () => {
      if (document.getElementById('station_stage_chart') === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 station_stage_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 station_stage_chart element 不存在的狀況，防止 console 跳錯。
        return
      }
      // 畫圖前須先清空 chart
      if (chart !== null) {
        chart.destroy()
      }
      let ctx = document.getElementById('station_stage_chart').getContext('2d')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth

      let obs_data = _get_chart_obs_data()

      let config = {
        type: 'line',
        data: _get_chart_data(),

        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 10,
            },
          },
          interaction: {
            intersect: false,
          },
          plugins: {
            tooltip: {
              caretSize: 0,
              position: 'custom',
              callbacks: {
                title: () => {
                  return ''
                },
                label: function(tooltipItem) {
                  let time = new Date(tooltipItem.label).strftime('%H:%M')
                  let stage = tooltipItem.formattedValue
                  return `${time} ${stage}m`
                },
              },
            },
            legend: {
              display: false,
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  day: 'MM/DD ddd',
                },
                unit: 'day',
              },
            },
            y: {
              min: _get_yaxis_min(obs_data),
              max: _get_yaxis_max(obs_data),
              grid: {
                color: '#ccc',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              position: 'right',
            },
          },
        },
      }

      define_tooltips_position()
      chart = new Chart(ctx, config)
    }

    watch(
      history_satation_stage,
      async () => {
        await nextTick()
        let today = new Date().zero_hour().addHours(23)
        let _selected_date = selected_date.value.zero_hour().addHours(23)
        if (
          _selected_date.equal(WaterStationStore.state.history_selected_time)
        ) {
          let dt_offset = parseInt(
            (_selected_date - today) / 1000 / 60 / 60 / 24
          )
          if (today_and_yesterday_stage.value) {
            shift_today_and_yesterday_stage.obs = []
            for (let data of today_and_yesterday_stage.value) {
              shift_today_and_yesterday_stage.obs.push({
                station_code: data.station_code,
                stage: data.stage,
                dtime: data.dtime.addDays(dt_offset),
              })
            }
            draw_chart()
          }
        }
      },
      {
        deep: true,
      }
    )

    onMounted(async () => {
      await get_yesterday2now_station_stage()
      get_history_station_stage_data()
    })

    onBeforeUnmount(() => {
      if (chart !== null) {
        chart.destroy()
      }
    })

    return {
      selected_date,
      is_chart_loading
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
