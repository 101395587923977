import { reactive, readonly } from 'vue'

import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'


const uuid = () => {
  var d = Date.now();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


export const useDeviceControl = (AlertStore, AnnouncementStore, RadarStore, WindyStore, WeatherDataStore) => {
  const state = reactive({
    device_id: null,
    platform: Capacitor.getPlatform()
  })

  const fetch_all = () => {
    AlertStore.fetch_alerts()
    AnnouncementStore.fetch_announcements()
    RadarStore.refetch_radar_data()
    WindyStore.refetch_windy_data()
    WeatherDataStore.fetch_typhoon_status()
  }

  // 網頁重新 focus 的時候或是 app 重新啟動的時候 去撈所有需要更新的資料
  if (state.platform === 'web') {
    // set device_id
    let device_id = localStorage.getItem('device_id')
    if (device_id) {
      state.device_id = device_id
    } else {
      state.device_id = uuid()
      localStorage.setItem('device_id', state.device_id)
    }

    // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    // if document[hidden] is truthy, means the page is hidden
    // if document[hidden] is falsy, means if the page is shown
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        fetch_all()
      }
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
      // Handle page visibility change
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }

  } else {
    Device.getId().then((_device_id) => {
      state.device_id = _device_id.uuid
    })

    App.addListener('appStateChange', state => {
      // state.isActive contains the active state
      if (state.isActive) {
        fetch_all()
      }
    });
  }

  return {
    state: readonly(state)
  }
}
