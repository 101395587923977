<template>
  <div
    class="flex h-[44px] bg-title flex items-center"
  >
    <div
      class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
      @click="DetailStore.set_station_visible(feature.unique_key, !total_station_visible[feature.unique_key])"
      :class="total_station_visible[feature.unique_key] ? 'icon-symbol_subtract' : 'icon-symbol_add'"
      >
    </div>
    <img class="ml-4 h-7 w-7" :src="get_icon_class(feature.type_en)" />
    <div class="w-full my-4 mx-2">
      {{ feature.name }}
    </div>
    <div
      class="mr-4 icon-delete cursor-pointer"
      @click="DetailStore.delete_feature(feature.unique_key)"
    ></div>
  </div>
  <div
    class=" relative h-[305px] shadow-chart"
    v-show="total_station_visible[feature.unique_key]"
  >
    <div v-show="total_station_loading[feature.unique_key]" class="blink_loading"></div>
    <div
      :id="feature.unique_key + '_inshore_chart'"
      v-if="weather_data_list?.length"
      class="h-full"
    ></div>
    <div v-else class="h-full flex items-center justify-center">
      暫無資料
    </div>
  </div>
</template>

<script>
import { computed, inject, markRaw, ref, watch } from 'vue'
import { useInshoreChart } from '@/compositions/chart/inshore_chart'
import { cb_alert } from '@/libs/cb-theme'

export default {
  name: 'DetailInshoreChart',

  props: ['feature'],
  setup(props) {
    const feature = props.feature
    const DetailStore = inject('DetailStore')
    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const total_station_loading = computed(() => DetailStore.state.total_station_loading)

    const weather_data_list = computed({
      get: () => DetailStore.state.total_station_data.find(station_data => station_data.unique_key === feature.unique_key)?.data
    })

    const inshore_chart = useInshoreChart(`${feature.unique_key}_inshore_chart`)

    watch([weather_data_list, () => total_station_visible.value[feature.unique_key]], ([_weather_data_list, visible]) => {
      if (visible) {
        inshore_chart.refresh_chart(_weather_data_list)
        DetailStore.set_chart_loading(feature.unique_key, false)
      }
    })

    const get_icon_class = (type) => {
      if (type === 'pin') {
        return require('@/assets/imgs/map_pin--click.png')
      } else if (type === 'cctv') {
        return require('@/assets/imgs/CCTV.png')
      } else if (type === 'tidal') {
        return require('@/assets/imgs/tidal.png')
      } else if (type === 'water_station') {
        return require('@/assets/imgs/water_stage.png')
      } else if (type === 'rain_station') {
        return require('@/assets/imgs/precipitation.png')
      } else if (type === 'tide_station') {
        return require('@/assets/imgs/tide_station.png')
      } else if (type === 'buoy') {
        return require('@/assets/imgs/buoy.png')
      } else if (type === 'inshore_area') {
        return require('@/assets/imgs/layer--wave.png')
      }
    }

    return {
      DetailStore,
      feature,
      total_station_visible,
      total_station_loading,
      get_icon_class,
      weather_data_list
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
