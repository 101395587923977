<template>
  <div class="safe_padding_top h-full overflow-scroll">
    <div
      class="font-bold p-2 flex justify-between items-center"
      :class="main_page ? 'bg-primary text-white' : 'bg-gray-100'"
    >
      <div :style="main_page ? { visibility: 'hidden' } : {}">
        <ReturnButton></ReturnButton>
      </div>
      <div>
        <slot name="title"></slot>
      </div>
      <div class="py-1 px-4 text-lg cursor-pointer" @click.stop="close_method">
        <i class="icon-symbol_close"> </i>
      </div>
    </div>

    <main ref="main" class="max-w-[600px] mx-auto px-4">
      <slot name="main"></slot>
    </main>
  </div>
</template>

<script>
import ReturnButton from '@/components/menu/ReturnButton'
import { onMounted, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'Layout',
  components: {
    ReturnButton,
  },

  props: {
    main_page: {
      type: Boolean,
      default: false,
    },

    close_method: {
      type: Function,
      default: null,
    },
  },

  setup(props) {
    const route = useRoute()

    const route_name = ref('')
    const main_page = props.main_page
    onMounted(async () => {
      await nextTick
      route_name.value = route.name
    })

    const router = useRouter()
    const close_method = () => {
      if (props.close_method) {
        props.close_method()
      } else {
        router.push({ name: 'Home' })
      }
    }

    return {
      main_page,
      close_method,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
