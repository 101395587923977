<template>
  <cb-checkbox v-model="checked" :disabled="disabled">
    <div class="btn_primary--line btn_icon btn_loading">
      <div v-show="loading"></div>
      <i :class="layer_info.icon"></i>
    </div>
  </cb-checkbox>
</template>

<script>
import { inject, computed } from 'vue'

export default {
  props: ['layer_info'],

  setup(props) {
    const LayerStore = inject('LayerStore')

    return {
      checked: computed({
        get() {
          return LayerStore.state.checked_layers[props.layer_info.layer_type]
        },
        set(val) {
          LayerStore.set_checked(val, props.layer_info.layer_type)
        },
      }),
      loading: computed(
        () => LayerStore.state.loading_layers[props.layer_info.layer_type]
      ),
      disabled: computed(
        () => LayerStore.state.disabled_layers[props.layer_info.layer_type]
      ),
    }
  },
}
</script>

<style scoped lang="scss">
.btn_loading {
  position: relative;
}
.btn_loading div::after {
  position: absolute;
  top: 50%;
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  content: '\e906';
  animation: rotate_center 1s 0s ease-in-out infinite;
  border-radius: 9999px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  line-height: 20px;
  padding: 0.5rem;
  color: transparent;
  border-color: transparent;
  --tw-border-opacity: 1;
  border-top-color: rgba(246, 180, 85, var(--tw-border-opacity));
  border-width: 3px;
}
@keyframes rotate_center {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    border-style: dotted;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.btn_primary--line {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.btn_primary--line:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(63, 149, 166, var(--tw-text-opacity));
}
.peer:checked ~ .btn_primary--line {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 149, 166, var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.peer:disabled ~ .btn_primary--line {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
</style>
