import { computed, watch, reactive, readonly, provide, markRaw } from 'vue'

import { FavoritesRepo } from '@/repositories/favorites'
import { GeoRepo } from '@/repositories/map'
import { round_to_five } from '../../libs/utils'
import { Geolocation } from '@capacitor/geolocation'


export const useFavoritesStore = (DeviceControl) => {
  const state = reactive({
    favorites: [],
    favorites_mapping: {}
  })

  const add_favorites = async (selected_target) => {
    let data = {
      device_id: get_device_id(),
      name: selected_target.name,
      type: selected_target.type,
      lat: selected_target.lat,
      lon: selected_target.lon,
      unique_key: selected_target.unique_key
    }

    data.station_id = selected_target.id ? selected_target.id : null

    if (data.type === 'pin') {
      try {
        let townvillage_res = await get_town_village(data.lat, data.lon)
        if (townvillage_res?.data?.town && townvillage_res?.data?.village) {
          data['name'] = townvillage_res.data.town + townvillage_res.data.village
        }
      }
      catch {
      }
    }

    state.favorites = [data, ...state.favorites]

    const res = await FavoritesRepo.favorites.post(data)
    data.id = res.data.id
    fetch_favorites()
  }

  const fetch_favorites = async (id) => {
    let res = await FavoritesRepo.favorites.get(id, get_device_id())
    if (res.data?.length > 0) {
      res.data?.sort((a, b) => {
        if (a.order === null && b.order !== null) {
          return 1
        }
        if (a.order !== null && b.order === null) {
          return -1
        }
        if (a.order === null && b.order === null) {
          return 0
        }
        return a.order - b.order
      }).map(e => {
        e.unique_key = `${e.type},${round_to_five(e.lat)},${round_to_five(e.lon)}`
      })
      state.favorites = markRaw(res.data)
    }
  }

  const edit_favorites = async (id, data) => {
    for (let favorite of state.favorites) {
      if (favorite.id === id) {
        favorite.name = data.name
      }
    }
    await FavoritesRepo.favorites.patch(id, data)
    fetch_favorites()
  }

  const delete_favorites = async (id) => {
    state.favorites = state.favorites.filter((element) => element.id !== id)
    await FavoritesRepo.favorites.delete(id)
    fetch_favorites()
  }

  const add_or_delete_favorites = (selected_target) => {
    let exists = false
    state.favorites.forEach((favorite) => {
      if (favorite.unique_key === selected_target.unique_key) {
        delete_favorites(favorite.id)
        exists = true
      }
    })
    if (!exists) {
      add_favorites(selected_target)
    }
  }

  const reorder_favorites = async (new_favorites) => {
    const favorites = []
    new_favorites.forEach((favorite) => {
      favorites.push(favorite.id)
    })
    const data = {
      device_id: get_device_id(),
      favorites: favorites
    }
    state.favorites = [...new_favorites]
    await FavoritesRepo.reorder.post(data)
    fetch_favorites()
  }

  const get_device_id = () => DeviceControl.state.device_id

  state.favorites_mapping = computed(() => {
    const mapping = {}
    state.favorites.forEach(favorite => {
      const unique_key = `${favorite.type},${round_to_five(favorite.lat)},${round_to_five(favorite.lon)}`
      mapping[unique_key] = favorite
    })
    return mapping
  })

  const get_town_village = async (lat, lon) => {
    return await GeoRepo.town_village.get(lat, lon)
  }

  const store = {
    state: readonly(state),
    add_favorites,
    edit_favorites,
    delete_favorites,
    add_or_delete_favorites,
    reorder_favorites
  }

  const pin_locate = (device_id) => {
    Geolocation.getCurrentPosition().then((value) => {
      GeoRepo.pin_locate.get(device_id, value.coords.latitude, value.coords.longitude)
    })
  }

  watch(get_device_id, (device_id) => {
    if (device_id) {
      fetch_favorites()
      pin_locate(device_id)
    }
  }, {
    immediate: true
  })

  provide('FavoritesStore', store)

  return store
}
