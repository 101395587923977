import { watch } from 'vue'

import { round_to_five } from '../../libs/utils'

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `rain_station,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `rain_station,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}

const PIN_WIDTH = 36
const get_icon = (in_favorites, value) => {
  const icon_img = require('@/assets/imgs/precipitation.png')
  const base_marker_html = (in_favorites) => {
    const favorite_html = in_favorites ? `<div class="absolute -top-[5px] -right-[5px]">
      <img class="w-[20px] h-[20px]" src=${require('@/assets/imgs/love--click.png')}>
    </div>` : ''
    return `<div>
      <img src="${icon_img}" class="w-full">
      ${favorite_html}
    </div>`
  }
  const options = {
    className: 'marker_div_icon',  // 為了消除背景白底，指定一個不存在的 class
    iconSize: [PIN_WIDTH, PIN_WIDTH],
    iconAnchor: [PIN_WIDTH / 2, PIN_WIDTH / 2]
  }

  if (value) {
    const bubble_content_html = `<div class="flex items-center whitespace-nowrap">
      ${value}&nbsp;mm
    </div>`
    const marker_html = `<div class="relative">
      <div class="marker_bubble horizontal_center before:horizontal_center">
        ${bubble_content_html}
      </div>
      ${base_marker_html(in_favorites)}
    </div>`

    return L.divIcon({
      html: marker_html,
      ...options
    })
  } else {
    return L.divIcon({
      html: base_marker_html(in_favorites),
      ...options
    })
  }
}

export const useRainStationVLayer = (map, LayerStore, RainStationStore, FavoritesStore) => {
  const layer_group = L.featureGroup()

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-rain_station/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-rain_station text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      for (let feature of features) {
        let station_marker = useBubbleMarker(FavoritesStore, RainStationStore, feature).addTo(cluster_group)

        const unique_key = get_unique_key(feature)

        station_marker.on("click", (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return
          let target = {
            'type': 'rain_station',
            'id': feature.id,
            'name': feature.name,
            'lat': feature.lat,
            'lon': feature.lon,
            'unique_key': unique_key
          }
          LayerStore.set_selected_target(target)

        })
      }
      layer_group.addLayer(cluster_group)
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  watch(() => LayerStore.state.checked_layers.rain_station, (_checked) => {
    toggle_layer(_checked)
    RainStationStore.get_accumulate_rain_obs_data()
  }, {
    immediate: true
  })

  watch(() => RainStationStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate: true
  })

  watch(() => LayerStore.state.selected_target, (target) => {
    if (target.type === "rain_station") {
      RainStationStore.get_rain_obs_data(target.id)
    }
  })
}

const useBubbleMarker = (FavoritesStore, RainStationStore, feature) => {
  const unique_key = get_unique_key(feature)
  let marker = L.marker(
    [feature.lat, feature.lon],
    {icon: get_icon(), shadowPane: 'favoritesPane'}
  )
  watch([() => RainStationStore.state.accumulate_rain_obs_data[feature.id], () => FavoritesStore.state.favorites_mapping[unique_key]], ([obs_data, fav_obj]) => {
    let elem_text = null
    if (obs_data?.length > 0) {
      // 如果所有值都為 -99 顯示 `--`
      if (obs_data.every(e => e === -99)) {
        elem_text = '--'
      } else {
        elem_text = obs_data[obs_data.length-1].precp_24h.toFixed()
      }
    } else if (obs_data?.length === 0) {
      elem_text = '--'
    }
    marker.setIcon(get_icon(fav_obj ? true : false, elem_text))
  }, {
    immediate: true
  })

  return marker
}
