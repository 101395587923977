import { reactive, readonly, provide } from 'vue'

import { ReferenceRepo } from '@/repositories/reference'


export const useMenuStore = () => {
  const state = reactive({
    legal_fishing_port_url: '',
  })

  const get_legal_fishing_port_url = async () => {
    let payload = await ReferenceRepo.legal_fishing_port.get()
    state.legal_fishing_port_url = payload.data
  }
  get_legal_fishing_port_url()

  const store = {
    state: readonly(state)
  }

  provide('MenuStore', store)

  return store
}
