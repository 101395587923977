<template>
  <div ref="root_elem" class="fixed top-0 h-full w-full bg-white z-favorites">
    <Layout main_page :close_method="close_page">
      <template v-slot:title>
        菜園管理
      </template>
      <template v-slot:main>
        <div class="flex flex-col h-full w-full p-4">
          <draggable
            v-model="favorites"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
          >
            <template #item="{element}">
              <div
                class=" py-2 w-full border-b border-gray-200 select-none flex items-center"
              >
                <div
                  class="min-w-0 flex-1 flex items-center px-2 py-1 rounded-lg hover:bg-gray-100"
                >
                  <!-- Icon block -->
                  <img class="h-7 w-7" :src="get_icon_class(element.type)" />

                  <!-- Name -->
                  <div
                    v-if="
                      editing_item?.id
                        ? element.id !== editing_item.id
                        : element.name
                    "
                    class="flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer p-2"
                    @click.stop="item_clicked($event, element)"
                    @touchstart="item_touched($event, element)"
                    @touchmove="record_current_touch"
                  >
                    {{ element.name }}
                  </div>

                  <!-- Rename block -->
                  <input
                    v-if="element?.id && element.id === editing_item.id"
                    class="rename_input flex-1 input--base ml-2 my-1"
                    type="text"
                    v-model="new_item_name"
                    @click.stop=""
                    @touchstart.stop=""
                    @keyup="rename_keyup"
                  />
                </div>

                <!-- handle -->
                <div
                  class="handle p-4 cursor-move"
                  v-if="element.id !== editing_item.id || !element?.id"
                >
                  <svg class="handle_svg" viewBox="0 0 18 8">
                    <line x1="2" y1="2" x2="17" y2="2" />
                    <line x1="2" y1="6" x2="17" y2="6" />
                  </svg>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </template>
    </Layout>

    <div
      ref="options_elem"
      v-show="options_visible"
      class="absolute bg-gray-600 rounded-lg p-2 select-none text-white"
    >
      <div
        class=" py-2 px-4 cursor-pointer hover:bg-gray-500 rounded-lg"
        @click="rename_item"
        @touchstart="rename_item"
      >
        編輯
      </div>
      <div class="w-auto border-t border-gray-300"></div>
      <div
        class=" py-2 px-4 cursor-pointer hover:bg-gray-500 rounded-lg"
        @click="delete_item"
        @touchstart="delete_item"
      >
        刪除
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'

import draggable from 'vuedraggable'

import Layout from '@/components/menu/Layout'

import { round_to_five } from '../libs/utils'

export default {
  components: {
    draggable,
    Layout,
  },

  props: ['visible'],

  emit: ['update:visible'],

  setup(props, { emit }) {
    const FavoritesStore = inject('FavoritesStore')
    const LayerStore = inject('LayerStore')

    const favorites = computed({
      get: () => FavoritesStore.state.favorites,
      set: (val) => FavoritesStore.reorder_favorites(val),
    })

    /* ------------------------------------------ */
    /* ---- Shared Item Interaction Behavior ---- */

    const root_elem = ref(null)
    const options_elem = ref(null)
    const options_visible = ref(false)

    let is_mobile = true
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      is_mobile = false
    }

    const goto_favorite_point = (item) => {
      emit('update:visible', false)
      LayerStore.set_selected_target({
        id: item.station_id || null,
        lat: item.lat,
        lon: item.lon,
        name: item.name,
        type: item.type,
        unique_key: `${item.type},${round_to_five(item.lat)},${round_to_five(
          item.lon
        )}`,
      })
      gtag('event', 'goto_favorite_point')
    }

    let selected_item = {}
    const editing_item = ref({})
    const new_item_name = ref('')
    let is_renaming = false
    async function rename_item(ev) {
      if (is_mobile && ev.type === 'click') {
        return
      }
      is_renaming = true
      editing_item.value = selected_item
      new_item_name.value = editing_item.value.name
      // setTimeout act as Next tick block
      // - Next tick is necessary for await Promise blocker to be applied, otherwise the click
      //   event would be trigger immediately.
      // - Next tick is necessary for focusing input to work, as the input field is rendered before
      //   next tick after 'renaming' is set to true.
      setTimeout(async () => {
        document.getElementsByClassName('rename_input')[0].focus()
        // Function blocker: Continue rename process if area outside input field clicked.
        if (is_mobile) {
          await new Promise((resolve) => {
            root_elem.value.addEventListener(
              'touchstart',
              () => {
                resolve()
              },
              { once: true }
            )
          })
        } else {
          await new Promise((resolve) => {
            root_elem.value.addEventListener(
              'click',
              () => {
                resolve()
              },
              { once: true }
            )
          })
        }
        if (
          new_item_name.value !== '' &&
          new_item_name.value !== editing_item.value.name
        ) {
          FavoritesStore.edit_favorites(editing_item.value.id, {
            name: new_item_name.value,
          })
          // favorites.value[item_position].name = new_item_name.value
        }
        editing_item.value = {}
        is_renaming = false
      })
    }

    const rename_keyup = (event) => {
      if (event.key === 'Enter') {
        root_elem.value.click()
      }
    }

    const delete_item = (ev) => {
      if (is_mobile && ev.type === 'click') {
        return
      }
      FavoritesStore.delete_favorites(selected_item.id)
    }

    /* -- End Shared Item Interaction Behavior -- */
    /* ------------------------------------------ */

    /* ---------------------------------- */
    /* ------ Pc Click Interaction ------ */

    let item_click_timeout = null
    const item_clicked = (ev, item) => {
      if (is_mobile || is_renaming) {
        return
      }

      if (!item_click_timeout) {
        item_click_timeout = setTimeout(() => {
          goto_favorite_point(item)
          item_click_timeout = null
        }, 300)
      } else {
        clearTimeout(item_click_timeout)
        item_click_timeout = null
        show_click_triggered_options(ev, item)
      }
    }

    async function show_click_triggered_options(ev, item) {
      selected_item = item
      if (window.innerHeight - ev.y < 100) {
        options_elem.value.style = `left: ${
          ev.x
        }px; bottom: ${window.innerHeight - ev.y}px;`
      } else {
        options_elem.value.style = `left: ${ev.x}px; top: ${ev.y}px;`
      }
      options_visible.value = true
      setTimeout(async () => {
        await new Promise((resolve) => {
          root_elem.value.addEventListener(
            'click',
            () => {
              resolve()
            },
            { once: true }
          )
        })
        options_visible.value = false
      })
    }

    /* ---- End Pc Click Interaction ---- */
    /* ---------------------------------- */

    /* ---------------------------------- */
    /* ---- Mobile Touch Interaction ---- */

    const _is_touch_moved = (touchstart_ev, touchend_ev) => {
      let start_x = touchstart_ev.touches[0].clientX
      let start_y = touchstart_ev.touches[0].clientY
      let end_x = touchend_ev.changedTouches[0].clientX
      let end_y = touchend_ev.changedTouches[0].clientY
      if ((end_x - start_x) ** 2 + (end_y - start_y) ** 2 > 900) {
        return true
      }
      return false
    }

    let current_touchmove_ev = null
    const record_current_touch = (ev) => {
      current_touchmove_ev = ev
    }

    const item_touched = (ev, item) => {
      // Disable single touch event if options are visible / item name is editing
      let touchend_handler = null
      if (!options_visible.value && !is_renaming) {
        touchend_handler = (touchend_ev) => {
          current_touchmove_ev = null
          clearTimeout(long_press_timeout)
          // If touchend is distanced from touch start, don't go to favorite point.
          if (!_is_touch_moved(ev, touchend_ev)) {
            goto_favorite_point(item)
          }
        }
      } else {
        touchend_handler = () => {
          current_touchmove_ev = null
          clearTimeout(long_press_timeout)
        }
      }
      ev.target.addEventListener('touchend', touchend_handler, { once: true })
      let long_press_timeout = setTimeout(() => {
        ev.target.removeEventListener('touchend', touchend_handler)
        // If touch moves and move over a certain distance then don't show touch.
        if (
          !current_touchmove_ev ||
          (current_touchmove_ev && !_is_touch_moved(ev, current_touchmove_ev))
        ) {
          show_touch_triggered_options(ev, item)
        }
        current_touchmove_ev = null
      }, 450)
    }

    async function show_touch_triggered_options(ev, item) {
      selected_item = item

      /* 決定 options 位置 */
      // Horizontal potision / 水平位置
      let horizontal_position = ``
      if (ev.touches[0].clientX < window.innerWidth / 2) {
        // Touch is on the left side screen, display options towards right
        horizontal_position = `left: ${ev.touches[0].clientX}px;`
      } else {
        // Touch is on the right side screen, display options towards left
        horizontal_position = `right: ${window.innerWidth -
          ev.touches[0].clientX}px;`
      }
      // Vertical position / 垂直位置
      let vertical_position = ``
      if (ev.touches[0].clientY < window.innerHeight * (3 / 4)) {
        // Touch is on the top side screen, display options towards bottom
        vertical_position = `top: ${ev.touches[0].clientY}px;`
      } else {
        // Touch is on the bottom side screen, display options towards top
        vertical_position = `bottom: ${window.innerHeight -
          ev.touches[0].clientY}px;`
      }
      // Assign options position
      options_elem.value.style = horizontal_position + vertical_position
      options_visible.value = true

      await new Promise((resolve) => {
        root_elem.value.addEventListener(
          'touchstart',
          () => {
            resolve()
          },
          { once: true }
        )
      })
      options_visible.value = false
    }

    /* -- End Mobile Click Interaction -- */
    /* ---------------------------------- */

    // Helper function

    const get_icon_class = (type) => {
      if (type === 'pin') {
        return require('@/assets/imgs/map_pin--click.png')
      } else if (type === 'cctv') {
        return require('@/assets/imgs/CCTV.png')
      } else if (type === 'tidal') {
        return require('@/assets/imgs/tidal.png')
      } else if (type === 'station_stage') {
        return require('@/assets/imgs/water_stage.png')
      } else if (type === 'rain_station') {
        return require('@/assets/imgs/precipitation.png')
      } else if (type === 'tide') {
        return require('@/assets/imgs/tide_station.png')
      } else if (type === 'buoy') {
        return require('@/assets/imgs/buoy.png')
      }
    }

    const close_page = () => {
      emit('update:visible', false)
    }

    return {
      root_elem,
      close_page,
      favorites,
      get_icon_class,

      // item click
      options_elem,
      options_visible,
      item_clicked,
      rename_item,

      // item touch
      record_current_touch,
      item_touched,

      // item editing
      editing_item,
      rename_keyup,
      new_item_name,
      delete_item,

      drag: ref(false),
    }
  },
}
</script>

<style scoped lang="scss">

.handle_svg {
  width: 18px;
  height: 8px;
  stroke: rgb(163, 163, 163);
  stroke-width: 2;
  stroke-linecap: round;
}

</style>
