<template>
  <Layout main_page>
    <template v-slot:title>
      選單
    </template>
    <template v-slot:main>
      <div class="menu_container">
        <div @click="$router.push({ name: 'About' })">
          關於釣魚天氣
        </div>
        <hr class="my-2" />
        <div @click="$router.push({ name: 'Manual' })">
          使用方法
        </div>
        <hr class="my-2" />
        <div @click="$router.push({ name: 'Resource' })">
          資料來源說明
        </div>
        <hr class="my-2" />
        <div>
          <a
            :href="legal_fishing_port_url"
            @click="add_ga_click_legal_fishing_port_url_event"
            class="menu_link"
            target="_blank"
          >
            合法垂釣港口
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
        <div>
          <a
            :href="fishing_prohibit_url"
            @click="add_ga_click_fishing_prohibit_url_event"
            class="menu_link"
            target="_blank"
          >
            封溪禁釣資訊
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
        <div>
          <a
            href="https://fhy.wra.gov.tw/ReservoirPage_2011/Statistics.aspx"
            class="menu_link"
            target="_blank"
          >
            水利署水庫即時資訊
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
        <div>
          <a href="https://typhoon.tw/" class="menu_link" target="_blank">
            颱風消息
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
        <template
          v-if="
            device_platform === 'web' &&
              (device_system === 'android' || device_system === 'ios')
          "
        >
          <div>
            <a
              :href="
                device_system === 'ios'
                  ? 'https://apps.apple.com/la/app/%E9%87%A3%E9%AD%9A%E5%A4%A9%E6%B0%A3%E5%9C%B0%E5%9C%96/id1572603590'
                  : 'https://play.google.com/store/apps/details?id=cb.fwm'
              "
              class="menu_link"
              target="_blank"
            >
              釣魚天氣APP下載
              <i class="icon-blank right-0 text-xl text-primary"></i>
            </a>
          </div>
          <hr class="my-2" />
        </template>
        <div>
          <a :href="get_fb_link()" class="menu_link" target="_blank">
            釣魚天氣FB粉絲團
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
        <div>
          <a :href="privacy_policy_url" class="menu_link" target="_blank">
            隱私權政策
            <i class="icon-blank right-0 text-xl text-primary"></i>
          </a>
        </div>
        <hr class="my-2" />
      </div>
      <div class=" w-full flex justify-center">
        <a href="https://www.facebook.com/groups/taiwanflyfishingpromote" target="_blank">
          <img
            class="h-10 my-4 mx-2"
            src="../assets/imgs/logo/TW-fishing_logo_footer.png"
          />
        </a>
        <a href="https://www.facebook.com/groups/216632058356262/" target="_blank">
          <img
            class="h-10 my-4 mx-2"
            src="../assets/imgs/logo/TCANF_logo_footer.png"
          />
        </a>
        <a href="https://www.youtube.com/channel/UCudGn7_8E-vx1x_0Ev_BleA" target="_blank">
          <img
            class="h-10 my-4 mx-2"
            src="../assets/imgs/logo/fishing-fun-news_logo_footer.png"
          />
        </a>
      </div>
    </template>
  </Layout>
</template>

<script>
import { computed, inject, ref } from 'vue'

import Layout from '@/components/menu/Layout'
import { Device } from '@capacitor/device'

export default {
  components: {
    Layout,
  },
  setup() {
    const MenuStore = inject('MenuStore')

    const device = (() => {
      if (/android/i.test(navigator.userAgent)) {
        return 'Android'
      }
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        return 'iOS'
      }
      return ''
    })()

    const get_fb_link = () => {
      if (device === 'Android') {
        return 'fb://page/117336863727440'
      } else if (device === 'iOS') {
        return 'fb://page/?id=117336863727440'
      } else {
        return 'https://facebook.com/117336863727440'
      }
    }

    const fishing_prohibit_url =
      process.env.VUE_APP_SERVER_HOST + 'fishing_prohibit/'
    const privacy_policy_url =
      process.env.VUE_APP_SERVER_HOST + 'privacy_policy/'
    const legal_fishing_port_url = computed(
      () => MenuStore.state.legal_fishing_port_url
    )
    const add_ga_click_legal_fishing_port_url_event = () => {
      gtag('event', 'click_legal_fishing_port_url')
    }
    const add_ga_click_fishing_prohibit_url_event = () => {
      gtag('event', 'click_fishing_prohibit_url')
    }

    const device_system = ref(null)
    const device_platform = ref(null)
    Device.getInfo().then((device_info) => {
      device_system.value = device_info.operatingSystem
      device_platform.value = device_info.platform
    })

    return {
      fishing_prohibit_url,
      privacy_policy_url,
      legal_fishing_port_url,
      get_fb_link,
      add_ga_click_legal_fishing_port_url_event,
      add_ga_click_fishing_prohibit_url_event,

      device_system,
      device_platform,
    }
  },
}
</script>
<style lang="scss" scoped>
.menu_container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.menu_container > div:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  cursor: pointer;
}
.menu_container > div {
  padding: 0.75rem;
}
.menu_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
</style>
