
import { CBScaleFix, CBScaleLiner, CBScaleTime } from './cb_scale.js'


let default_chart_options = {
  clip_size: {},
  canvas_size: {},
  canvas_margin: 16,
  // 是否需要 tooltip ( hover 時出現)
  tooltip_required: false,

  // 是否要開啟水平拖曳功能
  horizontal_drag_required: true
}

class CBChartAbc {
  constructor(div_id, data_artists, options) {
    this.container_id = div_id
    this.container = document.getElementById(this.container_id)
    this.options = Object.deepAssign({}, default_chart_options, options)
    this.axis_x = Object.deepAssign({}, this.options.axis_x)
    this.axes_y = Object.deepAssign({}, this.options.axes_y)

    this.init_elems()
    this.assign_data(data_artists)
    this.draw()
  }

  draw() {
    this.draw_reference()
    this.draw_axis_x()
    this.draw_axes_y()
    this.draw_data()
  }

  init_elems() {
    console.error("Not Implemented")
    return this
  }

  init_scale_x(data_artists) {
    console.error("Not Implemented")
    return this
  }
  init_scale_y(data_artists) {
    console.error("Not Implemented")
    return this
  }

  assign_data(data_artists) {
    this.init_scale_x(data_artists)
    this.init_scale_y(data_artists)
    this.data_artists = data_artists
    return this
  }

  draw_data() {
    console.error("Not Implemented")
    return this
  }

  draw_axis_x() {
    if (this.axis_x.paint) {
      this.axis_x.paint.draw(this.scale_x, this.canvas)
    }
    return this
  }

  draw_axes_y() {
    for (let elem in this.axes_y) {
      let axis_y = this.axes_y[elem]
      if (axis_y.paint) {
        axis_y.paint.draw(this.scale_y[elem], this.canvas)
      }
    }
    return this
  }

  draw_reference() {
    console.error("Not Implemented")
    return this
  }

  destroy() {
    if (this.data_artists) {
      for (let elem in this.data_artists) {
        this.data_artists[elem] = this.data_artists[elem]?.destroy()
      }
      this.data_artists = null
    }

    if (this.axis_x?.paint) {
      this.axis_x.paint.destroy()
    }

    if (this.axes_y) {
      for (let elem in this.axes_y) {
        if (this.axes_y[elem].paint) {
          this.axes_y[elem].paint.destroy()
        }
      }
    }

    if (this.scale_x) {
      this.scale_x = this.scale_x.destroy()
    }
    if (this.scale_y) {
      for (let elem in this.scale_y) {
        this.scale_y[elem] = this.scale_y[elem].destroy()
      }
      this.scale_y = null
    }

    this.container.innerHTML = ""

    return null
  }
}

/**
 * CBChart
 * 只要x, y軸有照順序排列的特性即可
 */
class CBChart extends CBChartAbc {
  init_elems() {
    const container_width = this.container.offsetWidth
    const container_height = this.container.offsetHeight

    this.clip = document.createElement('div')
    this.clip.className += 'cb_chart-clip'
    if (this.options.clip_class) {
      this.clip.className += ' ' +  this.options.clip_class
    }

    let clip_width = this.options.clip_size?.width == null? container_width : this.options.clip_size.width
    let clip_height = this.options.clip_size?.height == null? container_height : this.options.clip_size.height
    this.clip.style.width = css_unit_join(clip_width)
    this.clip.style.height = css_unit_join(clip_height)

    this.container.appendChild(this.clip)

    this.canvas_space = document.createElement('div')
    this.canvas_space.classList.add('cb_chart-canvas_container')

    this.clip.appendChild(this.canvas_space)

    this.canvas = document.createElement('canvas')
    this.canvas.id = this.container_id + '_canvas'
    this.canvas.width = this.options.canvas_size?.width == null? clip_width : this.options.canvas_size.width
    this.canvas.height = this.options.canvas_size?.height == null? clip_height : this.options.canvas_size.height
    this.canvas.style.display = 'block'

    this.canvas_space.appendChild(this.canvas)

    this.canvas_margin = this.options.canvas_margin

    if (this.options.tooltip_required) {
      this.add_hover_tooltip()
    }
    if (this.options.horizontal_drag_required) {
      this.add_horizontal_drag(this.clip)
    }

    return this
  }

  init_scale_x(data_artists) {
    if (this.scale_x) {
      this.scale_x = this.scale_x.destroy()
    }

    if (!this.axis_x.data_range) {
      this.axis_x.data_range = 'auto'
    }
    if (!this.axis_x.canvas_range) {
      this.axis_x.canvas_range = [this.canvas_margin, this.canvas.width - this.canvas_margin]
    }

    if (!Array.isArray(this.axis_x.data_range)) {
      let min = null
      let max = null
      let desc = false
      let xrange = [0, 0]
      for (let elem in data_artists) {
        if (this.axis_x.data_range == "auto") {
          xrange = data_artists[elem].xrange()
        }
        else if (this.axis_x.data_range instanceof Function) {
          xrange = this.axis_x.data_range(data_artists[elem].xrange())
        }
        if (xrange[0] > xrange[1]) { desc = true }
        if (min === null || min > xrange[0]) {
          min = xrange[0]
        }
        if (max === null || max < xrange[1]) {
          max = xrange[1]
        }
      }
      if (desc) {
        this.axis_x.data_range = [max, min]
      }
      else {
        this.axis_x.data_range = [min, max]
      }
    }
    this._create_scale_x()
    return this
  }

  _create_scale_x() {
    this.scale_x = new CBScaleLiner(
      this.axis_x.data_range,
      this.axis_x.canvas_range
    )
  }

  init_scale_y(data_artists) {
    if (this.scale_y) {
      for (let elem in this.scale_y) {
        this.scale_y[elem] = this.scale_y[elem].destroy()
      }
    }
    this.scale_y = {}
    for (let elem in data_artists) {
      if (!this.axes_y[elem]) {
        this.axes_y[elem] = {}
      }
      if (!this.axes_y[elem].data_range) {
        this.axes_y[elem].data_range = 'auto'
      }
      if (!this.axes_y[elem].canvas_range) {
        this.axes_y[elem].canvas_range = [this.canvas.height - this.canvas_margin, this.canvas_margin]
      }

      if (this.axes_y[elem].data_range == "auto") {
        this.axes_y[elem].data_range = data_artists[elem].yrange()
      }
      else if (this.axes_y[elem].data_range == "auto_from_zero") {
        this.axes_y[elem].data_range = data_artists[elem].yrange_from_zero()
      }
      else if (this.axes_y[elem].data_range instanceof Function) {
        this.axes_y[elem].data_range = this.axes_y[elem].data_range(data_artists[elem].yrange())
      }

      if (Array.isArray(this.axes_y[elem].data_range) && Array.isArray(this.axes_y[elem].canvas_range) && !isNaN(this.axes_y[elem].data_range[0])) {
        this.scale_y[elem] = new CBScaleLiner(
          this.axes_y[elem].data_range,
          this.axes_y[elem].canvas_range
        )
      }
      else {
        this.scale_y[elem] = new CBScaleFix(
          this.axes_y[elem].data_range,
          this.axes_y[elem].canvas_range
        )
      }
    }
    return this
  }

  draw_data() {
    for (let elem in this.data_artists) {
      this.data_artists[elem]?.draw(this.canvas, this.scale_x, this.scale_y[elem])
    }
    return this
  }

  add_hover_tooltip() {
    let tooltip = document.createElement('div')
    tooltip.className = 'cb_chart-tooltip'

    let tooltip_point = document.createElement('div')
    tooltip_point.className = 'cb_chart-tooltip_point'

    this.clip.onmousemove = e => { this._on_mouse_event(e, tooltip, tooltip_point) }
    this.clip.onclick = e => { this._on_mouse_event(e, tooltip, tooltip_point) }

    this.canvas.parentNode.appendChild(tooltip)
    this.canvas.parentNode.appendChild(tooltip_point)
  }

  _on_mouse_event(e, tooltip, tooltip_point) {
    let target_info = this._get_data_by_xy(e.offsetX, e.offsetY)
    if (target_info) {
      let target_elem = target_info.elem
      let target_data = target_info.data
      if (target_elem || target_data) {
        let format = (v) => { return v }
        if (this.axes_y[target_elem].format) {
          format = this.axes_y[target_elem].format
        }
        tooltip.innerHTML = format(target_data.y)
        tooltip.style.display = 'block'

        let data_x_position = this.scale_x.position(target_data.x)
        let data_y_position = this.scale_y[target_elem].position(target_data.y)

        if (data_y_position < this.scale_y[target_elem].canvas_range[1]) {
          data_y_position = this.scale_y[target_elem].canvas_range[1]
        }

        // --- 微調 hint 所在位置，避免超出 canvas 範圍
        let tooltip_x_position = data_x_position
        let tooltip_y_position = data_y_position < 0? 0 : data_y_position

        // 會超出左緣，顯示在右方
        if (data_x_position < tooltip.clientWidth/2) {
          tooltip_x_position += tooltip.clientWidth/2
        }
        // 會超出右緣，顯示在左方
        else if (data_x_position > this.canvas.clientWidth - tooltip.clientWidth/2) {
          tooltip_x_position -= tooltip.clientWidth/2
        }

        // 會超出上緣，顯示在下方
        if (tooltip_y_position < (tooltip.clientHeight/2 + 12)) {
          tooltip_y_position += tooltip.clientHeight/2 + 12
        }
        // 預設顯示在上方
        else {
          tooltip_y_position -= tooltip.clientHeight/2 + 12
        }

        tooltip.style.left = css_unit_join(tooltip_x_position, 'px')
        tooltip.style.top = css_unit_join(tooltip_y_position, 'px')
        // ---

        // 若是顯示在固定高度的資料，不要顯示 tooltip_point
        if (!(this.scale_y[target_elem] instanceof CBScaleFix)) {
          tooltip_point.style.left = css_unit_join(data_x_position, 'px')
          tooltip_point.style.top = css_unit_join(data_y_position, 'px')
          tooltip_point.style.display = 'block'
        }
        else {
          tooltip_point.style.display = 'none'
        }
      }
    }
    else {
      tooltip.style.display = 'none'
      tooltip_point.style.display = 'none'
    }
  }

  _get_data_by_xy(mouse_x, mouse_y) {
    let target_elems = []
    // 判斷 y 範圍
    for (let elem in this.scale_y) {
      let canvas_range = this.scale_y[elem].canvas_range
      if (Array.isArray(canvas_range)) {
        if (mouse_y > canvas_range[1] && mouse_y < canvas_range[0]) {
          target_elems.push(elem)
        }
      }
      else {
        if (mouse_y > canvas_range-15 && mouse_y < canvas_range+15) {
          target_elems.push(elem)
        }
      }
    }

    // 判斷 x 範圍
    let target_info = {
      elem: null,
      data: null
    }
    let min_distance = null
    for (let target_elem of target_elems) {
      if (this.data_artists[target_elem]) {
        let dataset = this.data_artists[target_elem].dataset
        for (let idx = 0; idx < dataset.length; idx++) {
          let x_position = this.scale_x.position(dataset[idx].x)
          let range_left = null
          let range_right = null
          if (idx > 0) {
            let prev_x_position = this.scale_x.position(dataset[idx-1].x)
            range_left = x_position - (x_position - prev_x_position)/2
          }
          else {
            range_left = 0
          }
          if (idx < dataset.length-1) {
            let next_x_position = this.scale_x.position(dataset[idx+1].x)
            range_right = x_position + (next_x_position - x_position)/2
          }
          else {
            range_right = this.canvas.clientWidth
          }

          if (x_position && mouse_x > range_left && mouse_x < range_right) {
            // 去比較哪個資料離滑鼠最近
            let distance = Math.pow(Math.pow(mouse_x - x_position, 2) + Math.pow(mouse_y - this.scale_y[target_elem].position(dataset[idx].y), 2), 0.5)
            if (min_distance === null || distance < min_distance) {
              target_info = {
                elem: target_elem,
                data: dataset[idx]
              }
              min_distance = distance
            }
          }
        }
      }
    }
    return target_info
  }

  add_horizontal_drag(target){
    let mouse_start_x = 0
    let origin_scroll_left = 0

    target.addEventListener("mousedown", start);

    function start(event){
      if(event.button == 0){
        mouse_start_x = event.clientX;
        origin_scroll_left = target.scrollLeft;

        document.addEventListener("mousemove", move);
        document.addEventListener("mouseup", stop);
      }
      return false
    }
    function move(event){
      var movement = event.clientX - mouse_start_x;

      target.scrollLeft = origin_scroll_left - movement;

      return false
    }
    function stop(){
      document.removeEventListener("mousemove",move);
      document.removeEventListener("mouseup",stop);
    }
  }

  draw_reference() {
    if (this.options.x_reference_line_required) {
      this.draw_x_reference_line()
    }

    if (this.options.y_reference_line_required) {
      for (let elem in this.axes_y) {
        if (this.axes_y[elem].paint) this.draw_y_reference_line(this.scale_y[elem])
      }
    }

    if (this.options.selected_block_x != null && this.options.selected_block_x != '') {
      this.draw_selected_block(this.options.selected_block_x)
    }
  }

  draw_x_reference_line() {
    let tick_list = this.scale_x.paint_tick_list(this.axis_x.paint.options)

    let ctx = this.canvas.getContext("2d")
    ctx.globalAlpha = 1

    for (let idx = 0; idx < tick_list.length - 1; idx++) {
      let x_position = this.scale_x.position(tick_list[idx])
      ctx.beginPath()
      ctx.strokeStyle = 'rgb(245, 245, 245)'
      ctx.setLineDash([4, 4])
      ctx.moveTo(x_position, 0)
      ctx.lineTo(x_position, this.canvas.height)
      ctx.stroke()
      ctx.closePath()
    }
    ctx.setLineDash([])
  }

  draw_y_reference_line(scale_y) {
    if (scale_y instanceof CBScaleFix) return

    let tick_values = scale_y.value_list()

    let ctx = this.canvas.getContext("2d")
    ctx.globalAlpha = 1
    ctx.strokeStyle = 'rgb(245, 245, 245)'
    ctx.setLineDash([4, 4])

    for (let idx in tick_values) {
      ctx.beginPath()

      ctx.moveTo(0, scale_y.position(tick_values[idx]))
      ctx.lineTo(this.canvas.width, scale_y.position(tick_values[idx]))
      ctx.stroke()

      ctx.closePath()
    }
    ctx.setLineDash([])
  }

  draw_selected_block(selected_block_x) {
    let tick_list = this.scale_x.paint_tick_list(this.options)
    let average_width = (this.scale_x.canvas_range[1] - this.scale_x.canvas_range[0]) / (tick_list.length-1)
    let x_position = this.scale_x.position(selected_block_x)

    let left_x = x_position - average_width/2
    let right_x = x_position + average_width/2

    let ctx = this.canvas.getContext("2d")

    ctx.fillStyle = 'rgba(255, 196, 0, 0.1)'
    ctx.fillRect(left_x, 0, right_x - left_x, this.canvas.height);
  }
}


class CBTimeChart extends CBChart {
  _create_scale_x() {
    this.scale_x = new CBScaleTime(
      this.axis_x.data_range, this.axis_x.canvas_range)
    return this
  }
}


export {
  CBChart, CBTimeChart
}
