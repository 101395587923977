import { req } from "@/axios/fwm"

export const GeoRepo = {
  latlon: {
    get: (address) => {
      return req("get", `geo/coordinate/${address}/`)
    }
  },
  town_village: {
    get: (lat, lon) => {
      return req("get", `geo/town_village/${lat},${lon}/`)
    }
  },
  auto_complete: {
    get: (text) => {
      return req("get", `geo/auto_complete/${text}/`)
    }
  },
  place: {
    get: (place_id) => {
      return req("get", `geo/place/${place_id}/`)
    }
  },
  pin_locate: {
    get: (device_id, lat, lon) => {
      return req("get", `user/pin_locate/${device_id}/${lat},${lon}`)
    }
  },
}
