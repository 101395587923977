import { watch } from 'vue'

export const useFishingHarborVLayer = (map, LayerStore, FishingHarborStore) => {
  const layer_group = L.featureGroup()

  const icon = L.icon({
    iconUrl: require('@/assets/imgs/port.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })

  const draw_marker = (features) => {
    layer_group.clearLayers()
    if (features) {
      for (let feature of features) {
        const popup = L.popup().setContent(feature.name);
        L.marker({
          lat: feature.lat,
          lon: feature.lon,
        }, {
          icon: icon
        }).bindPopup(popup).addTo(layer_group)
      }
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      gtag('event', 'turn_on_allowed_fishing_harbor')
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  watch(() => LayerStore.state.checked_layers.fishing_harbor, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => FishingHarborStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate: true
  })
}
