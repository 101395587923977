<template>
  <Layout>
    <template v-slot:title>
      資料來源說明
    </template>
    <template v-slot:main>
      <article class="p-4">
        # 水位資料與 CCTV 取自水利署 open data, 有幾個水位站加上了預報水位,
        是由多采科技提供<br /><br />
        #
        天氣觀測資料、潮汐預報、豪大雨特報、即時雷雨、沿海天氣預報、雷達回波等取自中央氣象局資料開放平台<br /><br />
        # 釣點的天氣預報資料除了降雨機率以及打雷是取自中央氣象局,
        其他的都是由安吉氣象提供(每小時都有資料); 風場圖亦是安吉氣象WRF預報<br /><br />
        # 封溪護漁資訊取自政府資料開放平台
      </article>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/menu/Layout'

export default {
  components: {
    Layout,
  },
}
</script>

<style lang="scss" scoped>

</style>
