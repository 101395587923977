import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { TidalRepo } from '@/repositories/tidal'

export const useTidalStore = (LayerStore) => {
  const state = reactive({
    features: null
  })

  const get_tidal_station_data = async (station_code) => {
    let response = await TidalRepo.tidal_station_data.get({
      "station_code": station_code
    })
    datetime_format(response.data)
    return response.data
  }

  const datetime_format = (datas) => {
    for (let data_a_day of datas.days) {
      data_a_day.day = new Date(data_a_day.day)
      for (let data_a_time of data_a_day.times) {
        data_a_time.time = new Date(data_a_time.time)
      }
    }
    return datas
  }

  const get_features = async () => {
    set_loading(true)
    try {
      let payload = await TidalRepo.tidal_stations.get()
      state.features = markRaw(payload.data)
    }
    finally {
      set_loading(false)
    }
  }

  const set_loading = (val) => LayerStore.set_loading(val, 'tidal')

  watch(() => LayerStore.state.checked_layers.tidal, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    get_tidal_station_data,
    get_features
  }

  provide('TidalStore', store)

  return store
}
