import L from 'leaflet/dist/leaflet-src.js'

var DefaultCWBRadarOptions = {
  /*
  因為麥卡托投影, 上下會偏掉
  */
  bounds: L.latLngBounds([[28.8875, 115.0],[17.8750, 126.5125]]),
  data_x_length: 921,
  data_y_length: 881,
  opacity: 0.7
}


L.RadarCanvasOverlay = L.Layer.extend({

  initialize: function (options) {
    if (!options) {
      options = DefaultCWBRadarOptions
    }
    this.data_bounds = options.bounds
    this.view_bounds = null
    this.visible = false

    // 先寫死吧~~
    this.limit_zoom = 10

    L.setOptions(this, options);
  },

  onAdd: function () {
    this.visible = true
    this._reset()
  },

  onRemove: function () {
    this.visible = false
    if (this._canvas) {
      L.DomUtil.remove(this._canvas)
      this._canvas = null
    }
    if (this._base_canvas) {
      L.DomUtil.remove(this._base_canvas)
      this._base_canvas = null
    }
  },

  bringToFront: function () {
    if (this._canvas) {
      this._map._panes.overlayPane.appendChild(this._canvas);
    }
    return this;
  },

  bringToBack: function () {
    var pane = this._map._panes.overlayPane;
    if (this._canvas) {
      pane.insertBefore(this._canvas, pane.firstChild);
    }
    return this;
  },

  getEvents: function () {
    var events = {
      zoom: this._reset,
      viewreset: this._reset,
      moveend: this._move
    };

    if (this._zoomAnimated) {
      events.zoomanim = this._animateZoom;
    }

    return events;
  },

  _initCanvas: function () {
    this._base_canvas = L.DomUtil.create('canvas', 'leaflet-canvas-layer');
    this._base_canvas.width = this.options.data_x_length
    this._base_canvas.height = this.options.data_y_length

    this._canvas = L.DomUtil.create('canvas', 'leaflet-canvas-layer');
    L.DomUtil.addClass(this._canvas, 'leaflet-image-layer');
    if (this._zoomAnimated) {
      L.DomUtil.addClass(this._canvas, 'leaflet-zoom-animated');
    }

    this._updateOpacity();

    let canvas_bounds = new L.Bounds(
      this._map.latLngToLayerPoint(this.data_bounds.getNorthWest()),
      this._map.latLngToLayerPoint(this.data_bounds.getSouthEast())
    )

    let _zoom = this._map.getZoom()
    if (_zoom <= this.limit_zoom) {
      let size = canvas_bounds.getSize()
      this.view_bounds = this.data_bounds
      L.DomUtil.setPosition(this._canvas, canvas_bounds.getTopLeft())
      this._canvas.style.width  = size.x + 'px'
      this._canvas.style.height = size.y + 'px'
      this._canvas.width = this.options.data_x_length
      this._canvas.height = this.options.data_y_length
      return {left: 0, width: this._canvas.width,
              top: 0, height: this._canvas.height}
    }
    else {
      let orig_size = canvas_bounds.getSize()
      let map_view_bounds = this._map.getBounds()
      let view_pp_bounds = new L.Bounds(
        this._map.latLngToLayerPoint(map_view_bounds.getNorthWest()),
        this._map.latLngToLayerPoint(map_view_bounds.getSouthEast())
      )

      let scale_x = this.options.data_x_length / orig_size.x
      let scale_y = this.options.data_y_length / orig_size.y

      let data_offset_left = 0
      while( (canvas_bounds.min.x + ((data_offset_left + 1.0) / scale_x)) < view_pp_bounds.min.x) {
        data_offset_left ++
      }
      let data_offset_right = 0
      while( (canvas_bounds.max.x - ((data_offset_right + 1.0) / scale_x)) > view_pp_bounds.max.x) {
        data_offset_right ++
      }
      let data_offset_top = 0
      while( (canvas_bounds.min.y + ((data_offset_top + 1.0) / scale_y)) < view_pp_bounds.min.y) {
        data_offset_top ++
      }
      let data_offset_bottom = 0
      while( (canvas_bounds.max.y - ((data_offset_bottom + 1.0) / scale_y)) > view_pp_bounds.max.y) {
        data_offset_bottom ++
      }

      let new_bound_left = (canvas_bounds.min.x + (data_offset_left / scale_x))
      let new_bound_right = (canvas_bounds.max.x - (data_offset_right / scale_x))
      let new_bound_top = (canvas_bounds.min.y + (data_offset_top / scale_y))
      let new_bound_bottom = (canvas_bounds.max.y - (data_offset_bottom / scale_y))
      let crop_size = {
        x: new_bound_right - new_bound_left,
        y: new_bound_bottom - new_bound_top
      }

      L.DomUtil.setPosition(this._canvas, L.point([
        new_bound_left, new_bound_top
      ]))
      this._canvas.style.width  = crop_size.x + 'px'
      this._canvas.style.height = crop_size.y + 'px'
      this._canvas.width = Math.round(crop_size.x * scale_x)
      this._canvas.height = Math.round(crop_size.y * scale_y)

      this.view_bounds = L.latLngBounds(
        this._map.layerPointToLatLng([new_bound_left, new_bound_top]),
        this._map.layerPointToLatLng([new_bound_right, new_bound_bottom])
      )

      return {
        left: data_offset_left,
        top: data_offset_top,
        width: this._canvas.width,
        height: this._canvas.height
      }
    }
  },

  setData: function(data) {
    this._data = data;
    this._reset()
  },

  _drawCanvas: function(data, data_range) {
    let base_ctx = this._base_canvas.getContext('2d')

    if (data && data_range) {
      let image = base_ctx.createImageData(this.options.data_x_length, this.options.data_y_length)
      this._draw_image(data, image)
      base_ctx.putImageData(image, 0, 0)

      let crop_data = base_ctx.getImageData(data_range.left, data_range.top, data_range.width, data_range.height)
      let ctx = this._canvas.getContext('2d')
      ctx.putImageData(crop_data, 0, 0)

      this.getPane().appendChild(this._canvas)
    }
  },

  _draw_image: function(data, image) {
    /*
    基本上對 CWB Radar 已十分了解
    所以相關資訊就直接寫死
    就不處理 covjson 裏的資訊了
    */
    if (data.coverages) {
      for (let cov of data.coverages) {
        let idx = 0
        for (let iy=this.options.data_y_length-1; iy>=0; iy--) {
          for (let ix=0; ix<this.options.data_x_length; ix++) {
            let val = cov.ranges.Radar.values[iy*this.options.data_x_length+ix]
            if (val >= 5) {
              let color = RADAR_COLOR(val)
              let _pidx = idx * 4
              image.data[_pidx] = color[0]
              image.data[_pidx+1] = color[1]
              image.data[_pidx+2] = color[2]
              image.data[_pidx+3] = 255
            }
            idx ++
          }
        }
        break
      }
    }
  },

  _animateZoom: function (e) {
    if (this._canvas && this.view_bounds) {
      var scale = this._map.getZoomScale(e.zoom),
        offset = this._map._latLngBoundsToNewLayerBounds(this.view_bounds, e.zoom, e.center).min;
      L.DomUtil.setTransform(this._canvas, offset, scale);
    }
  },

  _reset: function () {
    if (this._canvas) {
      L.DomUtil.remove(this._canvas)
      this._canvas = null
    }

    if (this._data) {
      let data_range = this._initCanvas()
      this._drawCanvas(this._data, data_range)
    }
  },

  _move: function () {
    if (this._map && this._map.getZoom() > this.limit_zoom) {
      if (this._canvas) {
        L.DomUtil.remove(this._canvas)
        this._canvas = null
      }
      if (this._data) {
        let data_range = this._initCanvas()
        this._drawCanvas(this._data, data_range)
      }
    }
  },

  _onImageLoad: function () {
    this.fire('load');
  },

  _updateOpacity: function () {
    L.DomUtil.setOpacity(this._canvas, this.options.opacity);
  }
});

L.radarCanvasOverlay = function (options) {
  return new L.RadarCanvasOverlay(options);
};

var RADAR_COLOR = function (v) {
  if (v >= 65) {
    return [150, 0, 255]
  }
  if (v >= 64) {
    return [171, 0, 255]
  }
  if (v >= 63) {
    return [192, 0, 255]
  }
  if (v >= 62) {
    return [213, 0, 255]
  }
  if (v >= 61) {
    return [234, 0, 255]
  }
  if (v >= 60) {
    return [255, 0, 255]
  }
  if (v >= 59) {
    return [234, 0, 204]
  }
  if (v >= 58) {
    return [213, 0, 153]
  }
  if (v >= 57) {
    return [192, 0, 102]
  }
  if (v >= 56) {
    return [171, 0, 51]
  }
  if (v >= 55) {
    return [150, 0, 0]
  }
  if (v >= 54) {
    return [160, 0, 0]
  }
  if (v >= 53) {
    return [170, 0, 0]
  }
  if (v >= 52) {
    return [180, 0, 0]
  }
  if (v >= 51) {
    return [190, 0, 0]
  }
  if (v >= 50) {
    return [200, 0, 0]
  }
  if (v >= 49) {
    return [211, 0, 0]
  }
  if (v >= 48) {
    return [222, 0, 0]
  }
  if (v >= 47) {
    return [233, 0, 0]
  }
  if (v >= 46) {
    return [244, 0, 0]
  }
  if (v >= 45) {
    return [255, 0, 0]
  }
  if (v >= 44) {
    return [255, 24, 0]
  }
  if (v >= 43) {
    return [255, 48, 0]
  }
  if (v >= 42) {
    return [255, 72, 0]
  }
  if (v >= 41) {
    return [255, 96, 0]
  }
  if (v >= 40) {
    return [255, 120, 0]
  }
  if (v >= 39) {
    return [255, 136, 0]
  }
  if (v >= 38) {
    return [255, 152, 0]
  }
  if (v >= 37) {
    return [255, 168, 0]
  }
  if (v >= 36) {
    return [255, 184, 0]
  }
  if (v >= 35) {
    return [255, 200, 0]
  }
  if (v >= 34) {
    return [255, 211, 0]
  }
  if (v >= 33) {
    return [255, 222, 0]
  }
  if (v >= 32) {
    return [255, 233, 0]
  }
  if (v >= 31) {
    return [255, 244, 0]
  }
  if (v >= 30) {
    return [255, 255, 0]
  }
  if (v >= 29) {
    return [204, 234, 0]
  }
  if (v >= 28) {
    return [153, 213, 0]
  }
  if (v >= 27) {
    return [102, 192, 0]
  }
  if (v >= 26) {
    return [51, 171, 0]
  }
  if (v >= 25) {
    return [0, 150, 0]
  }
  if (v >= 24) {
    return [0, 160, 0]
  }
  if (v >= 23) {
    return [0, 170, 0]
  }
  if (v >= 22) {
    return [0, 180, 0]
  }
  if (v >= 21) {
    return [0, 190, 0]
  }
  if (v >= 20) {
    return [0, 200, 0]
  }
  if (v >= 19) {
    return [0, 211, 0]
  }
  if (v >= 18) {
    return [0, 222, 0]
  }
  if (v >= 17) {
    return [0, 233, 0]
  }
  if (v >= 16) {
    return [0, 244, 0]
  }
  if (v >= 15) {
    return [0, 255, 0]
  }
  if (v >= 14) {
    return [0, 0, 255]
  }
  if (v >= 13) {
    return [0, 18, 255]
  }
  if (v >= 12) {
    return [0, 36, 255]
  }
  if (v >= 11) {
    return [0, 54, 255]
  }
  if (v >= 10) {
    return [0, 72, 255]
  }
  if (v >= 9) {
    return [0, 91, 255]
  }
  if (v >= 8) {
    return [0, 109, 255]
  }
  if (v >= 7) {
    return [0, 127, 255]
  }
  if (v >= 6) {
    return [0, 145, 255]
  }
  if (v >= 5) {
    return [0, 163, 255]
  }
  if (v >= 4) {
    return [0, 182, 255]
  }
  if (v >= 3) {
    return [0, 200, 255]
  }
  if (v >= 2) {
    return [0, 218, 255]
  }
  if (v >= 1) {
    return [0, 236, 255]
  }
  if (v > 0) {
    return [0, 255, 255]
  }
  return null;
}
