<template>
  <div
    class="flex h-[44px] bg-title flex items-center"
  >
    <div
      class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
      @click="DetailStore.set_station_visible(feature.unique_key, !total_station_visible[feature.unique_key])"
      :class="total_station_visible[feature.unique_key] ? 'icon-symbol_subtract' : 'icon-symbol_add'"
      >
    </div>
    <img class="ml-4 h-7 w-7" :src="get_icon_class()" />
    <div class="w-full my-4 mx-2">
      {{ feature.name }}
    </div>
    <div
      class="mr-4 icon-delete cursor-pointer"
      @click="DetailStore.delete_feature(feature.unique_key)"
    ></div>
  </div>
  <div
    class="min-h-[52px] w-full bg-white"
    v-show="total_station_visible[feature.unique_key]"
  >
    <div
      class="flex overflow-x-auto"
    >
      <button
        v-for="(url, idx) in cctv_urls"
        :key="'btn_' + idx"
        @click="selected_angle = idx"
        class="btn_primary--line flex items-center m-2 rounded-full border-solid border-gray-500"
        :class="{ 'bg-primary text-white': selected_angle === idx }"
      >
        <i class="icon-CCTV mr-[6px] text-xl"></i>{{ idx + 1 }}
      </button>
    </div>
  </div>
  <div
    class="relative w-full min-h-[250px]"
    v-show="total_station_visible[feature.unique_key]"
  >
  <div
    v-show="total_station_loading[feature.unique_key]"
    class="blink_loading"
  ></div>
  <img
      ref="img_elem"
      :src="cctv_urls[selected_angle]?.[selected_timestamp_order]"
      class="z-0 w-full object-contain"
    />
    <div
      v-if="cctv_urls[selected_angle]?.length > 1"
    >
      <i
        class="prev icon-symbol_left text-2xl absolute cursor-pointer left-1/4 bottom-10 text-white"
        @click="plus_timestamp_order(-1)"
      >
      </i>
      <i
        class="next icon-symbol_right text-2xl absolute cursor-pointer right-1/4 bottom-10 text-white"
        @click="plus_timestamp_order(1)"
      >
      </i>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { inject, watch } from '@vue/runtime-core'

export default {
    props: ['feature'],
  setup(props) {
    const selected_angle = ref(0)
    const selected_timestamp_order = ref(0)
    const feature = props.feature
    const DetailStore = inject('DetailStore')
    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const total_station_loading = computed(() => DetailStore.state.total_station_loading)

    const cctv_urls = computed({
      get: () => DetailStore.state.total_station_data.find(station_data => station_data.unique_key === feature.unique_key)?.data
    })

    watch(cctv_urls, (_urls) => {
      DetailStore.set_chart_loading(feature.unique_key, false)
    })

    const plus_timestamp_order = (n) => {
      selected_timestamp_order.value += n
      if (selected_timestamp_order.value >= cctv_urls.value[selected_angle.value].length) {
        selected_timestamp_order.value = 0
      }
      if (selected_timestamp_order.value < 0) {
        selected_timestamp_order.value = cctv_urls.value[selected_angle.value].length - 1
      }
    }

    const get_icon_class = () => {
      return require('@/assets/imgs/CCTV.png')
    }

    return {
      DetailStore,
      feature,
      total_station_visible,
      total_station_loading,
      get_icon_class,
      cctv_urls,
      selected_angle,
      selected_timestamp_order,
      plus_timestamp_order
    }
  }
}
</script>

<style>



</style>