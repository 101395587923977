/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const FishingHarborRepo = {
  allowed_fishing_harbor_fishing: {
    get: () => req("get", 'allowed_fishing_harbor_fishing/'),
  }
}
