
import { reactive, readonly, provide, watch } from 'vue'
import { useRouter } from 'vue-router'

export const useLayerStore = () => {
  const state = reactive({
    selected_target: null,
    checked_layers: {
      cctv: true,
      water_station: true,
      tidal: true,
      rainfall: true,
      thunderstorm: true,
      inshore: false,
      radar: false,
      windy: false,
      rain_station: false,
      tide: false,
      buoy: false,
      fishing_harbor: false
    },
    loading_layers: {
      cctv: false,
      water_station: false,
      tidal: false,
      rainfall: false,
      thunderstorm: false,
      inshore: false,
      radar: false,
      windy: false,
      rain_station: false,
      tide: false,
      buoy: false,
      fishing_harbor: false
    },
    disabled_layers: {
      cctv: false,
      water_station: false,
      tidal: false,
      rainfall: false,
      thunderstorm: false,
      inshore: false,
      radar: false,
      windy: false,
      rain_station: false,
      tide: false,
      buoy: false,
      fishing_harbor: false
    }
  })

  const load_record = () => {
    let checked_layers = JSON.parse(localStorage.getItem('record_checked_layers'))
    if (checked_layers) {
      state.checked_layers = checked_layers
    }
  }

  const set_checked = (checked, layer_type) => {
    state.checked_layers[layer_type] = checked
    localStorage.setItem('record_checked_layers', JSON.stringify(state.checked_layers))
  }

  const set_loading = (val, layer_type) => {
    state.loading_layers[layer_type] = val
  }

  const set_disabled = (val, layer_type) => {
    state.disabled_layers[layer_type] = val
  }

  const set_selected_target = (target) => {
    let layer_type = target.type
    if (layer_type === "station_stage") {
      layer_type = "water_station"
    }
    if (state.checked_layers[layer_type] === false) {
      set_checked(true, layer_type)
    }
    state.selected_target = target
  }

  load_record()

  /* watch */
  const router = useRouter()
  watch(() => state.selected_target, (selected_target) => {
    if (selected_target.type === 'pin' && selected_target.lat && selected_target.lon) {
      router.push({name: 'Details', query: {lat: selected_target.lat, lon: selected_target.lon}})
    }
  })

  const store = {
    state: readonly(state),
    set_checked,
    set_loading,
    set_disabled,
    set_selected_target
  }

  provide('LayerStore', store)

  return store
}
