<template>
  <div id="app" class="hack_iphoneFullScreen">
    <router-view/>
  </div>
</template>

<script>
/** stores */
import { useWeatherDataStore } from '@/compositions/store/WeatherDataStore'
import { useMenuStore } from '@/compositions/store/MenuStore'
import { useMapStore } from '@/compositions/store/MapStore'
import { useLayerStore } from '@/compositions/store/LayerStore'
import { usePinStore } from '@/compositions/store/PinStore'
import { useAlertStore } from '@/compositions/store/AlertStore'
import { useAnnouncementStore } from '@/compositions/store/AnnouncementStore'
import { useWaterStationStore } from '@/compositions/store/WaterStationStore'
import { useTidalStore } from '@/compositions/store/TidalStore'
import { useInshoreStore } from '@/compositions/store/InshoreStore'
import { useCctvStore } from '@/compositions/store/CctvStore'
import { useRadarStore } from '@/compositions/store/RadarStore'
import { useWindyStore } from '@/compositions/store/WindyStore'
import { useRainStationStore } from '@/compositions/store/RainStationStore'
import { useFavoritesStore } from '@/compositions/store/FavoritesStore'
import { useSearchingStore } from '@/compositions/store/SearchingStore'
import { useTideStore } from '@/compositions/store/TideStore'
import { useBuoyStore } from '@/compositions/store/BuoyStore'
import { useFishingHarborStore } from '@/compositions/store/FishingHarborStore'
import { useDetailStore } from '@/compositions/store/DetailStore'

import { useDeviceControl } from '@/compositions/device_control'

export default {
  setup(props) {
    /** 所有 store 生成的地方 */
    const MapStore = useMapStore()
    const LayerStore = useLayerStore()

    const AnnouncementStore = useAnnouncementStore()
    const AlertStore = useAlertStore(LayerStore)
    const RadarStore = useRadarStore(LayerStore)
    const WindyStore = useWindyStore(LayerStore)
    const WeatherDataStore = useWeatherDataStore()

    const DeviceControl = useDeviceControl(AlertStore, AnnouncementStore, RadarStore, WindyStore, WeatherDataStore)
    const FavoritesStore = useFavoritesStore(DeviceControl)

    const PinStore = usePinStore(FavoritesStore)
    const WaterStationStore = useWaterStationStore(LayerStore)
    const TidalStore = useTidalStore(LayerStore)
    const InshoreStore = useInshoreStore(LayerStore)
    const CctvStore = useCctvStore(LayerStore)
    const TideStore = useTideStore(LayerStore)
    const BuoyStore = useBuoyStore(LayerStore)
    useFishingHarborStore(LayerStore)

    useMenuStore()
    const RainStationStore = useRainStationStore(LayerStore)
    useSearchingStore(MapStore ,LayerStore, PinStore, WaterStationStore, TidalStore, CctvStore, RainStationStore, InshoreStore, TideStore, BuoyStore)

    useDetailStore(FavoritesStore, DeviceControl)
  }
}
</script>

<style lang="scss">
@import "assets/font_icon/style.css";

html, body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
}
</style>
