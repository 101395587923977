/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const WeatherDataRepo = {
  obs_data: {
    get: (params) => req("get", "weather_data/obs/rt/", params),
  },
  fcst_data: {
    get: (params) => req("get", "weather_data/fcst/", params),
  },
  town_72hr_fcst_data: {
    get: (params) => req("get", "weather_data/town_72hr_fcst/", params),
  },
  typhoon_status: {
    get: (params) => req("get", "weather_data/typhoon_status/", params),
  }
}