import { CBIconChartPaint } from '@/libs/cb-chart/cb_chart_paint.js'
import { CBAxisXPaint } from '@/libs/cb-chart/cb_axis_paint.js'
import { CBValidDataArtist, CBWSWDDataArtist, CBWeatherIconDataArtist } from '@/libs/cb-chart/cb_weather_chart.js'
import { WeatherIconTable } from '@/settings/weather'


export function ws_ms_to_beaufort(_ws) {
  //
  // lwsu@2021-03-31
  // todo:
  // 這個寫這裏不好
  // 但因為目前一定要用 color table 轉換...
  // 還有 icon 也要用風級轉換
  // 應該是要改寫 get_color and get_icon 的方法
  //
  if (!isNaN(_ws)) {
    if (_ws < -990) {
      return -999
    }
    if (_ws < 0.3) {
      return 0
    } else if (_ws < 1.6) {
        return 1
    } else if (_ws < 3.4) {
        return 2
    } else if (_ws < 5.5) {
        return 3
    } else if (_ws < 8.0) {
        return 4
    } else if (_ws < 10.8) {
        return 5
    } else if (_ws < 13.9) {
        return 6
    } else if (_ws < 17.2) {
        return 7
    } else if (_ws < 20.8) {
        return 8
    } else if (_ws < 24.5) {
        return 9
    } else if (_ws < 28.5) {
        return 10
    } else if (_ws < 32.7) {
        return 11
    } else if (_ws < 37.0) {
        return 12
    } else if (_ws < 41.5) {
        return 13
    } else if (_ws < 46.2) {
        return 14
    } else if (_ws < 51.0) {
        return 15
    } else if (_ws < 56.1) {
        return 16
    } else if (_ws < 61.2) {
        return 17
    } else {
        return 18
    }
  }
  return null
}

export class BufferDataArtist extends CBValidDataArtist {
  yrange() {
    // step1. 找到最大和最小值
    let min = null
    let max = null
    for (let data of this.dataset) {
      if (min === null || min > data.y) {
        min = data.y
      }
      if (max === null || max < data.y) {
        max = data.y
      }
    }

    // step2. 計算範圍
    // base_number: 以此數為基底，找到大於資料最大值且最接近此數的此數倍數，以及小於資料最小值且最接近此數的此數倍數
    let max_base_number = this.options.max_base_number || 10
    let min_base_number = this.options.min_base_number || 10

    max += max_base_number
    min -= min_base_number

    return [
      Math.floor(min/min_base_number) * min_base_number - min_base_number,
      Math.ceil(max/max_base_number) * max_base_number + max_base_number
    ]
  }
}

export class FWMWeatherIconDataArtist extends CBWeatherIconDataArtist {
  constructor(dataset, paint, options={}) {
    options.icon_table = WeatherIconTable
    super(dataset, paint, options)
  }
}


export class WSWDDataArtist extends CBWSWDDataArtist {
  // 覆寫
  // 加上風速風級轉換以取得對應的顏色和圖示
  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (raw_data.y.ws === null || raw_data.y.ws === undefined ||
          raw_data.y.wd === null || raw_data.y.wd === undefined) {
            continue
        }

        let ws_beaufort = ws_ms_to_beaufort(raw_data.y.ws)

        dataset.push({
          x: raw_data.x,
          y: raw_data.y.ws,
          icon: this.get_icon(ws_beaufort),
          color: this.get_color(ws_beaufort),
          rotate: this.get_rotate(raw_data.y.ws, raw_data.y.wd)
        })
      }
    }
    return dataset
  }
}





export class FWMAxisXPaint extends CBAxisXPaint {
  draw(scale, canvas) {
    super.draw(scale, canvas)
    this._draw_timeline(scale, canvas)
    this._draw_night_area(scale, canvas)

    return this
  }

  place_tick_value(text_div, position) {
    super.place_tick_value(text_div, position)
    text_div.style.top = css_unit_join(10, 'px')
    text_div.style.color = 'rgb(127, 127, 127)'
  }

  _draw_night_area(scale, canvas) {
    let tick_list = scale.paint_tick_list(this.options)
    let average_width = (scale.canvas_range[1] - scale.canvas_range[0]) / (tick_list.length-1)
    let ctx = canvas.getContext("2d")

    ctx.globalAlpha = 1

    for (let idx in tick_list) {
      let tick_value = tick_list[idx]
      if (tick_value.getHours() >= 18 || tick_value.getHours() <= 5) {
        ctx.fillStyle = 'rgba(69, 136, 177, 0.05)'
        ctx.fillRect(scale.position(tick_value) - average_width / 2, 0, average_width, canvas.height);
      }
    }
  }

  _draw_timeline(scale, canvas) {
    let tick_list = scale.paint_tick_list(this.options)
    let average_width = (scale.canvas_range[1] - scale.canvas_range[0]) / (tick_list.length-1)
    let ctx = canvas.getContext("2d")
    let current_dtime = new Date()

    for (let idx in tick_list) {
      let tick_value = tick_list[idx]
      if (tick_value < current_dtime) {
        ctx.beginPath()
        ctx.moveTo(scale.position(tick_value) - average_width / 2, 0)
        ctx.lineTo(scale.position(tick_value) - average_width / 2 + average_width, 0)
        ctx.lineWidth = "5"
        ctx.strokeStyle = "rgb(246, 180, 85)"
        ctx.stroke()
        ctx.closePath()
      }
    }
  }
}
