<template>
  <div class="my-2 text-center">
    {{ site_name ? site_name : '--' }}
  </div>
  <div class="relative">
    <div v-show="is_getting_tidal_data" class="blink_loading"></div>
    <div
      id="tidal_container"
      class=" relative w-full h-[300px] overflow-x-auto"
    >
      <canvas
        id="tidal_chart"
        class=" my-4 mx-2"
        width="2800"
        height="260"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import annotationPlugin from 'chartjs-plugin-annotation'

Chart.register(annotationPlugin)

export default {
  name: 'TidalChart',

  inject: ['LayerStore', 'TidalStore'],

  data() {
    return {
      chart: null,
      chart_width: null,
      site_name: '',
      is_getting_tidal_data: false,
    }
  },

  props: {
    tidal_data_list: Array,
  },

  computed: {
    selected_target() {
      return this.LayerStore.state.selected_target
    },
  },

  watch: {
    selected_target: {
      handler(tidal) {
        if (tidal.type === 'tidal') {
          this.get_tidal_data_list(tidal.id)
        }
      },
      immediate: true,
    },
  },

  methods: {
    async get_tidal_data_list(id) {
      this.site_name = ''
      this.is_getting_tidal_data = true

      try {
        let data = await this.TidalStore.get_tidal_station_data(id)
        this.$nextTick(() => {
          this.draw_chart(data)
        })
      } finally {
        this.is_getting_tidal_data = false
      }
    },

    draw_chart(datas) {
      if (document.getElementById('tidal_chart') === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 tidal_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 tidal_chart element 不存在的狀況，防止 console 跳錯。
        return
      }
      // 畫圖前須先清空 chart
      if (this.chart !== null) {
        this.chart.destroy()
      }

      let ctx = document.getElementById('tidal_chart').getContext('2d')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth
      let data_artists = this.get_data_artists(datas)
      let min_y_label = this._get_yaxis_min_label(data_artists)
      let max_y_label = this._get_yaxis_max_label(data_artists)
      let labels = this.get_data_labels(datas)
      this.site_name = datas.name
      var gradient = ctx.createLinearGradient(0, 0, 0, 1000)
      gradient.addColorStop(0, '#2828FF')
      gradient.addColorStop(0.2, '#94d973')
      gradient.addColorStop(0.5, '#fad874')
      gradient.addColorStop(1, '#f49080')
      let data = {
        labels: labels,
        datasets: [
          {
            tension: 0.5, // Bezier curve tension of the line(value during 0~1)
            data: data_artists,
            borderColor: gradient,
            fill: false,
            pointRadius: 5,
          },
        ],
      }

      let config = {
        type: 'line',
        data: data,

        options: {
          responsive: false,
          scales: {
            x: {
              display: true,
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MM/DD ddd',
                },
              },
            },
            y: {
              suggestedMin: min_y_label,
              suggestedMax: max_y_label,
              display: false,
              beginAtZero: true,
              grid: {
                color: 'transparent',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              id: 'y-axis-1',
              position: 'left',
            },
          },
          hover: {
            mode: null,
            animationDuration: 0, // duration of animations when hovering an item
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                now: {
                  type: 'line',
                  borderColor: '#ff7b00',
                  borderWidth: 1,
                  scaleID: 'x',
                  value: new Date().toISOString(),
                },
              },
            },
          },
          animation: {
            duration: 0,
            onComplete: function() {
              let self = this,
                ctx = this.ctx
              ctx.fontColor = 'black'
              ctx.fillStyle = 'black'
              ctx.textAlign = 'center'
              ctx.textBaseline = 'bottom'

              this.data.datasets.forEach(function(dataset, idx) {
                let meta = self.getDatasetMeta(idx)
                meta.data.forEach(function(line, index) {
                  let data = dataset.data[index]
                  let tidal_info_context = `${data.x.strftime('%H:%M')} (${
                    data.tidal_range
                  })`
                  let position_x = line.x
                  let position_y = line.y
                  let time_info_position_y = position_y
                  let slope = null
                  //第一個點跟最後一個點稍微做位移
                  if (index === 0) {
                    position_x = position_x + 30
                    slope = (data.y - 0) / (index + 1)
                  } else if (index === dataset.data.length - 1) {
                    position_x = position_x - 30
                    slope =
                      (data.y - dataset.data[index - 1].y) /
                      (index - (index - 1))
                  } else {
                    slope =
                      (data.y - dataset.data[index - 1].y) /
                      (index - (index - 1))
                  }

                  if (slope >= 0) {
                    position_y = position_y - 10
                    time_info_position_y = position_y - 10
                  } else {
                    position_y = position_y + 20
                    time_info_position_y = position_y + 10
                  }

                  ctx.fillText(tidal_info_context, position_x, position_y)
                })
              })
            },
          },
        },
      }

      this.chart = new Chart(ctx, config)
    },

    get_data_artists(datas) {
      let datasets = []
      for (let day_value of datas.days) {
        for (let time_value of day_value.times) {
          datasets.push({
            x: time_value.time,
            y: time_value.tidal_hight,
            tidal_range: time_value.tidal_range,
          })
        }
      }
      return datasets
    },

    get_data_labels(datas) {
      let labels = []
      for (let day_value of datas.days) {
        for (let time_value of day_value.times) {
          labels.push(time_value.time)
        }
      }
      return labels
    },

    _get_yaxis_min_label(datas) {
      let min_obj = null
      let min_value = -1 //default
      if (datas.length) {
        min_obj = datas.reduce((a, b) => (a.y < b.y ? a : b))
        if (min_obj) {
          let value = min_obj.y
          if (value > 0) {
            min_value = Math.ceil(value) + Math.ceil(value) / 2
          } else {
            min_value = Math.floor(value) + Math.floor(value) / 2
          }
        }
      }
      return min_value
    },

    _get_yaxis_max_label(datas) {
      let max_obj = null
      let max_value = 1 //default
      if (datas.length) {
        max_obj = datas.reduce((a, b) => (a.y > b.y ? a : b))
        if (max_obj) {
          let value = max_obj.y
          if (value > 0) {
            max_value = Math.ceil(value) + Math.ceil(value) / 2
          } else {
            max_value = Math.floor(value) + Math.floor(value) / 2
          }
        }
      }
      return max_value
    },
  },

  beforeUnmount() {
    // chart 須跟著元件被清空
    if (this.chart !== null) {
      this.chart.destroy()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
