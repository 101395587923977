import { watch } from 'vue'

require('@/libs/wind-js/leaflet-windy')

export const useWindyVLayer = (map, LayerStore, WindyStore) => {
  const layer = L.windyLayer({
    key_of_vector_u: 'u',
    key_of_vector_v: 'v',
    colorScale: COLOR_SCALE,
    opacity: 0.8,
    pane: 'shadowPane',
    velocityScale: 0.005,
    particleMultiplier: 1/200,
    lineWidth: 1.5,
    particleAge: 80,
    frameRate: 60,
    trailAge: 0.95
  })

  const assign_data = (windy_data) => {
    layer.setData(windy_data)
  }

  const toggle_layer = (checked) => {
    if (checked) {
      gtag('event', 'view_windy_data')
      map.addLayer(layer)
    }
    else {
      map.removeLayer(layer)
    }
  }

  watch(() => LayerStore.state.checked_layers.windy, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => WindyStore.state.windy_data, (_windy_data) => {
    assign_data(_windy_data)
  }, {
    immediate: true
  })
}

const COLOR_SCALE = [
  "rgb(125, 125, 125)"
]

COLOR_SCALE.indexFor = function(v) {
  return 0
};
