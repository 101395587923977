export const useNotification = (AlertStore, AnnouncementStore) => {
  const get_notifications = () => {
    const alert_messages = AlertStore.state.alert_messages
    const announcements = AnnouncementStore.state.announcements

    return alert_messages.concat(announcements)
  }

  return {
    get_notifications
  }
}
