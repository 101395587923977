import { watch } from 'vue'

export const useInshoreVLayer = (map, LayerStore, InshoreStore) => {
  const layer_group = L.featureGroup()

  const draw_marker = (features) => {
    layer_group.clearLayers()
    if (features) {
      features.forEach(element => {
        let inshore_name = element.name
        let icon = L.divIcon({
          iconSize: null,
          iconAnchor: [50, 50],
          className: null,
          html: '<div class="btn_primary rounded-full py-2 px-4 whitespace-nowrap border border-solid border-white/50">'+inshore_name+'</div>'
        })

        let inshore_marker = L.marker({
          lat: element.lat,
          lon: element.lon
        }, {
          icon: icon
        }).addTo(layer_group)
        inshore_marker.on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          let target = {
            'type': 'inshore',
            'id': element.id,
            'lat': element.lat,
            'lon': element.lon,
            'name': element.name
          }
          LayerStore.set_selected_target(target)
        })
      })
    }
  }

  const toggle_layer = (_checked) => {
    if (_checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  watch(() => LayerStore.state.checked_layers.inshore, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate:true
  })

  watch(() => InshoreStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate:true
  })

}
