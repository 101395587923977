import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/Main.vue'
import Menu from '../views/Menu.vue'
import About from '../views/About.vue'
import Manual from '../views/Manual.vue'
import Resource from '../views/Resource.vue'
import Notification from '../views/Notification.vue'
import HistoryWeather from '../views/HistoryWeather.vue'
import HistoryStationStage from '../views/HistoryStationStage.vue'
import Details from '../views/Details.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/menu/about',
    name: 'About',
    component: About
  },
  {
    path: '/menu/manual',
    name: 'Manual',
    component: Manual
  },
  {
    path: '/menu/resource',
    name: 'Resource',
    component: Resource
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification
  },
  {
    path: '/history/weather/:lat/:lon',
    name: 'HistoryWeather',
    component: HistoryWeather
  },
  {
    path: '/history/weather/:lat/:lon',
    name: 'HistoryWeather',
    component: HistoryWeather
  },
  {
    path: '/history/stage/:id',
    name: 'HistoryStationStage',
    component: HistoryStationStage
  },
  {
    path: '/details/',
    name: 'Details',
    component: Details
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
