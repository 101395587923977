/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const FavoritesRepo = {
  favorites: {
    get: (id, device_id) => {
      return id ? req("get", `favorites/${id}/`) : req("get", `favorites/?device_id=${device_id}`)
    },
    post: (data) => req('post', "favorites/", data),
    patch: (id, data) => req('patch', `favorites/${id}/`, data),
    delete: (id) => req('delete', `favorites/${id}/`)
  },
  reorder: {
    post: (data) => req('post', "favorites/reorder/", data)
  }
}
