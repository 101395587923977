<template>
  <Layout>
    <template v-slot:title>
      使用方法
    </template>
    <template v-slot:main>
      <article class="p-4">
        <h3 class="heading--h3 font-bold">操作動畫 <a class="underline text-blue-700" href="https://youtu.be/Dd_w97pt0JM" target="_blank">YouTube</a></h3>
        <hr class="my-4 border-gray-100" />
        <br />
        <h3 class="heading--h3 font-bold">天氣</h3>
        <hr class="my-4 border-gray-100" />
        使用者在地圖上任意點選一個地點，會出現小紅圈，再點一下小紅圈，會變成紅色地標圖示，
        並顯示該地點為期14日的天氣，點擊日期會顯示當日天氣資料圖表，
        由上而下分別為天氣狀態、預估降雨量/降雨機率、風速/風向/風級、天氣溫度、氣壓值。
        <br />
        可以在天氣圖表上點一下，會顯示詳細的天氣數值。
        <br />
        <picture>
          <source
            media="(max-width: 768px)"
            srcset="
              ../assets/imgs/weather_chart_sample_mobile.png 1x,
              ../assets/imgs/weather_chart_sample_mobile.png 2x
            "
          />
          <img
            class="chart_sample"
            src="../assets/imgs/weather_chart_sample_desktop.png"
          />
        </picture>
        <br />
        點擊地圖任一點，會出現鄰近（2公里）的各類站別，自選點可加入菜園管理，點最下方＋按鈕，點選圖示並按下儲存可自訂是否顯示該站資料，已選取的圖資上會有勾勾，各站詳細資訊可收合、刪除，若有加入菜園管理，會記錄收合及刪除的項目。
        <br />
        瀏覽器會記錄查詢過的地點（不限一處，可點選多處地點查詢該地天氣），只有當下查詢天氣的地點為紅色地標圖示，
        其他標示的地標圖示則呈現灰色，記錄會保留 1 天，每次點擊會重新計算天數。
        <br />
        <h3 class="heading--h3 font-bold mt-8">過去天氣</h3>
        <hr class="my-4 border-gray-100" />
        點擊上述天氣頁日期左方箭頭按鈕，即可查看過去天氣。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">通知</h3>
          <img src="@/assets/imgs/notice.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        當中央氣象局發出大雨、雷雨特報時以及系統管理員公告訊息時，
        系統的鈴鐺會亮起並同步顯示訊息內容，提醒使用者注意安全，
        警報結束或無警報狀態則不顯示。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">菜園管理</h3>
          <img src="@/assets/imgs/love--click.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        點選地圖上的CCTV、觀測水位、潮汐預報、雨量站、潮位站、浮標站以及地標圖示時皆會有一個灰色愛心，點一下會變成紅色並顯示於菜園管理清單當中。
        <img
          class="chart_sample"
          src="../assets/imgs/love--position_chart.jpg"
        />
        <br />
        <img
          class="chart_sample"
          src="../assets/imgs/love--position_CCTV.jpg"
        />
        點選地圖右上角紅色愛心圖示開啟菜園管理畫面，可直接點選清單中項目，查詢瀏覽該地相關氣候，亦可拖曳右邊兩條灰色橫線，移動該項目之排序位置。
        APP版在菜園清單裏「長按」項目名稱可進行編輯及刪除，網頁版則用滑鼠雙點擊即可進行編輯及刪除。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">颱風</h3>
          <div class="btn_icon mx-2 shadow-none">
            <i class="icon-TY"> </i>
          </div>
        </div>
        <hr class="my-4 border-gray-100" />
        若有颱風時，地圖右上角會出現颱風圖示，此圖示若呈現綠色代表有颱風出現，黃色代表有颱風會來台，紅色代表氣象局已發警報，平時沒有颱風則不會顯示。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">圖層</h3>
          <img src="@/assets/imgs/layer.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        點擊可展開及收合，當選取圖層內的功能物件時會呈現綠色(可複選)，其資訊會同時顯現於地圖上供使用者點選查詢，
        反之，若欲取消選取物件，於相對應的圖示再點一下即可，地圖上相關資料就不再顯示。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">CCTV</h3>
          <img src="@/assets/imgs/CCTV.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        為水利署河川局所設置於各地區的攝影機，提供水位即時畫面。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">觀測水位</h3>
          <img src="@/assets/imgs/water_stage.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        水位資料預設在最新更新時間，右側數字代表水位高度(單位:公尺)，在折線圖上滑動，會顯示時間及觀測的水位，觀測資料可追溯至前七天。
        <br />
        表中的黑色水平虛線代表過去半年的通常水位。
        黃、橘、紅色水平實線則是水利署訂定的警戒水位，分別為 3級、2級、1級警戒水位。
        <br />
        <h3 class="heading--h3 font-bold mt-8">過去水位</h3>
        <hr class="my-4 border-gray-100" />
        點擊上述水位頁左上角箭頭，即可查看過去水位。
        藍色實線是所選日期的水位，淡淺藍色實線則是最新一天的水位，便於比較現在與過去的水位差異。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">雨量站</h3>
          <img src="@/assets/imgs/precipitation.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        圖示上的小Popup顯示24小時累積雨量，超過3小時沒資料或沒缺值則顯示 "--"，紫色圓圈中若出現數字，表示該地區有多個雨量站，可點選即自動放大地圖，展開顯示該區雨量站之分布，
        雨量站由柱狀圖呈現(單位：毫米)，當滑鼠游標移過去或點擊某一條柱狀圖時，會變成橘色並顯示降雨時間及降雨量。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">沿海預報</h3>
          <img src="@/assets/imgs/layer--wave.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        針對濱海鄉鎮或島嶼沿海提供三天的海氣象預報資訊。點選欲查詢地圖上標示的鄉鎮沿海區塊即顯示當地詳細資料，
        由上而下分別為日期、時間(每三小時為一個區間，已過去的時間不顯示）、天氣狀態、風速/風向/風級、流速、浪高。
        風的圖示包含風向箭頭與顏色圈圈，圈圈的顏色表現風速大小，藍色表示風速較小，越接近橘紅色，風速越大，點擊風的圖示會顯示風速的數值和級數。
        流速為海流流速(單位:公里/時)。最下方藍色區域為浪高圖層，將浪高的數值以圖像化呈現。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">潮汐預報</h3>
          <img src="@/assets/imgs/tidal.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        以曲線圖呈現潮位差，曲線的每個高低點皆標示時間及潮差大小。
        另有半透明的圖示，表示該河川的水位受潮汐影響，點進去顯示該河川河口之潮汐資訊。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">潮位站</h3>
          <img src="@/assets/imgs/tide_station.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        會顯示最新海象觀測資料。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">浮標站</h3>
          <img src="@/assets/imgs/buoy.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        會顯示最新海象觀測資料。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">豪/大雨特報</h3>
          <img src="@/assets/imgs/layer--heavy_rain.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        將此功能開啟可得知豪/大雨特報之示警範圍，以半透明的黃色圖層覆蓋於地圖上表示該區為示警區域。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">雷雨特報</h3>
          <img src="@/assets/imgs/layer--thunder-heavy_rain.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        將此功能開啟可得知雷雨特報之示警範圍，以半透明的紫色圖層覆蓋於地圖上表示該區為示警區域。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">雷達回波</h3>
          <img src="@/assets/imgs/layer--radar.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        可藉由雷達回波圖判斷降雨強度及分布狀況，由弱至強以藍、綠、黃、橘、紅、紫的顏色漸層來表示，訊號越強的地區就代表示雨勢越大。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">風場</h3>
          <img src="@/assets/imgs/layer--wind_field.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        提供當下風速跟風向資訊。
        <br />
        <br />
        <div class="sub_img_title mt-4">
          <h3 class="heading--h3 font-bold">合法垂釣港口</h3>
          <img src="@/assets/imgs/port.png" />
        </div>
        <hr class="my-4 border-gray-100" />
        若開啟，地圖會顯示合法垂釣港口的位置。
        <br />
        <br />
      </article>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/menu/Layout'

export default {
  components: {
    Layout,
  },
}
</script>

<style lang="scss" scoped>
.sub_img_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}
.sub_img_title > img {
  height: 32px;
  margin-left: 0.5rem;
  width: 32px;
}
.chart_sample {
  display: block;
  margin: auto;
  width: 340px;
}
@media (min-width: 768px) {
  .chart_sample {
    width: 380px;
  }
}
</style>
