import { watch, ref } from 'vue'
import { cb_alert } from '@/libs/cb-theme'
import { Geolocation } from '@capacitor/geolocation';


const DEFAULT_OPTIONS = {
  minZoom: 8,
  maxZoom: 20
}

const FOCUS_ZOOM = 14



export const useVMap = (map_id, MapStore, SearchingStore, options) => {
  const _options = Object.assign(DEFAULT_OPTIONS, options)
  const location_icon = L.divIcon({
    html:"<div class='location'><div class='location_point'></div><div class='location_point-rim'></div></div>",
    className: '!pointer-events-none', // 設定 pointer-event 可以穿透不會擋到其他 marker
    iconSize: [30, 30],
    iconAnchor: [15, 15]
  })

  let location_marker = L.marker({lat:0, lng:0}, { icon: location_icon })
  const locate = () => {
    gtag('event', 'get_user_position')
    const coordinatesPromise = Geolocation.getCurrentPosition();
    coordinatesPromise.then((value) => {
      location_marker.setLatLng([value.coords.latitude, value.coords.longitude]).addTo(map)
      let zoom_level = map.getZoom()
      if (zoom_level < 14) {
        zoom_level = 14
      }
      map.flyTo([value.coords.latitude, value.coords.longitude], zoom_level)
    }).catch((e) => {
      console.warn("get_user_position", e)
      location_marker.removeFrom(map)
      cb_alert({
        title: '您尚未開啟定位',
        content: '請前往您的手機設定開啟定位',
        ok_text: '我知道了',
        title_icon_required: false
      })
    })
  }

  const map = L.map(map_id, {
    minZoom: _options.minZoom,
    maxZoom: _options.maxZoom,
    zoomControl: false
  }).setView(MapStore.state.center, MapStore.state.zoom_level)

  map.createPane('favoritesPane')
  map.getPane('favoritesPane').style.zIndex = 601

  const baselayers = {
    street: L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: '',
      maxNativeZoom: 22,
      maxZoom: _options.maxZoom
    }),
    satellite: L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: '',
      maxNativeZoom: 21,
      maxZoom: _options.maxZoom
    }),
  }

  const update_baselayer = (layer_name) => {
    if (!(layer_name in baselayers)) {
      return
    }
    for (let key in baselayers) {
      if (key === layer_name) {
        map.addLayer(baselayers[key])
      } else {
        map.removeLayer(baselayers[key])
      }
    }
  }

  watch(() => MapStore.state.selected_baselayer, baselayer => {
    update_baselayer(baselayer)
  }, {
    immediate: true
  })

  map.attributionControl.setPrefix('')
  map.on("mousemove", (e) => {
    var _s = sprintf("%0.4f , %0.4f", e.latlng.lng, e.latlng.lat);
    map.attributionControl.setPrefix(_s);
  })

  L.control.scale({
    imperial: false,
    position: 'bottomleft'
  }).addTo(map)

  map.on('zoomend', e => {
    MapStore.set_center(map.getCenter())
    MapStore.set_zoom_level(map.getZoom())
  })

  map.on('moveend', e => {
    MapStore.set_center(map.getCenter())
  })

  watch(() => MapStore.state.fly_to_latlon, (position) => {
    map.flyTo(position, FOCUS_ZOOM, {
      animate: true,
      duration: 2
    })
  })
  return { map, locate }
}
