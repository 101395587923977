<template>
  <div
    class="flex h-[44px] bg-title flex items-center"
  >
    <div
      class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
      @click="DetailStore.set_station_visible(feature.unique_key, !total_station_visible[feature.unique_key])"
      :class="total_station_visible[feature.unique_key] ? 'icon-symbol_subtract' : 'icon-symbol_add'"
      >
    </div>
    <img class="ml-4 h-7 w-7" :src="get_icon_class(feature.type_en)" />
    <div class="w-full my-4 mx-2">
      <!-- 顯示雨量測站名稱與即時資料 -->
      {{ feature.name }}
    </div>
    <div
      class="mr-4 icon-delete cursor-pointer"
      @click="DetailStore.delete_feature(feature.unique_key)"
    ></div>
  </div>
  <div class="relative">
    <div v-show="total_station_loading[feature.unique_key]" class="blink_loading"></div>
    <div
      :id="feature.unique_key + '_tidal_container'"
      class=" relative w-full h-[300px] overflow-x-auto"
      v-show="total_station_visible[feature.unique_key]"
    >
      <canvas
        :id="feature.unique_key + '_tidal_chart'"
        class=" my-4 mx-2"
        width="2800"
        height="260"
      ></canvas>
    </div>
  </div>
</template>

<script>
import { watch, inject, nextTick, computed, onBeforeUnmount } from 'vue'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import annotationPlugin from 'chartjs-plugin-annotation'

Chart.register(annotationPlugin)

export default {
  name: 'DetailTidalChart',
  props: ['feature'],
  // inject: ['LayerStore', 'TidalStore'],

  setup(props) {
    const feature = props.feature
    const DetailStore = inject('DetailStore')
    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const total_station_loading = computed(() => DetailStore.state.total_station_loading)

    let chart = null

    const tidal_data = computed({
      get: () => DetailStore.state.total_station_data.find(station_data => station_data.unique_key === feature.unique_key)?.data
    })

    watch(
      [tidal_data, () => total_station_visible.value[feature.unique_key]], async ([datas, visible]) => {
        if (visible) {
          if (datas) {
            await nextTick()
            draw_chart(datas)
          }
        }
      },{
        // immediate: true,
        deep: true
      }
    )

    const draw_chart = (datas) => {
      if (document.getElementById(`${feature.unique_key}_tidal_chart`) === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 tidal_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 tidal_chart element 不存在的狀況，防止 console 跳錯。
        return
      }

      // 畫圖前須先清空 chart
      if (chart !== null) {
        chart.destroy()
      }

      if (!datas?.days) {
        return
      }
      let ctx = document.getElementById(`${feature.unique_key}_tidal_chart`).getContext('2d')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth
      let data_artists = get_data_artists(datas)
      let min_y_label = _get_yaxis_min_label(data_artists)
      let max_y_label = _get_yaxis_max_label(data_artists)
      let labels = get_data_labels(datas)
      var gradient = ctx.createLinearGradient(0, 0, 0, 1000)
      gradient.addColorStop(0, '#2828FF')
      gradient.addColorStop(0.2, '#94d973')
      gradient.addColorStop(0.5, '#fad874')
      gradient.addColorStop(1, '#f49080')
      let data = {
        labels: labels,
        datasets: [
          {
            tension: 0.5, // Bezier curve tension of the line(value during 0~1)
            data: data_artists,
            borderColor: gradient,
            fill: false,
            pointRadius: 5,
          },
        ],
      }

      let config = {
        type: 'line',
        data: data,

        options: {
          responsive: false,
          scales: {
            x: {
              display: true,
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MM/DD ddd',
                },
              },
            },
            y: {
              suggestedMin: min_y_label,
              suggestedMax: max_y_label,
              display: false,
              beginAtZero: true,
              grid: {
                color: 'transparent',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              id: 'y-axis-1',
              position: 'left',
            },
          },
          hover: {
            mode: null,
            animationDuration: 0, // duration of animations when hovering an item
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                now: {
                  type: 'line',
                  borderColor: '#ff7b00',
                  borderWidth: 1,
                  scaleID: 'x',
                  value: new Date().toISOString(),
                },
              },
            },
          },
          animation: {
            duration: 0,
            onComplete: function() {
              let self = this
              ctx.fontColor = 'black'
              ctx.fillStyle = 'black'
              ctx.textAlign = 'center'
              ctx.textBaseline = 'bottom'
              data.datasets.forEach((dataset, idx) => {
                let meta = self.getDatasetMeta(idx)
                meta.data.forEach(function(line, index) {
                  let data = dataset.data[index]
                  let tidal_info_context = `${data.x.strftime('%H:%M')} (${
                    data.tidal_range
                  })`
                  let position_x = line.x
                  let position_y = line.y
                  let time_info_position_y = position_y
                  let slope = null
                  //第一個點跟最後一個點稍微做位移
                  if (index === 0) {
                    position_x = position_x + 30
                    slope = (data.y - 0) / (index + 1)
                  } else if (index === dataset.data.length - 1) {
                    position_x = position_x - 30
                    slope =
                      (data.y - dataset.data[index - 1].y) /
                      (index - (index - 1))
                  } else {
                    slope =
                      (data.y - dataset.data[index - 1].y) /
                      (index - (index - 1))
                  }

                  if (slope >= 0) {
                    position_y = position_y - 10
                    time_info_position_y = position_y - 10
                  } else {
                    position_y = position_y + 20
                    time_info_position_y = position_y + 10
                  }

                  ctx.fillText(tidal_info_context, position_x, position_y)
                })
              })
            },
          },
        },
      }
      DetailStore.set_chart_loading(feature.unique_key, false)
      chart = new Chart(ctx, config)
    }

    const get_data_artists = (datas) => {
      let datasets = []
      for (let day_value of datas.days) {
        for (let time_value of day_value.times) {
          datasets.push({
            x: time_value.time,
            y: time_value.tidal_hight,
            tidal_range: time_value.tidal_range,
          })
        }
      }
      return datasets
    }

    const get_data_labels = (datas) => {
      let labels = []
      for (let day_value of datas.days) {
        for (let time_value of day_value.times) {
          labels.push(time_value.time)
        }
      }
      return labels
    }

    const _get_yaxis_min_label = (datas) => {
      let min_obj = null
      let min_value = -1 //default
      if (datas.length) {
        min_obj = datas.reduce((a, b) => (a.y < b.y ? a : b))
        if (min_obj) {
          let value = min_obj.y
          if (value > 0) {
            min_value = Math.ceil(value) + Math.ceil(value) / 2
          } else {
            min_value = Math.floor(value) + Math.floor(value) / 2
          }
        }
      }
      return min_value
    }

    const _get_yaxis_max_label = (datas) => {
      let max_obj = null
      let max_value = 1 //default
      if (datas.length) {
        max_obj = datas.reduce((a, b) => (a.y > b.y ? a : b))
        if (max_obj) {
          let value = max_obj.y
          if (value > 0) {
            max_value = Math.ceil(value) + Math.ceil(value) / 2
          } else {
            max_value = Math.floor(value) + Math.floor(value) / 2
          }
        }
      }
      return max_value
    }

    const get_icon_class = (type) => {
      if (type === 'pin') {
        return require('@/assets/imgs/map_pin--click.png')
      } else if (type === 'cctv') {
        return require('@/assets/imgs/CCTV.png')
      } else if (type === 'tidal_station') {
        return require('@/assets/imgs/tidal.png')
      } else if (type === 'water_station') {
        return require('@/assets/imgs/water_stage.png')
      } else if (type === 'rain_station') {
        return require('@/assets/imgs/precipitation.png')
      } else if (type === 'tide') {
        return require('@/assets/imgs/tide_station.png')
      } else if (type === 'buoy') {
        return require('@/assets/imgs/buoy.png')
      }
    }

    onBeforeUnmount(() => {
      // chart 須跟著元件被清空
      if (chart !== null) {
        chart.destroy()
      }
    })

    return {
      feature,
      DetailStore,
      total_station_visible,
      total_station_loading,
      get_icon_class,
      tidal_data
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
