<template>
  <div class="relative">
    <select
      class="input--base appearance-none pr-9 cursor-pointer w-full"
      :class="select_class"
      v-model="selected_item"
    >
      <slot>
        <option :value="null">請選擇...</option>
        <option value="1">option1</option>
        <option value="2">option2</option>
      </slot>
    </select>
    <i class="icon-arrow_down vertical_center right-3 text-dark-50 pointer-events-none"></i>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'


export default {
  props: {
    modelValue: {
      default: null
    },

    select_class: {
      type: String,
      default: null
    }
  },

  emit: ['update:modelValue'],

  setup(props, { emit }) {
    const selected_item = computed({
      get: () => props.modelValue,
      set: (val) => emit('update:modelValue', val)
    })

    return {
      selected_item
    }
  }
}
</script>

<style>

/* @import "./base_font_icon/style.css"; */

</style>
