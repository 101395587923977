import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { WaterStationRepo } from '@/repositories/water_station'
import { RainStationRepo } from '@/repositories/rain_station'
import { TidalRepo } from '@/repositories/tidal'
import { MarineRepo } from '@/repositories/marine.js'
import { InshoreRepo }from '@/repositories/inshore'
import { CctvRepo } from '@/repositories/cctv'
import { MarkerCollectionsRepo } from '@/repositories/marker_collections'

export const useDetailStore = (FavoritesStore, DeviceControl) => {
  const state = reactive({
    collection_id: null,
    pin_latlon: [],
    // 這是各圖資要回點為資訊時暫時存放的
    water_station_features: null,
    rain_station_features: null,
    tidal_station_features: null,
    tide_station_features: null,
    inshore_area_features: null,
    buoy_station_features: null,
    cctv_features: null,

    total_station_data_feature: [],
    total_station_data: [],

    weather_visible: true,
    total_station_visible: {},
    total_station_loading: {},

    temp_unique_key_properties_mapping: {},
    temp_selected_features: []
  })

  const refresh_all_features = (lat, lon) => {
    get_water_station_features(lat, lon)
    get_rain_station_features(lat, lon)
    get_tidal_station_features(lat, lon)
    get_tide_station_features(lat, lon)
    get_inshore_area_features(lat, lon)
    get_buoy_station_features(lat, lon)
    get_cctv_features(lat, lon)
  }

  const get_weather_unique_key = () => {
    return `pin_${state.pin_latlon[0]}_${state.pin_latlon[1]}`
  }

  const check_collections = async (lat, lon) => {
    // 這裏不清掉會抓到舊的
    state.collection_id = null
    const payload = await MarkerCollectionsRepo.marker_collections.get({
      device_id: DeviceControl.state.device_id,
      weather_unique_key: get_weather_unique_key()
    })
    if (payload?.data) {
      state.collection_id = payload.data.id
      return await payload.data
    }
    return {}
  }

  const set_pin_latlon = (lat, lon) => {
    state.pin_latlon = [lat, lon]
  }

  watch(() => state.total_station_data_feature, _total_station_data_feature => {
    const list = []
    _total_station_data_feature.forEach(item => {
      list.push(item)
    })
    state.temp_selected_features = list
  }, {
    deep: true,
    immediate: true
  })

  watch(() => state.temp_selected_features, (temp_selected_features) => {
    const mapping = {}
    temp_selected_features.forEach(item => {
      mapping[item.unique_key] = item
    })
    state.temp_unique_key_properties_mapping = mapping
  }, {
    deep: true,
    immediate: true
  })

  const add_temp_selected_features = (feature) => {
    state.temp_selected_features.push(feature)
  }

  const remove_temp_selected_features = (feature) => {
    const idx = state.temp_selected_features.findIndex(item => item.unique_key === feature.unique_key)
    if (idx >= 0) {
      state.temp_selected_features.splice(idx, 1)
    }
  }

  const reset_total_station_data_feature = (features) => {
    let old_features = new Set(state.total_station_data_feature.map(f => f.unique_key))

    features.forEach(feature => {
      if (!old_features.has(feature.unique_key)) {
        set_station_visible(feature.unique_key, true)
        set_chart_loading(feature.unique_key, true)
        set_total_default_station_data(feature)
        switch (feature.type_en) {
          case 'rain_station':
            get_rain_station_obs(feature)
            break;
          case 'water_station':
            get_water_station_stage(feature)
            break;
          case 'tidal_station':
            get_tidal_station_fcst(feature)
            break;
          case 'inshore_area':
            get_inshore_area_data(feature)
            break;
          case 'buoy_station':
            get_buoy_station_obs(feature)
            break;
          case 'tide_station':
            get_tide_station_obs(feature)
            break;
          case 'cctv':
            get_cctv_images(feature)
            break;

          default:
            break;
        }
      }
    })

    state.total_station_data_feature = features
  }

  const set_total_station_data_feature = (features) => {
    features.forEach(feature => {
      set_total_default_station_data(feature)
      switch (feature.type_en) {
        case 'rain_station':
          get_rain_station_obs(feature)
          break;
        case 'water_station':
          get_water_station_stage(feature)
          break;
        case 'tidal_station':
          get_tidal_station_fcst(feature)
          break;
        case 'inshore_area':
          get_inshore_area_data(feature)
          break;
        case 'buoy_station':
          get_buoy_station_obs(feature)
          break;
        case 'tide_station':
          get_tide_station_obs(feature)
          break;
        case 'cctv':
          get_cctv_images(feature)
          break;

        default:
          break;
        }
    })
    state.total_station_data_feature = features
  }

  const save_collections = async () => {
    let _features = []
    state.total_station_data_feature.forEach((feature) => {
      _features.push({
        ...feature,
        visible: state.total_station_visible[feature.unique_key],
      })
    })

    const [lat, lon] = state.pin_latlon
    if (FavoritesStore.state.favorites_mapping[`pin,${lat},${lon}`]) {
      if (state.collection_id === null) {
        await MarkerCollectionsRepo.marker_collections.post({
          device_id: DeviceControl.state.device_id,
          weather_unique_key: get_weather_unique_key(),
          weather_visible: state.weather_visible,
          features: _features
        })
      } else {
        await MarkerCollectionsRepo.marker_collections.patch(state.collection_id, {
          weather_visible: state.weather_visible,
          features: _features
        })
      }
    } else {
      if (state.collection_id) {
        MarkerCollectionsRepo.marker_collections.delete(state.collection_id)
      }
      state.collection_id = null
    }
  }

  const reset_default_store = () => {
    state.water_station_features = null
    state.rain_station_features = null
    state.tidal_station_features = null
    state.tide_station_features = null
    state.inshore_area_features = null
    state.buoy_station_features = null
    state.cctv_features = null
    state.total_station_data = []
    state.total_station_data_feature = []
    state.total_station_visible = {}
    state.total_station_loading = {}
  }

  const set_station_control_config = ((unique_key, flag=true) => {
    state.total_station_loading[unique_key] = flag
    state.total_station_visible[unique_key] = flag
  })

  const set_weather_visible = ((visible) => {
    state.weather_visible = visible
  })

  const water_station_datetime_format = (info) => {
    for (let detail_info of info) {
      if (detail_info.dtime) {
        detail_info.dtime = new Date(detail_info.dtime)
      }
    }
    return info
  }

  const set_total_default_station_data = ((feature) => {
    state.total_station_data.push({
      unique_key: feature.unique_key,
      data: []
    })
  })

  const get_water_station_features = async (lat, lon) => {
    let payload = await WaterStationRepo.station_info.get({
      lat: lat,
      lon: lon
    })
    state.water_station_features = markRaw(payload.data)
  }

  const get_rain_station_features = async (lat, lon) => {
    let payload = await RainStationRepo.station_info.get({
      lat: lat,
      lon: lon
    })
    state.rain_station_features = markRaw(payload.data)
  }

  const get_water_station_stage = async(feature) => {
    let dt_to = new Date()
    let dt_from = dt_to.addDays(-7)
    try {
      let payload = await WaterStationRepo.station_stage.get({
        'station_code': feature.station_code,
        'dt_from': dt_from,
        'dt_to': dt_to,
      })
      if (payload.data.obs.length) {
        payload.data.obs = water_station_datetime_format(payload.data.obs)
      }
      if (payload.data.fcst.length) {
        payload.data.fcst = water_station_datetime_format(payload.data.fcst)
      }
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data
    } catch (error) {
      console.warn(error)
    }
  }

  const rain_station_datetime_format = (info) => {
    for (let detail_info of info) {
      if (detail_info.dtime) {
        detail_info.dtime = new Date(detail_info.dtime)
      }
    }
    return info
  }

  const get_rain_station_obs = async(feature) => {
    let dt_to = new Date()
    let dt_from = dt_to.addDays(-7)
    try {
      let payload = await RainStationRepo.obs.get({
        'station_id': feature.id,
        'dt_from': dt_from,
        'dt_to': dt_to,
      })
      payload.data = rain_station_datetime_format(payload.data)
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data
    } catch (error) {
      console.warn(error)
    }
  }

  const get_tidal_station_features = async (lat, lon) => {
    let payload = await TidalRepo.tidal_stations.get({
      lat: lat,
      lon: lon
    })
    state.tidal_station_features = markRaw(payload.data)
  }

  const tidal_station_datetime_format = (datas) => {
    for (let data_a_day of datas.days) {
      data_a_day.day = new Date(data_a_day.day)
      for (let data_a_time of data_a_day.times) {
        data_a_time.time = new Date(data_a_time.time)
      }
    }
    return datas
  }

  const get_tidal_station_fcst = async(feature) => {
    if (!feature.id) { return }
    try {
      let payload = await TidalRepo.tidal_station_data.get({
        'station_code': feature.id,
      })
      payload.data = tidal_station_datetime_format(payload.data)
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data
    } catch (error) {
      console.warn(error)
    }
  }

  const get_tide_station_features = async (lat, lon) => {
    let payload = await MarineRepo.station.get({
      category: 0,
      lat: lat,
      lon: lon
    })
    state.tide_station_features = markRaw(payload.data)
  }

  const get_tide_station_obs = async (feature) => {
    let payload = await MarineRepo.station_data.get({
      station_id: feature.id,
      from: new Date().zero_minute().addHours(-6).isostrz(),
      to: new Date().zero_minute().isostrz()
    })

    if (payload.data?.length > 0) {
      for (let data of payload.data) {
        if (data.dtime) {
          data.dtime = new Date(data.dtime)
        }
      }
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data[payload.data.length - 1]
    }
  }

  const get_buoy_station_features = async (lat, lon) => {
    let payload = await MarineRepo.station.get({
      category: 1,
      lat: lat,
      lon: lon
    })
    state.buoy_station_features = markRaw(payload.data)
  }

  const get_buoy_station_obs = async (feature) => {
    let payload = await MarineRepo.station_data.get({
      station_id: feature.id,
      from: new Date().zero_minute().addHours(-6).isostrz(),
      to: new Date().zero_minute().isostrz()
    })

    for (let data of payload.data) {
      if (data.dtime) {
        data.dtime = new Date(data.dtime)
      }
    }
    state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data[payload.data.length - 1]
  }

  const get_inshore_area_features = async (lat, lon) => {
    let payload = await InshoreRepo.inshore_areas.get({
      lat: lat,
      lon: lon
    })
    state.inshore_area_features = markRaw(payload.data)
  }

  const get_inshore_area_data = async (feature) => {
    try {
      let payload = await InshoreRepo.inshore_area_data.get({
        "area_id": feature.id
      })
      payload.data = inshore_area_datetime_format(payload.data)
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data
    } catch (error) {
      console.warn(error)
    }
  }

  const inshore_area_datetime_format = (datas) => {
    for (let detail_data of datas) {
      if (detail_data.time) {
        detail_data.time = new Date(detail_data.time)
      }
    }
    return datas
  }

  const get_cctv_features = async (lat, lon) => {
    let payload = await CctvRepo.cctv.get({
      lat: lat,
      lon: lon
    })
    state.cctv_features = markRaw(payload.data)
  }

  const get_cctv_images = async (feature) => {
    try {
      let payload = await CctvRepo.cctv_imgs.get(feature.id)
      state.total_station_data.find((station) => station.unique_key == feature.unique_key).data = payload.data
    } catch (error) {
      console.warn(error)
    }
  }

  const set_station_visible = (name, visible) => {
    state.total_station_visible[name] = visible
  }

  const set_chart_loading = (name, is_loading) => {
    state.total_station_loading[name] = is_loading
  }

  watch(
    [() => state.water_station_features],
    ([_water_station_features]) => {
      if (_water_station_features) {
        _water_station_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_water_station_stage(feature)
        })
        state.total_station_data_feature.push(..._water_station_features)
      }
    }
  )

  watch(
    [() => state.rain_station_features],
    ([_rain_station_features]) => {
      if (_rain_station_features) {
        _rain_station_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_rain_station_obs(feature)
        })
        state.total_station_data_feature.push(..._rain_station_features)
      }
    }
  )

  watch(
    [() => state.tidal_station_features],
    ([_tidal_station_features]) => {
      if (_tidal_station_features) {
        _tidal_station_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_tidal_station_fcst(feature)
        })
        state.total_station_data_feature.push(..._tidal_station_features)
      }
    }
  )

  watch(
    [() => state.inshore_area_features],
    ([_inshore_area_features]) => {
      if (_inshore_area_features) {
        _inshore_area_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_inshore_area_data(feature)
        })
        state.total_station_data_feature.push(..._inshore_area_features)
      }
    }
  )

  watch(
    [() => state.tide_station_features],
    ([_tide_station_features]) => {
      if (_tide_station_features) {
        _tide_station_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_tide_station_obs(feature)
        })
        state.total_station_data_feature.push(..._tide_station_features)
      }
    }
  )

  watch(
    [() => state.buoy_station_features],
    ([_buoy_station_features]) => {
      if (_buoy_station_features) {
        _buoy_station_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_buoy_station_obs(feature)
        })
        state.total_station_data_feature.push(..._buoy_station_features)
      }
    }
  )

  watch(
    [() => state.cctv_features],
    ([_cctv_features]) => {
      if (_cctv_features) {
        _cctv_features.forEach((feature) => {
          set_station_control_config(feature.unique_key)
          set_total_default_station_data(feature)
          get_cctv_images(feature)
        })
        state.total_station_data_feature.push(..._cctv_features)
      }
    }
  )

  const delete_feature = (unique_key) => {
    state.total_station_data_feature = state.total_station_data_feature.filter(feature => {
      return feature.unique_key !== unique_key
    })
    gtag('event', 'remove collection marker')
  }

  const store = {
      state: readonly(state),
      reset_default_store,
      get_water_station_features,
      get_water_station_stage,
      get_rain_station_features,
      set_total_default_station_data,
      get_tidal_station_features,
      get_tide_station_features,
      get_inshore_area_features,
      get_buoy_station_features,
      set_station_visible,
      set_chart_loading,
      delete_feature,
      get_cctv_features,
      set_station_control_config,
      reset_total_station_data_feature,
      set_total_station_data_feature,
      add_temp_selected_features,
      remove_temp_selected_features,
      set_pin_latlon,
      check_collections,
      save_collections,
      set_weather_visible,
      refresh_all_features
    }
    provide('DetailStore', store)
    return store
}