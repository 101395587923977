import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { WaterStationRepo } from '@/repositories/water_station'

export const useWaterStationStore = (LayerStore) => {
  const state = reactive({
    data: {
      obs: [],
      fcst: [], // 沒用到了
      stage_mode: null,
    },
    yesterday2today_data: [],
    is_getting_station_stage_data: false,
    features: null,
    history_selected_time: null
  })

  const get_station_stage = async (station_code, dt_from, dt_to) => {
    state.data.obs = []
    state.data.fcst = []
    try {
      let payload = await WaterStationRepo.station_stage.get({
        'station_code': station_code,
        'dt_from': dt_from,
        'dt_to': dt_to,
      })
      payload.data.obs = datetime_format(payload.data.obs)
      payload.data.fcst = datetime_format(payload.data.fcst)

      state.data.obs = payload.data.obs
      state.data.fcst = payload.data.fcst
      state.data.stage_mode = payload.data.stage_mode
      state.history_selected_time = dt_to
    } catch (error) {
      console.warn(error)
    }
    state.is_getting_station_stage_data = false
    return state.data
  }

  const get_yesterday2now_station_stage = async (station_code, dt_from, dt_to) => {
    state.yesterday2today_data = []
    let payload = await WaterStationRepo.station_stage.get({
      'station_code': station_code,
      'dt_from': dt_from,
      'dt_to': dt_to,
    })
    state.yesterday2today_data = datetime_format(payload.data.obs)
  }

  const datetime_format = (info) => {
    for (let detail_info of info) {
      if (detail_info.dtime) {
        detail_info.dtime = new Date(detail_info.dtime)
      }
    }
    return info
  }

  const get_features = async () => {
    set_layer_loading(true)
    try {
      let payload = await WaterStationRepo.station_info.get()
      state.features = markRaw(payload.data)
    }
    finally {
      set_layer_loading(false)
    }
  }

  const set_layer_loading = (val) => LayerStore.set_loading(val, 'water_station')

  /* watch */
  watch(() => LayerStore.state.checked_layers.water_station, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    get_station_stage,
    get_yesterday2now_station_stage,
    get_features
  }
  provide('WaterStationStore', store)
  return store
}
