<template>
  <div class=" z-modal left-0 top-0 absolute w-full h-full">
    <!-- 動態島 -->
    <div class=" top-5 w-full z-above_map absolute text-center">
      <span class=" py-1 px-2 rounded-full bg-gray-100 ">點選圖示可自訂是否顯示該站資料</span>
    </div>

    <!-- 地圖 -->
    <!-- z-map 是自訂變數，代表 z-index: 1000 -->
    <!-- 因在 ios 裝置，地圖 z-index 有可能跑掉才多加設定 -->
    <div ref="map_ref" id="selector_map" class=" z-map h-full"></div>

    <div class=" h-1/10 w-full z-above_map absolute bottom-0 bg-gray-500 bg-opacity-70 flex justify-center items-center">
      <button
        class="btn_icon btn_primary rounded-full py-2 px-5 mr-5"
        @click="save"
        >儲存</button>
      <button
        class="btn_icon bg-gray-100 rounded-full py-2 px-5"
        @click="close"
      >取消</button>
    </div>
  </div>
</template>

<script>
/** vlayers */
import SelectorMap from '@/compositions/vlayer/SelectorMap'
import { onMounted, inject } from 'vue'

export default {
  setup(props, { emit }) {
    const MapStore = inject('MapStore')
    const WaterStationStore = inject('WaterStationStore')
    const TidalStore = inject('TidalStore')
    const RainStationStore = inject('RainStationStore')
    const CctvStore = inject('CctvStore')
    const InshoreStore = inject('InshoreStore')
    const BuoyStore = inject('BuoyStore')
    const TideStore = inject('TideStore')
    const DetailStore = inject('DetailStore')

    let vmap = null
    onMounted(() => {
      const MAP_ID = 'selector_map'
      vmap = SelectorMap.useVSelectorMap(MAP_ID, MapStore)
      SelectorMap.useWaterStationVLayer(vmap.map, DetailStore, WaterStationStore)
      SelectorMap.useTidalVLayer(vmap.map, DetailStore, TidalStore)
      SelectorMap.useRainStationVLayer(vmap.map, DetailStore, RainStationStore)
      SelectorMap.useCctvVLayer(vmap.map, DetailStore, CctvStore)
      SelectorMap.useInshoreVLayer(vmap.map, DetailStore, InshoreStore)
      SelectorMap.useBuoyVLayer(vmap.map, DetailStore, BuoyStore)
      SelectorMap.useTideVLayer(vmap.map, DetailStore, TideStore)
    })

    const close = () => {emit('update:visible', false)}

    return {
      close,
      save: () => {
        DetailStore.reset_total_station_data_feature([...DetailStore.state.temp_selected_features])
        DetailStore.save_collections()
        close()
        gtag('event', 'save collection markers')
      }
    }
  }
}

</script>

<style>



</style>
