import { watch, computed } from 'vue'

import { round_to_five } from '../../libs/utils'

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `station_stage,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `station_stage,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}


export const useWaterStationVLayer = (map, LayerStore, WaterStationStore, FavoritesStore) => {
  const layer_group = L.featureGroup()

  const icon = L.icon({
    iconUrl: require('@/assets/imgs/water_stage.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })
  const no_data_icon= L.icon({
    iconUrl: require('@/assets/imgs/water_stage_nodata.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })
  const icon_in_favorites = L.icon({
    shadowUrl: require('@/assets/imgs/love--click.png'),
    iconUrl: require('@/assets/imgs/water_stage.png'),
    shadowSize: [20, 20],
    iconSize: [36, 36],
    shadowAnchor: [0, 20],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })
  const no_data_icon_in_favorites = L.icon({
    shadowUrl: require('@/assets/imgs/love--click.png'),
    iconUrl: require('@/assets/imgs/water_stage.png'),
    shadowSize: [20, 20],
    iconSize: [36, 36],
    shadowAnchor: [0, 20],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })

  const marker_layer_map = new Map()

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-water_station/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-water_station text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      for (let stn of features) {
        let station_marker = L.marker(
          {lat: stn.lat, lon: stn.lon},
          {
            icon: stn.stage ? icon : no_data_icon,
            shadowPane: 'favoritesPane',
          }
        ).addTo(cluster_group)

        const unique_key = get_unique_key(stn)

        station_marker.on("click", (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          let target = {
            'type': 'station_stage',
            'id': stn.station_code,
            'name': stn.station_name,
            'lat': stn.lat,
            'lon': stn.lon,
            unique_key
          }
          LayerStore.set_selected_target(target)
          gtag('event', 'open_water_stage_chart')
        })

        station_marker['station_code'] = stn.station_code
        station_marker['station_name'] = stn.station_name
        marker_layer_map.set(unique_key, station_marker)
      }

      layer_group.addLayer(cluster_group)
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  const refresh_icons = () => {
    marker_layer_map.forEach((marker, key) => {
      const obj = favorites_mapping.value[key]
      if (obj) {
        marker.setIcon(icon_in_favorites)
      } else {
        marker.setIcon(icon)
      }
    })
  }

  const favorites_mapping = computed(() => FavoritesStore.state.favorites_mapping)

  watch(() => LayerStore.state.checked_layers.water_station, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => WaterStationStore.state.features, (_features) => {
    draw_marker(_features)
    refresh_icons()
  }, {
    immediate: true
  })

  watch(favorites_mapping, () => {
    refresh_icons()
  })


}
