/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const RainStationRepo = {
  station_info: {
    get: (params) => req("get", "v6/rain_station/", params)
  },
  obs: {
    get: (params) => req("get", "rain_station/obs/", params),
  }
}
