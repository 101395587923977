
class CBChartDataArtist {
  constructor(dataset, paint, options) {
    this.paint = paint
    this.options = Object.deepAssign({}, options)
    this.dataset = this.make_drawing_data(dataset)
  }

  draw(canvas, scale_x, scale_y) {
    if (this.dataset && this.paint) {
      let paints = [this.paint]
      if (Array.isArray(this.paint)) {
        paints = this.paint
      }
      else {
        paints = [this.paint]
      }
      for (let _paint of paints) {
        if (_paint.draw) {
          _paint.draw(this.dataset, canvas, scale_x, scale_y)
        }
      }
    }
    return this
  }

  make_drawing_data(dataset) {
    this.raw_dataset = dataset
    return dataset
  }

  xlist() {
    let xx = []
    for (let obj of this.dataset) {
      xx.push(obj.x)
    }
    return xx
  }

  ylist() {
    let yy = []
    for (let obj of this.dataset) {
      yy.push(obj.y)
    }
    return yy
  }

  xrange() {
    if (this.dataset?.length) {
      return [this.dataset[0].x, this.dataset[this.dataset.length-1].x]
    }
    else {
      console.warn("This dataset is not valid to get xrange from it.", this.dataset)
      return [0, 0]
    }
  }

  yrange() {
    // step1. 找到最大和最小值
    let min = null
    let max = null
    for (let data of this.dataset) {
      if (min === null || min > data.y) {
        min = data.y
      }
      if (max === null || max < data.y) {
        max = data.y
      }
    }

    // step2. 計算範圍
    // base_number: 以此數為基底，找到大於資料最大值且最接近此數的此數倍數，以及小於資料最小值且最接近此數的此數倍數
    let base_number = this.options.base_number || 10
    // buffer_required: 需不需要有極值的緩衝範圍（避免極值看不到）
    let min_buffer_required = this.options.min_buffer_required || this.options.buffer_required || false
    let max_buffer_required = this.options.max_buffer_required || this.options.buffer_required || false

    if (min_buffer_required) {
      min -= base_number
    }
    if (max_buffer_required) {
      max += base_number
    }

    if (min === max) {
      min = Math.floor(min/base_number) * base_number
      return [min, min + base_number]
    }
    else {
      return [Math.floor(min/base_number) * base_number, Math.ceil(max/base_number) * base_number ]
    }
  }

  yrange_from_zero() {
    // step1. 找到最大值
    let max = null
    for (let data of this.dataset) {
      if (max === null || max < data.y) {
        max = data.y
      }
    }

    // step2. 計算範圍
    // base_number: 以此數為基底，找到大於最大值且最接近此數的此數倍數，以及小於最小值且最接近此數的此數倍數
    let base_number = this.options.base_number || 10
    // buffer_required: 需不需要有極值的緩衝範圍（避免極值看不到）
    let max_buffer_required = this.options.max_buffer_required || this.options.buffer_required || false

    if (max_buffer_required) {
      max += base_number
    }

    if (max === 0) {
      return [0, base_number]
    }
    else {
      return [0, Math.ceil(max/base_number) * base_number ]
    }
  }

  destroy() {
    return null
  }
}

class CBIconDataArtist extends CBChartDataArtist {
  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        dataset.push({
          x: raw_data.x,
          y: raw_data.y,
          icon: this.get_icon(raw_data.y, raw_data.x)
        })
      }
    }

    return dataset
  }

  get_icon(y, x) {
    let icon = ''

    if (this.options?.icon_table) {
      let icon_table = this.options.icon_table

      if (icon_table[y]) {
        icon = icon_table[y]
      }
      else {
        console.debug(`Can't find icon of value ${y}`)
      }
    }
    else {
      console.error(`No icon table`)
    }

    return icon
  }
}


export {
  CBChartDataArtist,
  CBIconDataArtist
}
