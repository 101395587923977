<template>
  <div class="py-1 px-4 text-lg cursor-pointer" @click="go_back()">
    <i class="icon-symbol_back"></i>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'ReturnButton',
  setup() {
    const router = useRouter()
    const go_back = () => {
      router.go(-1)
    }
    return {
      go_back: go_back,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
