
class CBScale {
  constructor(data_range, canvas_range) {
    this.canvas_range = canvas_range
    this.data_range = data_range
  }

  position(data_value) {
    console.error("Not Implemented")
  }

  value_list(interval, number_of_intervals, tick_filter) {
    console.error("Not Implemented")
  }

  destroy() {
    this.data_range = null
    this.canvas_range = null
    return null
  }
}


class CBScaleFix extends CBScale {
  position() {
    return this.canvas_range instanceof Array? (this.canvas_range[0] + this.canvas_range[1]) / 2 : this.canvas_range
  }
}


class CBScaleLiner extends CBScale {
  position(data_value) {
    try {
      return ((data_value - this.data_range[0]) / (this.data_range[1] - this.data_range[0])) *
        (this.canvas_range[1] - this.canvas_range[0]) +
        this.canvas_range[0]
    }
    catch (e) {
      return null
    }
  }

  rate(data_value) {
    let v = ((data_value - this.data_range[0]) / (this.data_range[1] - this.data_range[0]))
    if (v > 1) { return 1 }
    if (v < 0) { return 0 }
    return v
  }

  canvas_size() {
    if (this.canvas_range && this.canvas_range.length == 2) {
      return this.canvas_range[1] - this.canvas_range[0]
    }
    return 0
  }

  value_list(interval, number_of_intervals, tick_filter) {
    /**
     * interval: ticks 之間的間隔
     * number_of_intervals: 需要有幾個 tick 間隔，會得到 number_of_intervals+1 個 tick
     * tick_filter: a filter to filter tick
     */

    if (!interval) {
      if (!number_of_intervals && !tick_filter) {
        number_of_intervals = 5
      }
      if (number_of_intervals) {
        try {
          interval = (this.data_range[1] - this.data_range[0]) / number_of_intervals
        }
        catch (error) {
          console.warn('Fail to use number_of_intervals', error)
        }
      }
      if (!interval) {
        interval = 1
      }
    }

    let dlist = []
    if (this.data_range[1] < this.data_range[0]) {
      if (interval > 0) { interval *= -1 }
      for (let v=this.data_range[0]; v>=this.data_range[1]; v+=interval) {
        dlist.push(v)
      }
    }
    else {
      if (interval < 0) { interval *= -1 }
      for (let v=this.data_range[0]; v<=this.data_range[1]; v+=interval) {
        dlist.push(v)
      }
    }

    if (tick_filter) {
      dlist = dlist.filter(tick_filter)
    }
    return dlist
  }

  paint_tick_list(paint_options) {
    return this.value_list(paint_options.tick_interval, paint_options.number_of_intervals, paint_options.tick_filter)
  }
}


class CBScaleTime extends CBScaleLiner {
  constructor(data_range, canvas_range) {
    super(data_range, canvas_range)
    this.data_range = [data_range[0].valueOf(), data_range[1].valueOf()]
  }

  position(data_value) {
    try {
      data_value = data_value.valueOf()
      return ((data_value - this.data_range[0]) / (this.data_range[1] - this.data_range[0])) *
          (this.canvas_range[1] - this.canvas_range[0]) +
          this.canvas_range[0]
    }
    catch (e) {
      return null
    }
  }

  rate(data_value) {
    data_value = data_value.valueOf()
    let v = ((data_value - this.data_range[0]) / (this.data_range[1] - this.data_range[0]))
    if (v > 1) { return 1 }
    if (v < 0) { return 0 }
    return new Date(v)
  }

  value_list(interval, number_of_intervals, tick_filter) {
    /**
     * interval: ticks 之間的間隔，unit: minutes, default 1 hour
     * number_of_intervals: 需要有幾個 tick 間隔，會得到 number_of_intervals+1 個 tick
     * tick_filter: a filter to filter tick
     */

    if (!interval) {
      if (!number_of_intervals && !tick_filter) {
        number_of_intervals = 5
      }
      if (number_of_intervals) {
        try {
          interval = (this.data_range[1] - this.data_range[0]) / number_of_intervals
        }
        catch (error) {
          console.warn('Fail to use number_of_intervals', error)
        }
      }
      if (!interval) {
        interval = 60 * 60 * 1000
      }
    }
    else {
      interval = interval * 60 * 1000
    }


    let dlist = []
    if (this.data_range[1] < this.data_range[0]) {
      if (interval > 0) { interval *= -1 }
      for (let v=this.data_range[0]; v>=this.data_range[1]; v+=interval) {
        dlist.push(new Date(v))
      }
    }
    else {
      if (interval < 0) { interval *= -1 }
      for (let v=this.data_range[0]; v<=this.data_range[1]; v+=interval) {
        dlist.push(new Date(v))
      }
    }

    if (tick_filter) {
      dlist = dlist.filter(tick_filter)
    }
    return dlist
  }

  paint_tick_list(paint_options) {
    return this.value_list(paint_options.tick_interval, paint_options.number_of_intervals, paint_options.tick_filter)
  }
}


export {
  CBScaleFix, CBScaleLiner, CBScaleTime
}
