import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { CctvRepo } from '@/repositories/cctv'

export const useCctvStore = (LayerStore) => {
  const state = reactive({
    selected_cctv: null,
    features: null,
    is_getting_cctv_urls: false, // 此指到後端取得圖片網址，並非下載圖片。
    interval_id: null,
    url_method: null,
    image_urls: null,
  })

  const set_selected_cctv = (cctv_id=null) => {
    state.selected_cctv = cctv_id
    if (cctv_id) {
      fetch_cctv_images(cctv_id)
    }
  }

  const set_url_method = (method) => {
    state.url_method = method
  }

  const change_image_urls_at_intervals = (_urls) => {
    const interval_id = setInterval(() => {
      let urls = []
      for (let _url of _urls) {
        if (_url && _url.length) {
          let url = new URL(_url[0])
          url.searchParams.append('t', Math.random())
          urls.push([url])
        }
      }
      set_image_urls(urls)
      // 暫時將time interval時間設成5s，因為不同資料源能維持的串流時間不同
      // 例如：高速公路局約8s、公路總局約30s
    }, 5000)
    set_interval_id(interval_id)
  }

  const set_image_urls = (urls) => {
    state.image_urls = urls
  }

  const set_interval_id = (interval_id=null) => {
    state.interval_id = interval_id
  }

  const fetch_cctv_images = async (cctv_id) => {
    state.is_getting_cctv_urls = true
    set_image_urls([])
    try {
      let payload = await CctvRepo.cctv_imgs.get(cctv_id)
      set_image_urls(payload.data)
      if (state.url_method === 'STREAMING_IMG') {
        // 當採用串流圖片方法時，需固定時間改變圖片url
        change_image_urls_at_intervals(payload.data)
      }
      if (state.url_method === 'IFRAME' && state.image_urls.length) {
        // 若Cctv提供方法為IFRAME，會使用另開分頁方式顯示
        window.open(state.image_urls[0])
      }
    }
    finally {
      state.is_getting_cctv_urls = false
    }
  }

  const get_features = async () => {
    set_loading(true)
    try {
      let payload = await CctvRepo.cctv.get()
      state.features = markRaw(payload.data)
    }
    finally {
      set_loading(false)
    }
  }

  const set_loading = (val) => LayerStore.set_loading(val, 'cctv')

  watch(() => LayerStore.state.checked_layers.cctv, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })

  watch(() => LayerStore.state.selected_target, (selected_target) => {
    if (selected_target.type === "cctv") {
      set_selected_cctv(selected_target.id)
      let selected_feature = state.features.find(feature => feature.id === selected_target.id)
      set_url_method(selected_feature.method)
    }
  })

  const store = {
    state: readonly(state),
    set_selected_cctv,
    fetch_cctv_images,
    get_features
  }

  provide('CctvStore', store)

  return store
}
