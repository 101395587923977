/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const ConsoleRepo = {
  announcement: {
    get: () => req("get", 'console/announcement/')
  }
}
