<template>
  <div
    class="safe_padding_top h-full pb-[60px] md:pb-[90px] overflow-y-auto scrollbar-hidden"
  >
    <div
      class="relative w-full p-1 flex justify-center items-center flex-wrap gap-2 py-2 px-10 md:py-1"
    >
      <div
        class="vertical_center left-2 cursor-pointer"
        @click="$router.push({ name: 'Home' })"
      >
        <i class="icon-symbol_left text-2xl"></i>
      </div>
      <div class="rounded-lg p-1 flex justify-center gap-2">
        <Datepicker
          inline
          autoApply
          v-model="selected_date"
          :maxDate="new Date().addDays(-1)"
          :minDate="new Date().addDays(-365)"
          :yearRange="[new Date().getFullYear() - 1, new Date().getFullYear()]"
          :enableTimePicker="false"
          format="yyyy/MM/dd"
        ></Datepicker>
      </div>
    </div>
    <div class="relative h-[260px] shadow-chart">
      <div v-show="is_getting_weather_data" class="blink_loading"></div>
      <div
        id="history_weather_chart"
        v-if="weather_raw_data?.length"
        class="h-full"
      ></div>
      <div v-else class="h-full flex items-center justify-center">
        暫無資料
      </div>
    </div>
  </div>
</template>

<script>
import { watch, inject, onBeforeUnmount, ref, markRaw } from 'vue'
import { cb_alert } from '@/libs/cb-theme'
import { useWeatherChart } from '@/compositions/chart/weather_chart'
import { useRoute } from 'vue-router'

export default {
  name: 'WeatherChart',

  setup(props) {
    const WeatherDataStore = inject('WeatherDataStore')

    const weather_raw_data = ref(null)
    const selected_date = ref(new Date().addDays(-1))
    const is_getting_weather_data = ref(false)

    const route = useRoute()
    const lat = route.params.lat
    const lon = route.params.lon

    const get_weather_data_list = async () => {
      is_getting_weather_data.value = true
      let dt_from = selected_date.value.zero_hour()
      let dt_to = dt_from.addHours(23)

      try {
        let obs_data = await WeatherDataStore.fetch_obs_data(
          lat,
          lon,
          dt_from,
          dt_to
        )
        let dt = dt_from
        // 把時間補齊
        while (dt.valueOf() <= dt_to.valueOf()) {
          if (!obs_data.find((_) => _.dtime.valueOf() === dt.valueOf())) {
            obs_data.push({
              dtime: dt,
              lat: lat,
              lon: lon,
              precp: -999,
              pres: -999,
              rh: -999,
              tx: -999,
              wd: 45,
              ws: -999,
              wx: '不明',
            })
          }
          dt = dt.addHours(1)
        }
        obs_data.sort((a, b) => {
          return a.dtime.valueOf() - b.dtime.valueOf()
        })
        weather_raw_data.value = markRaw(obs_data)
      } catch (e) {
        console.log(e)
        cb_alert('天氣資料載入失敗')
      }

      is_getting_weather_data.value = false
    }
    watch(
      selected_date,
      () => {
        gtag('event', 'view_history_weather')
        get_weather_data_list()
      },
      {
        immediate: true,
      }
    )

    const weather_chart = useWeatherChart('history_weather_chart')

    watch(weather_raw_data, (_weather_raw_data) => {
      weather_chart.refresh_chart(_weather_raw_data)
    })

    return {
      selected_date,
      weather_raw_data,
      is_getting_weather_data,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
