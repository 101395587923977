import { nextTick } from 'vue'

const TICK_INTERVAL = 180

export const useInshoreChart = (chart_id) => {
  let chart = null
  let raw_data

  const draw_chart = () => {
    let data_artists = get_data_artists()
    let chart_options = get_chart_options()

    chart = new CBTimeChart(chart_id, data_artists, chart_options)
  }

  const get_data_artists = () => {
    let datasets = {
      weather_icon: [],
      wswd: [],
      flow_velocity: [],
      wave_height: [],
      wave_height_min_max: [],
    }

    for (let data of raw_data) {
      let datetime = new Date(data.time)

      datasets.wave_height.push({
        x: datetime,
        y: data.wave_height,
      })

      datasets.weather_icon.push({
        x: datetime,
        y: data.wx,
      })
      datasets.wswd.push({
        x: datetime,
        y: { ws: data.ws, wd: data.wd },
      })
      datasets.flow_velocity.push({
        x: datetime,
        y: data.flow_velocity ? data.flow_velocity : '-',
      })
    }

    let wave_height_min = datasets.wave_height.reduce((a, b) =>
      a.y < b.y ? a : b
    )
    let wave_height_max = datasets.wave_height.reduce((a, b) =>
      a.y > b.y ? a : b
    )
    datasets.wave_height_min_max.push(wave_height_min)
    datasets.wave_height_min_max.push(wave_height_max)

    return {
      weather_icon: new FWMWeatherIconDataArtist(
        datasets.weather_icon,
        new CBIconChartPaint()
      ),
      wave_height: new BufferDataArtist(
        datasets.wave_height,
        new CBAreaChartPaint({
          opacity: 0.6,
          fade_required: 0.6,
          color_table: WaveColorTable
        }),
        {
          buffer_required: true,
          max_base_number: 2,
          min_base_number: 1,
        }
      ),
      wave_height_min_max: new CBChartDataArtist(
        datasets.wave_height,
        new CBTextChartPaint({
          class_name: 'font-bold text-[rgb(82,159,186)]',
          format: v => v + 'm'
        })
      ),
      ws: new WSWDDataArtist(datasets.wswd, [
        new CBIconChartPaint(),
        new CBWSCirclePaint({
          circle_size: 30,
        }),
      ]),
      flow_velocity: new CBChartDataArtist(
        datasets.flow_velocity,
        new CBTextChartPaint({
          class_name: 'font-bold text-[rgb(150,150,150)]'
        })
      ),
    }
  }

  const get_chart_options = () => {
    const chart_data_width = 50

    let clip_width = document.getElementById(chart_id).offsetWidth
    // lwsu@2024-05-28: 變 33 筆資料了
    let canvas_width = 33 * chart_data_width
    if (canvas_width < clip_width) {
      canvas_width = clip_width
    }

    return {
      canvas_size: {
        width: canvas_width
      },
      canvas_margin: chart_data_width/2,
      tooltip_required: true,
      axis_x: {
        font_size: 16,
        position: 'top',
        paint: new InshoreAxisXPaint({
          tick_interval: TICK_INTERVAL,
        }),
      },
      axes_y: {
        weather_icon: {
          canvas_range: 90,
        },
        wave_height: {
          canvas_range: [305, 195],
          format: (h) => '浪高' + h + 'm',
        },
        wave_height_min_max: {
          canvas_range: 205,
          format: (h) => h + 'm',
        },
        ws: {
          canvas_range: 135,
          format: (v) => {
            return (
              Math.round(v * 3.6) +
              'km/h (' +
              ws_ms_to_beaufort(v) +
              '級)'
            )
          },
        },
        flow_velocity: {
          canvas_range: 175,
          format: (v) => {
            if (v) {
              return '流速 ' + v + 'km/h'
            }
            return '-'
          },
        },
      },
    }
  }

  const refresh_chart = async (_raw_data) => {
    if (chart) { chart = chart.destroy() }

    await nextTick()

    if (!_raw_data) return

    raw_data = _raw_data

    nextTick(() => {
      if (!document.getElementById(chart_id)) {
        console.warn("The chart container does not exist.");
        return
      }

      draw_chart()

      // Set chart offset so that 6:00 is at the beginning if
      // the screen isn't enough to display
      let cb_chart_clip = document.getElementsByClassName("cb_chart-clip")[0]
      let canvas_container = document.getElementsByClassName("cb_chart-canvas_container")[0]
      if (cb_chart_clip.offsetWidth < canvas_container.offsetWidth * 4 / 3) {
        cb_chart_clip.scrollLeft = canvas_container.offsetWidth / 4
      }
    })
  }

  return {
    refresh_chart
  }
}


import { CBTimeChart } from '@/libs/cb-chart/cb_chart.js'
import { CBChartDataArtist } from '@/libs/cb-chart/cb_data_artist.js'
import { CBAreaChartPaint, CBTextChartPaint, CBIconChartPaint } from '@/libs/cb-chart/cb_chart_paint.js'
import { CBWSCirclePaint } from '@/libs/cb-chart/cb_weather_chart.js'
import { WaveColorTable } from '@/settings/weather'

import { ws_ms_to_beaufort, BufferDataArtist, FWMWeatherIconDataArtist, WSWDDataArtist, FWMAxisXPaint } from '@/libs/fwm_chart'


const parse_date = (date) => {
  let diff_days = date.diffDays(new Date().zero_hour())
  if (diff_days === 0) {
    return '(今)'
  }
  else if (diff_days === 1) {
    return '(明)'
  }
  else {
    return ''
  }
}

export class InshoreAxisXPaint extends FWMAxisXPaint {
  // 覆寫
  // 需多畫日期刻度
  draw_single_tick(scale, tick_value) {
    // date div
    if (tick_value.getHours() === 0) {
      let date_div = document.createElement('div')
      // 位置減一小時、translateX(0) 讓它對其資料點左側
      this.place_tick_value(date_div, css_unit_join(scale.position(tick_value.addHours(-1))))
      date_div.style.transform = 'translateX(0%)'

      date_div.style.top = css_unit_join(15, 'px')
      date_div.style.fontWeight = 'bold'
      date_div.style.fontSize = css_unit_join(14, 'px')
      let date_format = (v) =>  '週' + v.strftime('%-a') + v.getDate() + parse_date(tick_value)
      date_div.innerHTML = date_format(tick_value)
      this.container.appendChild(date_div)
    }
    // time div
    let time_div = document.createElement('div')
    this.place_tick_value(time_div, css_unit_join(scale.position(tick_value)))
    time_div.style.top = css_unit_join(50, 'px')

    let time_format = (v) => v.strftime("%H:00")
    time_div.innerHTML = time_format(tick_value)
    this.container.appendChild(time_div)
  }
}
