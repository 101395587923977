import { reactive, provide, readonly, markRaw } from 'vue'
import { round_to_five } from '../../libs/utils'

export const usePinStore = () => {
  const state = reactive({
    record_pins: [],
    temp_point: null
  })

  const set_temp_point = (point) => {
    state.temp_point = point
  }

  const save_to_local_storage = (record_pins) => {
    if (record_pins) {
      localStorage.setItem('record_position', JSON.stringify(record_pins))
      state.record_pins = markRaw(record_pins)
    }
    else {
      localStorage.setItem('record_position', JSON.stringify(state.record_pins))
    }
  }


  const load_record = () => {
    let record_positions = JSON.parse(localStorage.getItem('record_position'))
    if (record_positions) {
      // 過濾掉已過期的資料
      record_positions = record_positions.filter(position => new Date(position.effective_time) >= new Date())
      // 過濾掉之後要存回去
      save_to_local_storage(record_positions)
    }
    else {
      record_positions = []
    }
    state.record_pins = markRaw(record_positions)
  }

  load_record()

  const create_record_pin = (latlon) => {
    const record_positions = JSON.parse(localStorage.getItem('record_position')) || []
    record_positions.push({
      'effective_time': new Date().addDays(2).isostrz(),
      'lat': latlon.lat,
      'lon': latlon.lon
    })
    save_to_local_storage(record_positions)
  }

  const remove_record_pin = (latlon) => {
    let record_positions = JSON.parse(localStorage.getItem('record_position')) || []
    record_positions = record_positions.filter(e => round_to_five(e.lat) !== round_to_five(latlon.lat) && round_to_five(e.lon) !== round_to_five(latlon.lon))
    save_to_local_storage(record_positions)
  }

  const refresh_pin_effective_time = (pin) => {
    let record_positions = JSON.parse(localStorage.getItem('record_position'))
    record_positions.forEach(exists_pin => {
      if (round_to_five(exists_pin.lat) === round_to_five(pin.lat) &&
          round_to_five(exists_pin.lon) === round_to_five(pin.lon)) {
        exists_pin.effective_time = new Date().addDays(2).isostrz()
      }
    })
    save_to_local_storage(record_positions)
  }

  const store = {
    state: readonly(state),
    create_record_pin,
    remove_record_pin,
    refresh_pin_effective_time,
    set_temp_point
  }

  provide('PinStore', store)

  return store
}
