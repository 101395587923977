import { watch, ref } from 'vue'

const DEFAULT_OPTIONS = {
  minZoom: 8,
  maxZoom: 20
}


export const useVSelectorMap = (map_id, MapStore, options) => {
  const _options = Object.assign(DEFAULT_OPTIONS, options)
  const map = L.map(map_id, {
    minZoom: _options.minZoom,
    maxZoom: _options.maxZoom,
    zoomControl: false
  }).setView(MapStore.state.center, MapStore.state.zoom_level)

  map.createPane('favoritesPane')
  map.getPane('favoritesPane').style.zIndex = 601

  const baselayers = {
    street: L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: '',
      maxNativeZoom: 22,
      maxZoom: _options.maxZoom
    }),
    satellite: L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution: '',
      maxNativeZoom: 21,
      maxZoom: _options.maxZoom
    }),
  }

  const update_baselayer = (layer_name) => {
    if (!(layer_name in baselayers)) {
      return
    }
    for (let key in baselayers) {
      if (key === layer_name) {
        map.addLayer(baselayers[key])
      } else {
        map.removeLayer(baselayers[key])
      }
    }
  }

  watch(() => MapStore.state.selected_baselayer, baselayer => {
    update_baselayer(baselayer)
  }, {
    immediate: true
  })

  map.attributionControl.setPrefix('')

  return { map }
}
