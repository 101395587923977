import { reactive, readonly, provide } from 'vue'
import { WeatherDataRepo } from '@/repositories/weather_data'

export const useWeatherDataStore = () => {
  const state = reactive({
    typhoon_status: undefined
  })

  const fetch_obs_data = async (lat, lon, dt_from, dt_to) => {
    let payload = await WeatherDataRepo.obs_data.get({
      lat: lat,
      lon: lon,
      dt_from: dt_from,
      dt_to: dt_to
    })
    let data_list = payload.data
    for (let data of data_list) {
      data['dtime'] = new Date(data['dtime'])
    }
    return data_list
  }

  const fetch_fcst_data = async (lat, lon) => {
    let payload = await WeatherDataRepo.fcst_data.get({
      lat: lat,
      lon: lon
    })
    let data_list = payload.data
    for (let data of data_list) {
      data['dtime'] = new Date(data['dtime'])
    }
    return data_list
  }

  const fetch_town_72hr_fcst_data = async (lat, lon) => {
    let payload = await WeatherDataRepo.town_72hr_fcst_data.get({
      lat: lat,
      lon: lon
    })
    let data_list = payload.data
    for (let data of data_list) {
      data['init_time'] = new Date(data['init_time'])
      data['dtime'] = new Date(data['dtime'])
    }
    return data_list
  }

  const fetch_typhoon_status = async () => {
    let payload = await WeatherDataRepo.typhoon_status.get({})
    state.typhoon_status = payload?.data
  }

  fetch_typhoon_status()

  let store = {
    state: readonly(state),
    fetch_fcst_data,
    fetch_obs_data,
    fetch_town_72hr_fcst_data,
    fetch_typhoon_status,
  }

  provide('WeatherDataStore', store)

  return store
}
