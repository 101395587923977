<template>
  <div class="h-full bg-gray-100">
    <Layout main_page>
      <template v-slot:title>
        通知
      </template>
      <template v-slot:main>
        <div
          v-for="(msg, idx) in messages"
          :key="'msg' + idx"
          class="msg_shadow rounded-lg m-4 p-4 bg-white"
          :class="{
            announcement: msg.notification_type === 'border-2 border-accent',
          }"
        >
          <div class="flex justify-between items-center">
            <h3 class="heading--h3">{{ msg.title }}</h3>
            <div
              v-show="msg.notification_type === 'announcement'"
              class="text-sm font-bold text-accent"
            >
              重要訊息
            </div>
          </div>
          <hr class="my-4" />
          <p class="indent-lg" v-if="msg.notification_type === 'alert'">
            {{ msg.description }}
          </p>
          <div
            class="w-full overflow-hidden"
            v-else
            v-html="msg.description"
          ></div>
          <br />
          <p class="text-right" v-if="msg.notification_type === 'alert'">
            生效時間：{{ msg.effective.strftime('%Y.%m.%d %H:%M') }}
          </p>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import Layout from '@/components/menu/Layout'

/** compositions */
import { useNotification } from '@/compositions/Notification'

export default {
  components: {
    Layout,
  },

  setup() {
    const AlertStore = inject('AlertStore')
    const AnnouncementStore = inject('AnnouncementStore')

    const { get_notifications } = useNotification(AlertStore, AnnouncementStore)

    const messages = computed(() =>
      get_notifications().sort((a, b) => {
        // the key of msg time: alert -> 'effective'; announcement -> 'updated_at'
        let time_a =
          a.notification_type === 'alert' ? a.effective : a.updated_at
        let time_b =
          b.notification_type === 'alert' ? b.effective : b.updated_at
        if (time_a > time_b) {
          return -1
        } else {
          return 1
        }
      })
    )

    return {
      messages: messages,
    }
  },
}
</script>

<style lang="scss" scoped>

.msg_shadow {
  box-shadow: 0 1px 3px rgba(black, 0.2);
}

</style>

<style>

/* 提供給 markdown 語法生成的 list elem，避免其點點不見 */
.html_content ul {
  list-style: inherit;
  padding-left: 20px;
}

</style>
