<template>
  <div class="relative w-full h-full">
    <!-- 提供螢幕側邊滑動事件 -->
    <div
      ref="swipe_ref"
      v-show="!favorites_visible"
      class=" fixed right-0 w-6 h-full z-swipe"
    ></div>

    <!-- 滑動的菜園清單 -->
    <div
      ref="favorites_ref"
      class=" fixed w-full h-full z-favorites left-full"
      :class="{
        'animate-move_left': favorites_visible,
        'animate-move_right': favorites_visible === false,
      }"
    >
      <Favorites
        v-show="favorites_visible != null"
        v-model:visible="favorites_visible"
      >
      </Favorites>
    </div>

    <!-- 地圖 -->
    <!-- z-map 是自訂變數，代表 z-index: 1000 -->
    <!-- 因在 ios 裝置，地圖 z-index 有可能跑掉才多加設定 -->
    <div ref="map_ref" id="map" class=" z-map h-full"></div>

    <!-- 左上 -->
    <div
      class="safe_padding_top absolute inset-0 h-full w-full bg-white z-1500"
      v-show="search_modal"
    ></div>
    <!-- 這裏新增favorites_visible來隱藏search地址功能， -->
    <!-- 因為在我的最愛修改名稱時多個input會造成鍵盤顯示下一步 -->
    <div
      v-show="!favorites_visible"
      class="safe_margin_top absolute top-0 z-above_map pt-4 pl-4 flex flex-col"
      :class="[search_modal ? 'z-1501 w-full pr-4 h-full' : '']"
    >
      <!-- 地址搜尋 -->
      <div
        class="relative md:max-w-none"
        :class="[search_modal ? 'w-full' : 'max-w-3/5']"
      >
        <input
          id="search_input"
          type="text"
          class="input--base peer w-full bg-white/70 pl-9 focus:bg-opacity-100"
          v-model="search_text"
          @focus="on_search_focus"
          @keyup="search_auto_complete_timeout"
          placeholder="在這裏搜尋(至少 2 個字)"
        />
        <i
          @click="close_search_modal"
          class="cursor-pointer absolute left-2 vertical_center leading-4 peer-focus:text-accent"
          :class="[search_modal ? 'icon-symbol_left' : 'icon-search']"
        ></i>
        <i
          v-if="search_text"
          @click="search_text = ''"
          class="cursor-pointer icon-symbol_close absolute right-2 vertical_center leading-4 peer-focus:text-accent"
        ></i>
      </div>
      <div
        v-if="show_search_auto_complete_processing"
        class="relative flex"
      >
        <img
          class="h-10"
          src="../assets/imgs/spinner.gif"
        />
        <div
          class="flex vertical_center left-12 text-blue-500"
        >
          關鍵字檢索中 ......
        </div>
      </div>
      <div
        class="relative flex-1"
        :class="[search_modal ? 'w-full' : 'md:max-w-none max-w-3/5']"
        v-show="search_modal"
      >
        <div
          class="overflow-y-auto absolute peer w-full bg-white h-full pb-[60px] md:pb-[90px]"
        >
          <div
            class="py-2 cursor-pointer w-full"
            v-for="(info, idx) in suggestion_list"
            :key="'search_info' + idx"
            @click="click_search_auto_complete(info)"
          >
            <div class="relative font-bold flex items-center">
              <i
                v-if="info.icon_type === 'cctv'"
                class="icon-CCTV pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'tidal'"
                class="icon-tidal pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'station_stage'"
                class="icon-water_stage pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'rain_station'"
                class="icon-precipitation pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'tide'"
                class="icon-tide_station pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'buoy'"
                class="icon-buoy pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'pin'"
                class="icon-map_pin pr-1 text-3xl"
              ></i>
              <i
                v-if="info.icon_type === 'history'"
                class="icon-time pr-1 text-3xl"
              ></i>
              <div>
                {{ info.main_text }}
                <br />
                <span class="text-sm">{{ info.secondary_text }}</span>
              </div>
              <i
                v-if="info.icon_type === 'history'"
                @click.stop="on_remove_history(info)"
                class="icon-symbol_close absolute right-2 leading-4"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右上按鈕 -->
    <div
      class="safe_margin_top absolute top-0 right-0 z-above_map pt-4 pr-4 flex justify-between gap-4"
    >
      <!-- 颱風提示 -->
      <a
        v-show="ty_visible"
        class="btn_accent--line btn_icon text-white"
        :class="ty_btn_color"
        href="https://typhoon.tw/"
        target="_blank"
      >
        <i class="icon-TY"> </i>
      </a>
      <!-- 通知 -->
      <button
        class="btn_accent--line btn_icon"
        :style="[ notifications.length ? 'display: inline;' : 'display: none;']"
        @click="go_notification"
      >
        <i class="icon-notice"></i>
      </button>
      <!-- 菜園清單 -->
      <button
        class="btn_accent--line btn_icon text-red-500"
        @click="favorites_visible = !favorites_visible"
      >
        <i class="icon-love"> </i>
      </button>
      <!-- Menu -->
      <button
        class="btn_primary--line btn_icon"
        @click="$router.push({ name: 'Menu' })"
      >
        <i class="icon-menu"></i>
      </button>
    </div>

    <!-- 左下按鈕 -->
    <div
      class="absolute bottom-0 left-0 z-above_map pb-8 pl-4"
      v-show="!panel_visible"
    >
      <!-- 切換圖層按鈕 -->
      <div
        v-show="control_container_show"
        class=" bg-gray-100/90 rounded-3xl p-2 mb-1 grid grid-cols-2 items-center gap-2"
      >
        <template v-for="(group, _idx) in layer_groups" :key="'group' + _idx">
          <LayerToggleButton
            v-for="(layer_info, idx) in group"
            :key="'control_layer_' + _idx + idx"
            :layer_info="layer_info"
          />
          <hr class="col-span-full" />
        </template>
        <!-- 合法垂釣漁港 切換按鈕 -->
        <LayerToggleButton :layer_info="fishing_harbor_layer_info" />

        <!-- 街道／衛星 切換按鈕 -->
        <button
          v-if="selected_baselayer == 'street'"
          class="btn_normal btn_icon text-primary"
          @click="set_baselayer('satellite')"
        >
          <i class="icon-street baselayer_icon"></i>
        </button>
        <button
          v-if="selected_baselayer == 'satellite'"
          class="btn_normal btn_icon text-primary"
          @click="set_baselayer('street')"
        >
          <i class="icon-satellite baselayer_icon"></i>
        </button>
      </div>
      <button
        class="btn_primary btn_icon px-3"
        @click="control_container_show = !control_container_show"
      >
        <i class="icon-layer"></i>
      </button>
    </div>

    <!-- 右下按鈕 -->
    <div
      class="absolute bottom-0 right-0 z-above_map pb-8 pr-4 flex flex-col gap-2"
      v-show="!panel_visible"
    >
      <!-- GPS定位按鈕 -->
      <button class="btn_normal btn_icon px-3" @click="locate">
        <i class="icon-position_arrow text-blue-500"></i>
      </button>
    </div>

    <!-- 下方 panel -->
    <div
      ref="panel_ref"
      v-show="panel_visible"
      class="absolute bottom-0 w-full z-above_map bg-white shadow-panel"
    >
      <!-- 關閉按鈕 -->
      <div
        class=" absolute horizontal_center bottom-full z-above_map w-60 font-bold bg-white rounded-t-full cursor-pointer p-1 shadow-panel flex justify-center"
        @click="panel_visible = !panel_visible"
      >
        <i class="icon-symbol_close"></i>
      </div>
      <div
        v-if="panel_visible"
        class="absolute -top-8 left-[calc(50%_+_130px)]"
      >
        <FavoritesBtnVue v-if="favorites_btn_visible"></FavoritesBtnVue>
        <!-- <weather-chart v-if="selected_target?.type == 'pin'"> </weather-chart> -->
      </div>
      <tidal-chart v-if="selected_target?.type == 'tidal'"> </tidal-chart>
      <station-stage-chart v-if="selected_target?.type == 'station_stage'">
      </station-stage-chart>
      <inshore-chart v-if="selected_target?.type == 'inshore'"> </inshore-chart>
      <rain-chart v-if="selected_target?.type == 'rain_station'"> </rain-chart>
      <tide-info v-if="selected_target?.type == 'tide'"> </tide-info>
      <buoy-info v-if="selected_target?.type == 'buoy'"> </buoy-info>
    </div>

    <CctvOverlay v-if="selected_cctv && url_method !=='IFRAME'" :panel_visible="panel_visible">
    </CctvOverlay>
  </div>
</template>

<script>
/** vue */
import {
  computed,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'

import { LAYER_GROUPS } from '@/settings/layer'

/** vlayers */
import { useVMap } from '@/compositions/vlayer/VMap'
import { useWaterStationVLayer } from '@/compositions/vlayer/WaterStationVLayer'
import { useTidalVLayer } from '@/compositions/vlayer/TidalVLayer'
import { useTideVLayer } from '@/compositions/vlayer/TideVLayer'
import { useBuoyVLayer } from '@/compositions/vlayer/BuoyVLayer'
import {
  useRainfallVLayer,
  useThunderstormVLayer,
} from '@/compositions/vlayer/AlertVLayer'
import { usePinVLayer } from '@/compositions/vlayer/PinVLayer'
import { useInshoreVLayer } from '@/compositions/vlayer/InshoreVLayer'
import { useCctvVLayer } from '@/compositions/vlayer/CctvVLayer'
import { useRadarVLayer } from '@/compositions/vlayer/RadarVLayer'
import { useWindyVLayer } from '@/compositions/vlayer/WindyVLayer'
import { useRainStationVLayer } from '@/compositions/vlayer/RainStationVLayer'
import { useFishingHarborVLayer } from '@/compositions/vlayer/FishingHarborVLayer'

/** compositions */
import { useNotification } from '@/compositions/Notification'

/** Vue components */
// 待討論：模板中用小寫連字命名、這邊用相對路徑，單純是因爲在模板那樣寫可以被 Vetur 自動引入滿方便的...
import LayerToggleButton from '../components/LayerToggleButton.vue'
// import WeatherChart from '../components/WeatherChart.vue'
import StationStageChart from '../components/StationStageChart.vue'
import InshoreChart from '../components/InshoreChart.vue'
import CctvOverlay from '../components/CctvOverlay.vue'
import TidalChart from '../components/TidalChart.vue'
import Favorites from '../components/Favorites.vue'
import RainChart from '../components/RainChart.vue'
import FavoritesBtnVue from '../components/FavoritesBtn.vue'
import TideInfo from '../components/TideInfo.vue'
import BuoyInfo from '../components/BuoyInfo.vue'

export default {
  components: {
    LayerToggleButton,
    // WeatherChart,
    StationStageChart,
    InshoreChart,
    CctvOverlay,
    TidalChart,
    Favorites,
    RainChart,
    FavoritesBtnVue,
    TideInfo,
    BuoyInfo,
  },

  setup() {
    /** 這個檔案裡會用到的 Store，用 inject 取得 */
    const MapStore = inject('MapStore')
    const LayerStore = inject('LayerStore')
    const PinStore = inject('PinStore')
    const AlertStore = inject('AlertStore')
    const AnnouncementStore = inject('AnnouncementStore')
    const WaterStationStore = inject('WaterStationStore')
    const TidalStore = inject('TidalStore')
    const InshoreStore = inject('InshoreStore')
    const CctvStore = inject('CctvStore')
    const RadarStore = inject('RadarStore')
    const WindyStore = inject('WindyStore')
    const RainStationStore = inject('RainStationStore')
    const FavoritesStore = inject('FavoritesStore')
    const WeatherDataStore = inject('WeatherDataStore')
    const SearchingStore = inject('SearchingStore')
    const TideStore = inject('TideStore')
    const BuoyStore = inject('BuoyStore')
    const FishingHarborStore = inject('FishingHarborStore')

    // map, vlayers 相關
    var vmap = null
    onMounted(() => {
      const MAP_ID = 'map'
      vmap = useVMap(MAP_ID, MapStore, SearchingStore)
      usePinVLayer(
        vmap.map,
        LayerStore,
        PinStore,
        MapStore,
        FavoritesStore,
        SearchingStore
      )
      useCctvVLayer(vmap.map, LayerStore, CctvStore, FavoritesStore)
      useInshoreVLayer(vmap.map, LayerStore, InshoreStore)
      useRadarVLayer(vmap.map, LayerStore, RadarStore)
      useTidalVLayer(vmap.map, LayerStore, TidalStore, FavoritesStore)
      useRainfallVLayer(vmap.map, LayerStore, AlertStore)
      useThunderstormVLayer(vmap.map, LayerStore, AlertStore)
      useWindyVLayer(vmap.map, LayerStore, WindyStore)
      useRainStationVLayer(
        vmap.map,
        LayerStore,
        RainStationStore,
        FavoritesStore
      )
      useWaterStationVLayer(
        vmap.map,
        LayerStore,
        WaterStationStore,
        FavoritesStore
      )
      useTideVLayer(vmap.map, LayerStore, TideStore, FavoritesStore)
      useBuoyVLayer(vmap.map, LayerStore, BuoyStore, FavoritesStore)
      useFishingHarborVLayer(vmap.map, LayerStore, FishingHarborStore)
    })
    // cctv overlay
    const selected_cctv = computed(() => CctvStore.state.selected_cctv)
    const url_method = computed(() => CctvStore.state.url_method)

    // 新增取消菜園按鈕相關 (panel旁的)
    const favorites_btn_visible = computed(() => {
      return (
        selected_target?.value &&
        selected_target.value.type !== 'inshore' &&
        panel_visible.value
      )
    })

    // panel 相關
    const panel_visible = ref(false)
    const map_ref = ref(null),
      panel_ref = ref(null)
    const selected_target = computed(() => LayerStore.state.selected_target)
    const resize_map = () => {
      const map_height =
        document.body.clientHeight - panel_ref.value.clientHeight
      map_ref.value.style.height = `${map_height}px`
      vmap.map?.invalidateSize()
    }
    watch(selected_target, async (_selected_target) => {
      if (_selected_target?.type !== 'cctv') {
        panel_visible.value = true
      } else {
        panel_visible.value = false
      }
    })
    watch(
      [panel_visible, selected_target],
      async ([_panel_visible, _selected_target]) => {
        await nextTick()
        resize_map()
        if (vmap.map) {
          let zoom_level = vmap.map.getZoom()
          if (zoom_level < 14) {
            zoom_level = 14
          }
          if (_panel_visible) {
            vmap.map.setView(
              [_selected_target.lat, _selected_target.lon],
              zoom_level
            )
          }
        }
      }
    )

    // baselayer 相關
    const set_baselayer = (val) => MapStore.set_baselayer(val)
    const selected_baselayer = computed(() => MapStore.state.selected_baselayer)

    // search 相關
    const search_modal = ref(false)
    const search_text = ref('')
    const show_search_auto_complete_processing = ref(false)
    const exec_search_auto_complete = () => {
      return search_text.value.length >= 2
    }

    const suggestion_list = ref([])
    let timer = null
    const search_auto_complete_timeout = (e) => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }

      if (exec_search_auto_complete()) {
        show_search_auto_complete_processing.value = true
        timer = setTimeout(async (e) => {
          suggestion_list.value = await SearchingStore.get_suggestion_list(
            search_text.value
          )
          show_search_auto_complete_processing.value = false
        }, 2000)
      }
    }
    const click_search_auto_complete = async (info) => {
      const latlon = await SearchingStore.choose_suggestion(info)
      if (info?.place_id) {
        SearchingStore.insert_history(info)
        PinStore.set_temp_point(latlon)
      }
      close_search_modal()
      MapStore.set_fly_to_latlon(latlon)
    }

    const on_search_focus = async () => {
      gtag('event', 'on_search_focus')
      search_modal.value = true
      if (exec_search_auto_complete()) {
        show_search_auto_complete_processing.value = true
        suggestion_list.value = await SearchingStore.get_suggestion_list(
          search_text.value
        )
        show_search_auto_complete_processing.value = false
      }
    }

    const on_remove_history = async (info) => {
      gtag('event', 'remove_search_history')
      SearchingStore.remove_history(info)
      suggestion_list.value = await SearchingStore.get_suggestion_list(
        search_text.value
      )
    }

    const close_search_modal = () => {
      gtag('event', 'close_search_modal')
      search_modal.value = false
    }

    // 通知
    const router = useRouter()
    const go_notification = () => {
      gtag('event', 'click_notification')
      router.push({ name: 'Notification' })
    }
    const { get_notifications } = useNotification(AlertStore, AnnouncementStore)

    // GPS定位
    let locator_enabled = computed(() => MapStore.state.locator_enabled)
    const locate = () => {
      vmap?.locate()
    }

    /* 螢幕側邊滑動叫出菜園清單 */
    const swipe_ref = ref(null)
    const favorites_ref = ref(null)
    const favorites_visible = ref(null)
    onMounted(() => {
      // swipe_ref 的向左滑動事件
      swipe_ref.value.addEventListener('touchstart', (e) => {
        let start_x = e.touches[0].clientX
        let start_y = e.touches[0].clientY
        swipe_ref.value.addEventListener(
          'touchend',
          (e) => {
            let end_x = e.changedTouches[0].clientX
            let end_y = e.changedTouches[0].clientY
            if (
              !favorites_visible.value &&
              start_x != null &&
              end_x < start_x &&
              Math.abs((end_y - start_y) / (end_x - start_x)) < 0.7 &&
              start_x - end_x > window.innerWidth / 9
            ) {
              favorites_visible.value = true
            }
            // Disable CctvOverlay after swipe animation ends.
            setTimeout(() => CctvStore.set_selected_cctv(null), 450)
          },
          { once: true }
        )
      })

      // 菜園清單 (favorites_ref) 的向右滑動事件
      favorites_ref.value.addEventListener('touchstart', (e) => {
        let start_x = e.touches[0].clientX
        let start_y = e.touches[0].clientY
        favorites_ref.value.addEventListener(
          'touchend',
          (e) => {
            let end_x = e.changedTouches[0].clientX
            let end_y = e.changedTouches[0].clientY
            if (
              favorites_visible.value &&
              start_x != null &&
              end_x > start_x &&
              Math.abs((end_y - start_y) / (end_x - start_x)) < 0.7 &&
              end_x - start_x > window.innerWidth / 5
            ) {
              favorites_visible.value = false
            }
          },
          { once: true }
        )
      })
    })

    /* 颱風相關 */
    const ty_visible = computed(() => {
      if (
        WeatherDataStore.state.typhoon_status?.has_alert ||
        WeatherDataStore.state.typhoon_status?.will_invade ||
        WeatherDataStore.state.typhoon_status?.has_ty
      ) {
        return true
      }
    })
    const ty_btn_color = computed(() => {
      if (WeatherDataStore.state.typhoon_status?.has_alert) {
        return 'bg-red-500'
      }
      if (WeatherDataStore.state.typhoon_status?.will_invade) {
        return 'bg-yellow-300'
      }
      if (WeatherDataStore.state.typhoon_status?.has_ty) {
        return 'bg-green-500'
      }
    })

    const get_layer_group_order_menu = () => {
      // 左下角選layer的按鈕排序
      const order_layer_type = [
        ['cctv', 'water_station', 'rain_station'],
        ['inshore', 'tidal', 'tide', 'buoy'],
        ['rainfall', 'thunderstorm', 'radar', 'windy'],
      ]
      let menu = []
      for (let group of order_layer_type) {
        let _menu = []
        for (let layer_type of group) {
          _menu.push(LAYER_GROUPS.find((_) => _.layer_type === layer_type))
        }
        menu.push(_menu)
      }
      return menu
    }

    const get_fishing_harbor_layer_info = () => {
      return LAYER_GROUPS.find((_) => _.layer_type === 'fishing_harbor')
    }

    const control_container_show = ref(false)
    watch(
      () => LayerStore.state.checked_layers,
      () => {
        control_container_show.value = false
      },
      {
        deep: true,
      }
    )

    onBeforeUnmount(() => {
      // 切換 router 時清空 selected_target
      LayerStore.set_selected_target({})
    })

    /** 模板有使用到的需 return 出去 */
    return {
      control_container_show,
      layer_groups: get_layer_group_order_menu(),
      fishing_harbor_layer_info: get_fishing_harbor_layer_info(),
      map_ref,
      panel_ref,
      swipe_ref,
      favorites_ref,

      favorites_btn_visible,
      favorites_visible,
      panel_visible,
      selected_target,

      ty_visible,
      ty_btn_color,

      selected_baselayer,
      set_baselayer,

      search_modal,
      search_text,
      show_search_auto_complete_processing,
      search_auto_complete_timeout,
      suggestion_list,
      click_search_auto_complete,
      on_search_focus,
      on_remove_history,
      close_search_modal,

      selected_cctv,
      url_method,
      go_notification,
      notifications: computed(get_notifications),
      locator_enabled,
      locate,
    }
  },
}
</script>

<style lang="scss">

.leaflet-control-scale-line {
  margin-left: 20px;
}
.leaflet-control-attribution {
  margin-right: 22px !important;
  margin-bottom: 5px !important;
}

</style>

<style>
.leaflet-favorites-pane img {
  max-width: none !important;
  max-height: none !important;
}
.location {
  width: 20px;
  height: 20px;
  position: relative;
}
@keyframes twinkling {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.location_point {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(0, 153, 255);
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  animation: twinkling 1000ms infinite;
}
.location_point-rim {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 153, 255, 0.3);
}
.marker_bubble {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: absolute;
  bottom: 100%;
  --tw-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
  --tw-shadow-colored: 4px 4px 8px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.marker_bubble::before {
  content: '';
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  height: 0.5rem;
  position: absolute;
  top: 100%;
  width: 0.75rem;
}
</style>
