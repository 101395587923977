import { reactive, readonly, provide, watch, computed, ref } from 'vue'

import { GeoRepo } from '@/repositories/map'

export const useSearchingStore = (MapStore, LayerStore, PinStore, WaterStationStore, TidalStore, CctvStore, RainStationStore, InshoreStore, TideStore, BuoyStore) => {
  const state = reactive({
    history: []
  })

  const insert_history = (info) => {
    // 會進來這個 function 有以下兩個條件：
    // 1. 點下建議列表的其中一個點
    // 2. 輸入地點之後按下搜尋
    // 搜尋過的文字才需要放到 history 搜尋記錄內
    // 最多 30 個
    if (!state.history.some(item => info?.place_id && item.place_id === info.place_id)) {
      state.history.unshift(info)
      if (state.history.length > 30) {
        state.history.pop()
      }
      gtag('event', 'click_searched_suggestion')
    }
    else {
      gtag('event', 'click_searched_history')
    }
  }

  const remove_history = (info) => {
    state.history = state.history.filter(item => item.place_id !== info.place_id)
  }

  let prev_search_text = null
  let auto_complete_list = []
  const get_suggestion_list = async (text) => {
    // 搜尋過的紀錄
    let history = state.history.map(item => {
      return {
        ...item,
        main_text: (item?.main_text || item.name) || '',
        secondary_text: item?.secondary_text || '',
        icon_type: 'history',
      }
    })

    if (text && text.length >=2) {
      // google auto_complete 來源

      // 跟前一次的搜尋文字一樣就不用再跟後端再要一次了
      if (prev_search_text !== text) {
        let data = await get_auto_complete_list(text)
        auto_complete_list = data.map(item => {
          return {
            ...item,
            name: item.main_text,
            icon_type: 'pin',
          }
        })
        prev_search_text = text
      }

      // 圖資包含搜尋歷史也要被搜尋
      return history
        .filter(item => item.main_text.includes(text) || item.name.includes(text))
        .concat(auto_complete_list.concat(
          static_list.filter(item => {
            let key = item.type === 'station_stage' ? 'water_station' : item.type
            // 沒打開的圖層就不用搜了
            return LayerStore.state.checked_layers[key] && item.name.includes(text)
          }).map(item => {
            return {
              ...item,
              main_text: item.name,
              secondary_text: '',
              icon_type: item.type,
            }
          })
        ))
    }
    return history
  }

  const choose_suggestion = async (info) => {
    if (info?.place_id) {
      return await get_place(info.place_id)
    }
    return {
      lat: info.lat,
      lon: info.lon
    }
  }

  let static_list = []
  // 要這些 store 先有資料才能被搜尋到，也就是要先點開這些圖資圖層才行
  watch([
    () => WaterStationStore.state.features,
    () => TidalStore.state.features,
    () => CctvStore.state.features,
    () => RainStationStore.state.features,
    () => InshoreStore.state.features,
    () => TideStore.state.features,
    () => BuoyStore.state.features,
  ], ([water_station_list, tidal_list, cctv_list, rain_satation_list, inshore_list, tide_list, buoy_list]) => {
    const arr = [
      [water_station_list, 'station_stage'],
      [tidal_list, 'tidal'], // 潮汐站
      [cctv_list, 'cctv'],
      [rain_satation_list, 'rain_station'],
      [inshore_list, 'inshore'],
      [tide_list, 'tide'], // 潮位站
      [buoy_list, 'buoy'],
    ].reduce((prev, curr) => {
      if (curr[0]?.length >= 0) {
        return prev.concat(curr[0].map(item => {
          return {
            type: curr[1],
            // water_station_list 中的 name 是 station_name @??
            name: item.name || item.station_name || '',
            ...item
          }
        }))
      }
      return prev
    }, [])
    static_list = arr
  }, {
    immediate: true
  })
  const get_auto_complete_list = async (text) => {
    const res = await GeoRepo.auto_complete.get(text)
    return await res.data
  }

  const get_place = async (place_id) => {
    const res = await GeoRepo.place.get(place_id)
    return await res.data
  }

  const store = {
    state: readonly(state),
    get_suggestion_list,
    choose_suggestion,
    insert_history,
    remove_history
  }

  const history = JSON.parse(localStorage.getItem('searching_history_list')) || []
  if (history) {
    state.history = history
  }
  watch(() => state.history, (history) => {
    localStorage.setItem('searching_history_list', JSON.stringify(history))
  }, {
    deep: true
  })

  provide('SearchingStore', store)

  return store
}
