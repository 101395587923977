/**
 * Make img zoomable and movable
 * @returns
 */
export const useImgControl = () => {
  const img_params = {
    scale: 1,
    translateX: 0, /* unit: px */
    translateY: 0, /* unit: px */
  } /* Used for img scaling */

  const set_transform = (el, trans_prop) => {
    let scale = trans_prop.scale
    // As the translate also affects by scale, so preprocess translate to keep the outcome right
    let translateX = trans_prop.translateX / trans_prop.scale
    let translateY = trans_prop.translateY / trans_prop.scale
    el.style.transform = 'scale(' + scale + ') ' + 'translate(' + translateX + 'px, ' + translateY + 'px)'
  }

  const img_touchstart = (event) => {
    var touches = event.touches;
    var events = touches[0];
    var events2 = touches[1];

    event.preventDefault();

    // 第一个触摸点的坐标
    // 縮放基礎點參考
    img_params.pageX = events.pageX;
    img_params.pageY = events.pageY;
    // 移動基礎點參考
    img_params.lastMoveX = events.pageX;
    img_params.lastMoveY = events.pageY;

    img_params.moveable = true;

    if (events2) {
      img_params.pageX2 = events2.pageX;
      img_params.pageY2 = events2.pageY;
    }

    img_params.originScale = img_params.scale || 1;
  }

  const img_touchmove = (event) => {
    if (!img_params.moveable) {
      return;
    }

    event.preventDefault();

    var touches = event.touches;
    var events = touches[0];
    var events2 = touches[1];
    // 双指移动
    if (events2) {
      // 第2个指头坐标在touchmove时候获取
      if (!img_params.pageX2) {
          img_params.pageX2 = events2.pageX;
      }
      if (!img_params.pageY2) {
          img_params.pageY2 = events2.pageY;
      }

      // 获取坐标之间的举例
      var getDistance = function (start, stop) {
          return Math.hypot(stop.x - start.x, stop.y - start.y);
      };
      // 双指缩放比例计算
      var zoom = getDistance({
          x: events.pageX,
          y: events.pageY
      }, {
          x: events2.pageX,
          y: events2.pageY
      }) /
      getDistance({
          x: img_params.pageX,
          y: img_params.pageY
      }, {
          x: img_params.pageX2,
          y: img_params.pageY2
      });
      // 应用在元素上的缩放比例
      var newScale = img_params.originScale * zoom;
      // 最大缩放比例限制
      if (newScale > 3) {
          newScale = 3;
      }
      // 记住使用的缩放值
      img_params.scale = newScale;
      // 图像应用缩放效
      set_transform(event.target, img_params)
    }
    // 單指移動
    else {
      var dx = events.pageX - img_params.lastMoveX
      var dy = events.pageY - img_params.lastMoveY
      img_params.translateX += dx
      img_params.translateY += dy
      set_transform(event.target, img_params)
      img_params.lastMoveX = events.pageX
      img_params.lastMoveY = events.pageY
    }
  }

  const img_touchend = (event) => {
    img_params.moveable = false;

    delete img_params.pageX2;
    delete img_params.pageY2;
    delete img_params.lastMoveX;
    delete img_params.lastMoveY;
  }

  return {
    img_touchstart,
    img_touchmove,
    img_touchend
  }
}
