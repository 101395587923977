import { watch, computed } from 'vue'

require('@/libs/leaflet-radar')
export const useRadarVLayer = (map, LayerStore, RadarStore) => {
  const layer = L.radarCanvasOverlay()

  const assign_single_data = (radar_data) => {
    try {
      // 丟進 leaflet 的資料若有可響應的包裝，會讓畫面很卡
      layer.setData(radar_data)
    }
    catch (error) {
      /**
       * Irene@2021-08-05
       * 這邊在圖層加到地圖之前，會抓到以下錯誤：
       * TypeError: Cannot read property 'latLngToLayerPoint' of undefined
       * 不確定為何 tpw 沒有出錯，不過目前無視掉也不會有問題
       */
    }
  }

  async function play_radar_animation(recent_radar_datas) {
    let i = 0
    while (recent_radar_datas) {
      // Stop update radar layer if it's untoggled.
      if (checked.value) {
        layer.setData(recent_radar_datas[i])
        if (i === recent_radar_datas.length - 1) {
          i = 0
          // Wait 3 sec after the last radar data is shown
          await new Promise(resolve => setTimeout(resolve, 3000))
        }
        else {
          i++
        }
      }
      await new Promise(resolve => setTimeout(resolve, 200));
    }
    // If animation end due to no recent radar datas, set layer to latest single data.
    assign_single_data()
  }

  const toggle_layer = (checked) => {
    if (checked) {
      gtag('event', 'turn_on_radar_layer')
      map.addLayer(layer)
    }
    else {
      map.removeLayer(layer)
    }
  }

  const checked = computed(() => LayerStore.state.checked_layers.radar)

  watch(checked, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => RadarStore.state.radar_data, (_radar_data) => {
    assign_single_data(_radar_data)
  }, {
    immediate: true
  })

  watch(() => RadarStore.state.recent_radar_datas, (_recent_radar_datas) => {
    play_radar_animation(_recent_radar_datas)
  })
}
