<template>
  <div>
    <div class="flex h-[38px] pt-2">
      <div
        class="absolute text-sm bg-white shadow-chart mx-2 p-2 rounded-lg cursor-pointer"
      >
        <router-link
          :to="{
            name: 'HistoryStationStage',
            params: { id: selected_target.id },
          }"
        >
          <i class="icon-symbol_left date"></i>
        </router-link>
      </div>
      <div class="w-full my-2 text-center">
        <!-- 顯示水位測站名稱與即時資料 -->
        {{ selected_target.name }}&nbsp;{{ last_obs_stage }}
      </div>
    </div>
    <div class=" relative">
      <div v-show="is_chart_loading" class="blink_loading"></div>
      <div
        id="station_stage_container"
        class=" relative w-full h-[300px] overflow-x-auto"
      >
        <canvas
          id="station_stage_chart"
          class=" my-4 mx-2"
          width="1400"
          height="260"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import { Tooltip } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { ref, watch, inject, nextTick, computed, onBeforeUnmount } from 'vue'

Chart.register(annotationPlugin)

// Consider stage data below -900m as invalid value
const INVALID_VALUE = -900

export default {
  name: 'StationStageChart',

  setup(prop) {

    const LayerStore =  inject('LayerStore')
    const WaterStationStore =  inject('WaterStationStore')

    let chart = null
    const is_chart_loading = ref(false)

    const selected_target = computed(() => LayerStore.state.selected_target)
    const station_stage_data = computed(() => WaterStationStore.state.data)

    const last_obs_stage = computed(() => {
        let obs_stage_data = WaterStationStore.state.data
        if (
          obs_stage_data &&
          obs_stage_data.obs &&
          obs_stage_data.obs.length > 0
        ) {
          const {
            is_display,
            last_obs_dtime,
            last_obs_stage,
          } = check_obs_stage_valid_within_3hrs(obs_stage_data.obs)
          if (is_display) {
            // 超過 3 小時以前就不顯示了
            return `-- ${last_obs_stage}m (${last_obs_dtime.strftime('%H:%M')})`
          }
          return '-- 資料中斷'
        }
        return '--'
      },
    )

    watch(
      station_stage_data, async (station_stage) => {
      if (station_stage) {
        await nextTick()
          draw_chart(station_stage)

          // 手機版的水位資料預設到今天的位置
          // 因每站的水位資料長度不同，所以要找到今天的資料是在obs資料裡的第幾個
          // ratio = 第幾個/總長度，然後設定scrollLeft到這個位置
          let station_stage_container = document.getElementById(
            'station_stage_container'
          )
          let station_stage_chart = document.getElementById(
            'station_stage_chart'
          )

          let obs = station_stage.obs
          if (obs) {
            let today_index = obs.findIndex(function(item) {
              return item.dtime.getDate() === new Date().getDate()
            })
            let ratio = today_index / obs.length
            station_stage_container.scrollLeft =
              station_stage_chart.offsetWidth * ratio
          }
        }
      },{
        deep: true,
      }
    )

    watch(
      () => LayerStore.state.selected_target,
      async (_selected_target) => {
        if (_selected_target?.type === "station_stage") {
          let dt_to = new Date()
          let dt_from = dt_to.addDays(-7)
          is_chart_loading.value = true
          await WaterStationStore.get_station_stage(_selected_target.id, dt_from, dt_to)
          is_chart_loading.value = false
        }
      },{
        deep: true,
        immediate: true
      }
    )

      // 確認3小時內的水位資料是否包含有效值，並找出最新有效觀測時間對應的水位。
    const check_obs_stage_valid_within_3hrs = (datas) => {
      for (let i = datas.length - 1; i >= 0; i--) {
        if (datas[i].dtime > new Date().addHours(-3)) {
          if (datas[i].stage > INVALID_VALUE) {
            return {
              is_display: true,
              last_obs_dtime: datas[i].dtime,
              last_obs_stage: datas[i].stage,
            }
          }
        } else {
          return {
            is_display: false,
            last_obs_dtime: null,
            last_obs_stage: null,
          }
        }
      }
    }

    const draw_chart = (datas) => {
      if (document.getElementById('station_stage_chart') === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 station_stage_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 station_stage_chart element 不存在的狀況，防止 console 跳錯。
        return
      }
      // 畫圖前須先清空 chart
      if (chart !== null) {
        chart.destroy()
      }
      // 將chart清空之後 看datas裡面是否有obs 若沒有就不畫chart 避免 chart js 出錯
      if (!datas.obs.length) {
        return
      }

      let ctx = document.getElementById('station_stage_chart').getContext('2d')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth

      let mode = {
        type: 'line',
        borderColor: 'black',
        borderWidth: 1.5,
        scaleID: 'y',
        value: get_chart_stage_mode(),
        borderDash: [6, 6],
      }

      // Get warning lvl values from features
      const warning_levls = WaterStationStore.state.features.find(e => e.station_code == LayerStore.state.selected_target.id)
      let warning_lv1 = {
        type: 'line',
        borderColor: '#EA0000',
        borderWidth: 2,
        scaleID: 'y',
        value: warning_levls.warning_lv1,
      }

      let warning_lv2 = {
        type: 'line',
        borderColor: '#FF8000',
        borderWidth: 2,
        scaleID: 'y',
        value: warning_levls.warning_lv2,
      }

      let warning_lv3 = {
        type: 'line',
        borderColor: '#F9F900',
        borderWidth: 2,
        scaleID: 'y',
        value: warning_levls.warning_lv3,
      }

      let obs_data = _get_chart_obs_data()

      let config = {
        type: 'line',
        data: _get_chart_data(),

        options: {
          layout: {
            padding: {
              left: 10,
            },
          },
          responsive: false,
          interaction: {
            intersect: false,
          },
          plugins: {
            tooltip: {
              caretSize: 0,
              position: 'custom',
              callbacks: {
                title: () => {
                  return ''
                },
                label: function(tooltipItem) {
                  let time = new Date(tooltipItem.label).strftime('%H:%M')
                  let stage = tooltipItem.formattedValue
                  return `${time} ${stage}m`
                },
              },
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                mode,
                warning_lv1,
                warning_lv2,
                warning_lv3,
              },
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  day: 'MM/DD ddd',
                },

                unit: 'day',
              },
            },
            y: {
              suggestedMin: _get_yaxis_min(obs_data, warning_levls),
              suggestedMax: _get_yaxis_max(obs_data),
              grid: {
                color: '#ccc',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              position: 'right',
              stacked: true,
            },
          },
        },
      }

      define_tooltips_position()
      chart = new Chart(ctx, config)
    }

    const define_tooltips_position = () => {
      Tooltip.positioners.custom = function(elements, position) {
        if (!elements.length) {
          return false
        }
        return {
          x: position.x,
          y: 0,
        }
      }
    }

    const _get_chart_data = () => {
      let datasets = []
      let options = {
        obs: {
          backgroundColor: '#4285F4',
          borderColor: '#4285F4',
        },
        fcst: {
          backgroundColor: '#00e600',
          borderColor: '#00e600',
        },
      }
      if (station_stage_data.value.obs && station_stage_data.value.obs.length) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: options.obs.backgroundColor,
          borderColor: options.obs.borderColor,
          data: _get_chart_obs_data(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      return {
        datasets: datasets,
      }
    }

    const _get_chart_obs_data = () => {
      let data = []
      if (station_stage_data.value.obs.length) {
        station_stage_data.value.obs.forEach((obs) => {
          if (obs.stage > INVALID_VALUE) {
            data.push({
              x: obs.dtime,
              y: obs.stage,
            })
          }
        })
      }
      return data
    }

    const get_chart_stage_mode = () => {
      let mode = null
      if (station_stage_data.value.stage_mode) {
        mode = station_stage_data.value.stage_mode
      }
      return mode
    }

    const _get_yaxis_min = (datas ,warning_levls) => {
      let stage_mode = station_stage_data.value.stage_mode
      let warning_lv1 = warning_levls.warning_lv1
      let warning_lv2 = warning_levls.warning_lv2
      let warning_lv3 = warning_levls.warning_lv3
      let total_stage_list = [
        warning_lv1,
        warning_lv2,
        warning_lv3,
        stage_mode,
      ]

      // 將觀測與計算水位的最小值進行比較，取得y軸的最小值
      let min_value = null
      if (datas.length) {
        total_stage_list.push(datas.reduce((a, b) => (a.y < b.y ? a : b)).y)
      }

      // 年平均水位需求恆顯示， 所以再跟最小值做比較 如果小於最小值就取代
      if (total_stage_list.length) {
        let _total_stage_list = total_stage_list.filter((x) => x)
        min_value = Math.min(..._total_stage_list)
      }

      if (min_value !== null) {
        let float_value = min_value % 1
        if (min_value > 0) {
          return min_value - float_value
        } else {
          let mod = 1 + float_value
          return min_value - mod
        }
      }
      return 0
    }

    const _get_yaxis_max = (datas) => {
      let stage_mode = station_stage_data.stage_mode
      // 將觀測與計算水位的最大值進行比較，取得y軸的最大值
      let max_value = null
      if (datas.length) {
        max_value = datas.reduce((a, b) => (a.y > b.y ? a : b)).y
      }
      // 年平均水位需求恆顯示，所以再跟最大值比較 如果超過最大值就取代
      if (max_value < stage_mode) {
        max_value = stage_mode
      }

      if (max_value !== null) {
        let float_value = max_value % 1
        let mod = 1 - float_value
        return max_value + mod
      }
      return 0
    }

    // const _get_xaxis_min_label = () => {
    //   if (station_stage_data.obs && station_stage_data.obs.length) {
    //     // 展示歷線圖抓第一筆觀測資料時間
    //     let first_obs_dt = station_stage_data.obs[0].dtime
    //     return first_obs_dt
    //   } else if (
    //     station_stage_data.fcst &&
    //     station_stage_data.fcst.length
    //   ) {
    //     // 如果沒有觀測，就抓第一筆預報當 min_label
    //     let first_fcst_dt = station_stage_data.fcst[0].dtime
    //     return first_fcst_dt
    //   } else {
    //     // 沒資料的話, 抓現在時間回推 24 小時，避免後面程序出錯
    //     return new Date().addHours(-24)
    //   }
    // }

    // const _get_xaxis_max_label = () => {
    //   let last_fcst_dt, last_obs_dt
    //   if (station_stage_data.fcst && station_stage_data.fcst.length) {
    //     // 展示歷線圖只要抓最後一筆預報時間
    //     last_fcst_dt = station_stage_data.fcst[
    //       station_stage_data.fcst.length - 1
    //     ].dtime
    //   }
    //   if (station_stage_data.obs && station_stage_data.obs.length) {
    //     // 如果沒有預報，就抓最後一筆觀測當 max_label
    //     last_obs_dt = station_stage_data.obs[
    //       station_stage_data.obs.length - 1
    //     ].dtime
    //   }
    //   if (this.isExist(last_obs_dt) && this.isExist(last_fcst_dt)) {
    //     return last_fcst_dt > last_obs_dt ? last_fcst_dt : last_obs_dt
    //   } else if (this.isExist(last_fcst_dt)) {
    //     return last_fcst_dt
    //   } else if (this.isExist(last_obs_dt)) {
    //     return last_obs_dt
    //   } else {
    //     // 沒資料的話，抓現在時間，避免後面程序出錯
    //     return new Date()
    //   }
    // }

    // const isExist = (obj) => {
    //   return (
    //     obj !== null &&
    //     typeof obj !== 'null' &&
    //     typeof obj !== 'undefined' &&
    //     obj !== ''
    //   )
    // }

    // const shift_fcst_data = (fcst_data) => {
    //   // 將所有水位預報資料加上和最後一筆觀測資料的差
    //   let last_obs_data = station_stage_data.obs[
    //     station_stage_data.obs.length - 1
    //   ]
    //   let first_fcst_data = fcst_data[0]
    //   let stage_diff = first_fcst_data.y - last_obs_data.stage
    //   let shift_fcst_data = []
    //   fcst_data.forEach((data) => {
    //     // 計算水位取小數後兩位數
    //     shift_fcst_data.push({
    //       x: data.x,
    //       y: Math.round((data.y - stage_diff) * 100) / 100,
    //     })
    //   })
    //   return shift_fcst_data
    // }

    onBeforeUnmount(() => {
      // chart 須跟著元件被清空
      if (chart !== null) {
        chart.destroy()
      }
    })

    return {
      is_chart_loading,
      selected_target,
      last_obs_stage
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
