<template>
  <div ref="favorites_btn_ref" class=" z-2001 cursor-pointer">
    <div class="p-0" @click="add_or_delete_favorites">
      <img
        v-show="favorites_active"
        class="w-[34px] h-[34px]"
        src="@/assets/imgs/love--click.png"
      />
      <img
        v-show="!favorites_active"
        class="w-[34px] h-[34px]"
        src="@/assets/imgs/love--normal.png"
      />
    </div>
  </div>
</template>
<script>
import { computed, inject, ref } from 'vue'

export default {
  props: ['latlon'],
  setup(props) {
    const FavoritesStore = inject('FavoritesStore')

    const [lat, lon] = props.latlon
    const selected_target ={
      'type': 'pin',
      'lat': lat,
      'lon': lon,
      'name': `自選點 ${lat}, ${lon}`,
      'unique_key': `pin,${lat},${lon}`
    }

    const favorites_btn_ref = ref(null)
    const favorites_active = computed(() => {
      let active = false
      FavoritesStore.state.favorites.forEach((element) => {
        if (element.unique_key === selected_target.unique_key) {
          active = true
        }
      })
      return active
    })
    const add_or_delete_favorites = () => {
      return FavoritesStore.add_or_delete_favorites(selected_target)
    }

    return {
      favorites_active,
      favorites_btn_ref,
      add_or_delete_favorites,
    }
  },
}
</script>
