import { GeoRepo } from '@/repositories/map'

import { reactive, readonly, provide } from 'vue'

export const useMapStore = () => {
  /** state 放在 function 內部，靠 provide/inject 去用，較統一 */
  // 可視為以前的 data + computed
  const state = reactive({
    center: [24, 121],
    zoom_level: 9,
    locator_enabled: null,
    selected_baselayer: 'street',
    fly_to_latlon: {}
  })

  const load_record = () => {
    let map_center = JSON.parse(localStorage.getItem('map_center'))
    if (map_center) set_center(map_center)

    let zoom_level = JSON.parse(localStorage.getItem('zoom_level'))
    if (zoom_level != null) set_zoom_level(zoom_level)

    let baselayer = JSON.parse(localStorage.getItem('baselayer'))
    if (baselayer) set_baselayer(baselayer)
  }

  const set_fly_to_latlon = (pos) => {
    state.fly_to_latlon = pos
  }

  const set_center = (val) => {
    state.center = val
    localStorage.setItem('map_center', JSON.stringify(val))
  }

  const set_zoom_level = (val) => {
    state.zoom_level = val
    localStorage.setItem('zoom_level', JSON.stringify(val))
  }

  const set_baselayer = (val) => {
    gtag('event', 'switch_baselayer')
    state.selected_baselayer = val
    localStorage.setItem('baselayer', JSON.stringify(val))
  }

  /** 作為 created 的時機，也可以放 watch */
  load_record()

  /** 最後要 return 出去的 store，會被外部呼叫的才需要被放在裡面。
   * 為了 provide，先存成變數 */
  const store = {
    state: readonly(state),
    set_center,
    set_zoom_level,
    set_baselayer,
    set_fly_to_latlon
  }

  /** store 自己執行 provide，呼叫時就不用再做一次 */
  provide('MapStore', store)

  return store
}
