import { watch, computed } from 'vue'

import { round_to_five } from '../../libs/utils'

const ICON_IMG_URL = require('@/assets/imgs/tide_station.png')

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `tide,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `tide,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}

export const useTideVLayer = (map, LayerStore, TideStore, FavoritesStore) => {
  const layer_group = L.featureGroup()

  const icon = L.icon({
    iconUrl: ICON_IMG_URL,
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })

  const icon_in_favorites = L.icon({
    iconUrl: ICON_IMG_URL,
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
    shadowUrl: require('@/assets/imgs/love--click.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 20],
  })

  const marker_layer_map = new Map()

  const draw_marker = (features) => {
    layer_group.clearLayers()
    if (features) {
      for (let stn of features) {
        let station_marker = L.marker(
          {lat: stn.lat, lon: stn.lon},
          {icon: icon, shadowPane: 'favoritesPane'}
        ).addTo(layer_group)

        const unique_key = get_unique_key(stn)

        station_marker.on("click", (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          let target = {
            'type': 'tide',
            'id': stn.id,
            'name': stn.name,
            'lat': stn.lat,
            'lon': stn.lon,
            'unique_key': unique_key
          }
          LayerStore.set_selected_target(target)
        })

        marker_layer_map.set(unique_key, station_marker)
      }
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  const refresh_icons = () => {
    marker_layer_map.forEach((marker, key) => {
      const obj = favorites_mapping.value[key]
      if (obj) {
        marker.setIcon(icon_in_favorites)
      } else {
        marker.setIcon(icon)
      }
    })
  }

  const favorites_mapping = computed(() => FavoritesStore.state.favorites_mapping)

  watch(() => LayerStore.state.checked_layers.tide, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => TideStore.state.features, (_features) => {
    draw_marker(_features)
    refresh_icons()
  }, {
    immediate: true
  })

  watch(favorites_mapping, () => {
    refresh_icons()
  })

}
