/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const TidalRepo = {
  tidal_stations: {
    get: (params) => req("get", "v6/marine/tidal/stations/", params)
  },
  tidal_station_data: {
    get: (params) => req("get", "v5/marine/tidal/station_data/", params)
  }
}
