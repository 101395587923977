const get_img_url = (path) => {
  return require(`@/libs/cb-chart/imgs/${path}`)
}

const ExceptValues = [-999, -998]


const WeatherColorTable = {
  // 適用 tx, soil_temp, leaf_temp
  tx: {
    '-10': '247, 252, 254',
    '-9': '234, 247, 253',
    '-8': '221, 242, 252',
    '-7': '207, 237, 251',
    '-6': '193, 232, 250',
    '-5': '181, 227, 248',
    '-4': '168, 222, 248',
    '-3': '152, 214, 245',
    '-2': '141, 204, 237',
    '-1': '128, 293, 232',
    '0': '118, 183, 226',
    '1': '105, 173, 220',
    '2': '95, 163, 214',
    '3': '82, 152, 208',
    '4': '71, 143, 198',
    '5': '71, 148, 181',
    '6': '73, 154, 163',
    '7': '71, 159, 145',
    '8': '73, 164, 126',
    '9': '74, 169, 109',
    '10': '73, 175, 91',
    '11': '73, 181, 70',
    '12': '97, 188, 73',
    '13': '121, 195, 76',
    '14': '146, 202, 79',
    '15': '169, 209, 82',
    '16': '193, 215, 85',
    '17': '216, 222, 87',
    '18': '240, 229, 92',
    '19': '250, 222, 88',
    '20': '249, 205, 81',
    '21': '248, 188, 74',
    '22': '248, 171, 68',
    '23': '246, 155, 60',
    '24': '246, 137, 53',
    '25': '246, 120, 46',
    '26': '243, 102, 41',
    '27': '239, 92, 41',
    '28': '233, 82, 42',
    '29': '228, 72, 41',
    '30': '225, 60, 40',
    '31': '220, 50, 39',
    '32': '216, 41, 41',
    '33': '209, 31, 39',
    '34': '200, 29, 38',
    '35': '192, 27, 36',
    '36': '182, 27, 34',
    '37': '174, 25, 31',
    '38': '165, 24, 28',
    '39': '157, 23, 27',
    '40': '146, 21, 25',
  },

  ws: {
    '0': '255, 255, 255',
    '1': '255, 250, 250',
    '2': '255, 245, 185',
    '3': '255, 224, 178',
    '4': '255, 180, 153',
    '5': '255, 110, 65',
    '6': '255, 0, 0',
    '7': '204, 0, 0',
    '8': '188, 0, 0',
    '9': '153, 0, 0',
    '10': '102, 0, 0',
    '11': '102, 0, 153',
    '12': '102, 0, 153',
    '13': '102, 0, 153',
    '14': '102, 0, 153',
    '15': '102, 0, 153',
    '16': '102, 0, 153',
    '17': '102, 0, 153',
    '超過17': '102, 0, 153'
  }
}

// 2020.11.19 暫時將氣象局41種天氣描述歸類成八種常用：
// ["晴天", "雨天", "陰天", "多雲", "晴時多雲", "晴時多雲短暫雨", "多雲短暫雨", "陰短暫雨"]
// 其他用不太到：["雷雨", "下雪"]
const WeatherIconTable =[
{
  "desc": ["晴天", "晴"],
  "icon_img": get_img_url('weather/daytime_clear.png'),
  "night_icon_img": get_img_url('weather/night_clear.png')
}, {
  "desc": ["晴時多雲"],
  "icon_img": get_img_url('weather/daytime_mostly_clear.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear.png')
}, {
  "desc": ["多雲時晴"],
  "icon_img": get_img_url('weather/daytime_mostly_clear.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear.png')
}, {
  "desc": ["晴時多雲偶陣雨", "多雲時晴短暫陣雨", "多雲時晴短暫雨", "晴時多雲短暫陣雨", "晴短暫陣雨", "晴午後陰短暫雨", "晴午後陰短暫陣雨", "晴時多雲陣雨", "多雲時晴陣雨"],
  "icon_img": get_img_url('weather/daytime_mostly_clear_with_shower.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear_with_shower.png')
}, {
  "desc": ["多雲"],
  "icon_img": get_img_url('weather/partly_cloudy.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy.png')
}, {
  "desc": ["多雲時陰"],
  "icon_img": get_img_url('weather/partly_cloudy.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy.png')
}, {
  "desc": ["陰時多雲"],
  "icon_img": get_img_url('weather/cloudy.png'),
  "night_icon_img": get_img_url('weather/cloudy.png')
}, {
  "desc": ["陰天", "陰"],
  "icon_img": get_img_url('weather/cloudy.png'),
  "night_icon_img": get_img_url('weather/cloudy.png')
}, {
  "desc": ["小雨", "多雲陣雨", "多雲短暫雨", "多雲短暫陣雨", "午後短暫陣雨", "短暫陣雨", "短暫雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["多雲時陰短暫雨", "多雲時陰短暫陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["陰時多雲短暫雨", "陰時多雲短暫陣雨", "陰短暫雨", "陰短暫陣雨", "陰午後短暫陣雨"],
  "icon_img": get_img_url('weather/cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/cloudy_with_shower.png')
}, {
  "desc": ["大雨", "雨天", "陣雨", "有雨", "午後陣雨"],
  "icon_img": get_img_url('weather/rainy.png'),
  "night_icon_img": get_img_url('weather/rainy.png')
}, {
  "desc": ["多雲時陰有雨", "多雲時陰陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["中雨", "陰時多雲有雨", "陰時多雲有陣雨", "陰時多雲陣雨", "陰時多雲午後短暫陣雨"],
  "icon_img": get_img_url('weather/cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/cloudy_with_shower.png')
}, {
  "desc": ["陰有雨", "陰有陣雨", "陰雨", "陰陣雨"],
  "icon_img": get_img_url('weather/cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/cloudy_with_shower.png')
}, {
  "desc": ["多雲陣雨或雷雨", "多雲短暫陣雨或雷雨", "多雲短暫雷陣雨", "多雲雷陣雨", "短暫陣雨或雷雨後多雲", "短暫雷陣雨後多雲", "短暫陣雨或雷雨", "晴時多雲短暫陣雨或雷雨", "晴短暫陣雨或雷雨", "多雲時晴短暫陣雨或雷雨", "午後短暫雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲時陰陣雨或雷雨", "多雲時陰短暫陣雨或雷雨", "多雲時陰短暫雷陣雨", "多雲時陰雷陣雨", "晴陣雨或雷雨", "晴時多雲陣雨或雷雨", "多雲時晴陣雨或雷雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["陰時多雲有雷陣雨", "陰時多雲陣雨或雷雨", "陰時多雲短暫陣雨或雷雨", "陰時多雲短暫雷陣雨", "陰時多雲雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["陰有陣雨或雷雨", "陰有雷陣雨", "陰陣雨或雷雨", "陰雷陣雨", "晴午後陰短暫陣雨或雷雨", "晴午後陰短暫雷陣雨", "陰短暫陣雨或雷雨", "陰短暫雷陣雨", "雷雨", "陣雨或雷雨後多雲", "陰陣雨或雷雨後多雲", "陰短暫陣雨或雷雨後多雲", "陰短暫雷陣雨後多雲", "陰雷陣雨後多雲", "雷陣雨後多雲", "陣雨或雷雨", "雷陣雨", "午後雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["晴午後多雲局部雨", "晴午後多雲局部陣雨", "晴午後多雲局部短暫雨", "晴午後多雲局部短暫陣雨", "晴午後多雲短暫雨", "晴午後多雲短暫陣雨", "晴午後局部雨", "晴午後局部陣雨", "晴午後局部短暫雨", "晴午後局部短暫陣雨", "晴午後陣雨", "晴午後短暫雨", "晴午後短暫陣雨", "晴時多雲午後短暫陣雨", "多雲時晴午後短暫陣雨"],
  "icon_img": get_img_url('weather/daytime_mostly_clear_with_shower.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear_with_shower.png')
}, {
  "desc": ["多雲午後局部雨", "多雲午後局部陣雨", "多雲午後局部短暫雨", "多雲午後局部短暫陣雨", "多雲午後陣雨", "多雲午後短暫雨", "多雲午後短暫陣雨", "多雲時陰午後短暫陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["晴午後多雲陣雨或雷雨", "晴午後多雲雷陣雨", "晴午後陣雨或雷雨", "晴午後雷陣雨", "晴午後多雲局部陣雨或雷雨", "晴午後多雲局部短暫陣雨或雷雨", "晴午後多雲局部短暫雷陣雨", "晴午後多雲局部雷陣雨", "晴午後多雲短暫陣雨或雷雨", "晴午後多雲短暫雷陣雨", "晴午後局部短暫雷陣雨", "晴午後局部雷陣雨", "晴午後短暫雷陣雨", "晴雷陣雨", "晴時多雲雷陣雨", "晴時多雲午後短暫雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲午後局部陣雨或雷雨", "多雲午後局部短暫陣雨或雷雨", "多雲午後局部短暫雷陣雨", "多雲午後局部雷陣雨", "多雲午後陣雨或雷雨", "多雲午後短暫陣雨或雷雨", "多雲午後短暫雷陣雨", "多雲午後雷陣雨", "多雲時晴雷陣雨", "多雲時晴午後短暫雷陣雨", "多雲時陰午後短暫雷陣雨", "陰時多雲午後短暫雷陣雨", "陰午後短暫雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲局部陣雨或雪", "多雲時陰有雨或雪", "多雲時陰短暫雨或雪", "多雲短暫雨或雪", "陰有雨或雪", "陰時多雲有雨或雪", "陰時多雲短暫雨或雪", "陰短暫雨或雪", "多雲時陰有雪", "多雲時陰短暫雪", "多雲短暫雪", "陰有雪", "陰時多雲有雪", "陰時多雲短暫雪", "陰短暫雪", "有雨或雪", "有雨或短暫雪", "陰有雨或短暫雪", "陰時多雲有雨或短暫雪", "多雲時陰有雨或短暫雪", "多雲有雨或短暫雪", "多雲有雨或雪", "多雲時晴有雨或雪", "晴時多雲有雨或雪", "晴有雨或雪", "短暫雨或雪", "多雲時晴短暫雨或雪", "晴時多雲短暫雨或雪", "晴短暫雨或雪", "有雪", "多雲有雪", "多雲時晴有雪", "晴時多雲有雪", "晴有雪", "短暫雪", "多雲時晴短暫雪", "晴時多雲短暫雪", "晴短暫雪"],
  "icon_img": get_img_url('weather/snow.png'),
  "night_icon_img": get_img_url('weather/snow.png')
}, {
  "desc": ["晴有霧", "晴晨霧", "晴有霾"],
  "icon_img": get_img_url('weather/daytime_clear.png'),
  "night_icon_img": get_img_url('weather/night_clear.png')
}, {
  "desc": ["晴時多雲有霧", "晴時多雲晨霧"],
  "icon_img": get_img_url('weather/daytime_mostly_clear.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear.png')
}, {
  "desc": ["多雲時晴有霧", "多雲時晴晨霧"],
  "icon_img": get_img_url('weather/daytime_mostly_clear.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear.png')
}, {
  "desc": ["多雲有霧", "多雲晨霧", "有霧", "晨霧", "多雲時陰有霧", "多雲時陰晨霧", "多雲有靄"],
  "icon_img": get_img_url('weather/partly_cloudy.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy.png')
}, {
  "desc": ["陰有霧", "陰晨霧", "陰時多雲有霧", "陰時多雲晨霧"],
  "icon_img": get_img_url('weather/cloudy.png'),
  "night_icon_img": get_img_url('weather/cloudy.png')
}, {
  "desc": ["多雲局部雨", "多雲局部陣雨", "多雲局部短暫雨", "多雲局部短暫陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["晴午後陰局部雨", "晴午後陰局部陣雨", "晴午後陰局部短暫雨", "晴午後陰局部短暫陣雨"],
  "icon_img": get_img_url('weather/daytime_mostly_clear_with_shower.png'),
  "night_icon_img": get_img_url('weather/night_mostly_clear_with_shower.png')
}, {
  "desc": ["多雲時陰局部雨", "多雲時陰局部陣雨", "多雲時陰局部短暫雨", "多雲時陰局部短暫陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["陰局部雨", "陰局部陣雨", "陰局部短暫雨", "陰局部短暫陣雨", "陰時多雲局部雨", "陰時多雲局部陣雨", "陰時多雲局部短暫雨", "陰時多雲局部短暫陣雨", "陰有霧有陣雨", "陰局部雨有霧", "陰局部陣雨有霧", "陰局部短暫陣雨有霧", "陰時多雲有霧有局部雨", "陰時多雲有霧有局部陣雨", "陰時多雲有霧有局部短暫雨", "陰時多雲有霧有局部短暫陣雨", "陰時多雲有霧有陣雨", "陰時多雲有霧有短暫雨", "陰時多雲有霧有短暫陣雨", "陰時多雲局部雨有霧", "陰時多雲局部陣雨有霧", "陰時多雲局部短暫雨有霧", "陰時多雲局部短暫陣雨有霧", "陰時多雲陣雨有霧", "陰時多雲短暫雨有霧", "陰時多雲短暫雨晨霧", "陰時多雲短暫陣雨有霧", "陰時多雲短暫陣雨晨霧", "陰陣雨有霧", "陰短暫雨有霧", "陰短暫雨晨霧", "陰短暫陣雨有霧", "陰短暫陣雨晨霧"],
  "icon_img": get_img_url('weather/cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/cloudy_with_shower.png')
}, {
  "desc": ["多雲有霧有局部雨", "多雲有霧有局部陣雨", "多雲有霧有局部短暫雨", "多雲有霧有局部短暫陣雨", "多雲有霧有陣雨", "多雲有霧有短暫雨", "多雲有霧有短暫陣雨", "多雲局部雨有霧", "多雲局部雨晨霧", "多雲局部陣雨有霧", "多雲局部陣雨晨霧", "多雲局部短暫雨有霧", "多雲局部短暫雨晨霧", "多雲局部短暫陣雨有霧", "多雲局部短暫陣雨晨霧", "多雲陣雨有霧", "多雲短暫雨有霧", "多雲短暫雨晨霧", "多雲短暫陣雨有霧", "多雲短暫陣雨晨霧", "有霧有短暫雨", "有霧有短暫陣雨"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["多雲時陰有霧有局部雨", "多雲時陰有霧有局部陣雨", "多雲時陰有霧有局部短暫雨", "多雲時陰有霧有局部短暫陣雨", "多雲時陰有霧有陣雨", "多雲時陰有霧有短暫雨", "多雲時陰有霧有短暫陣雨", "多雲時陰局部雨有霧", "多雲時陰局部陣雨有霧", "多雲時陰局部短暫雨有霧", "多雲時陰局部短暫陣雨有霧", "多雲時陰陣雨有霧", "多雲時陰短暫雨有霧", "多雲時陰短暫雨晨霧", "多雲時陰短暫陣雨有霧", "多雲時陰短暫陣雨晨霧"],
  "icon_img": get_img_url('weather/partly_cloudy_with_shower.png'),
  "night_icon_img": get_img_url('weather/partly_cloudy_with_shower.png')
}, {
  "desc": ["多雲局部陣雨或雷雨", "多雲局部短暫陣雨或雷雨", "多雲局部短暫雷陣雨", "多雲局部雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲時陰局部陣雨或雷雨", "多雲時陰局部短暫陣雨或雷雨", "多雲時陰局部短暫雷陣雨", "多雲時陰局部雷陣雨", "晴午後陰局部陣雨或雷雨", "晴午後陰局部短暫陣雨或雷雨", "晴午後陰局部短暫雷陣雨", "晴午後陰局部雷陣雨", "陰局部陣雨或雷雨", "陰局部短暫陣雨或雷雨", "陰局部短暫雷陣雨", "陰局部雷陣雨", "陰時多雲局部陣雨或雷雨", "陰時多雲局部短暫陣雨或雷雨", "陰時多雲局部短暫雷陣雨", "陰時多雲局部雷陣雨"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲有陣雨或雷雨有霧", "多雲有雷陣雨有霧", "多雲有霧有陣雨或雷雨", "多雲有霧有雷陣雨", "多雲局部陣雨或雷雨有霧", "多雲局部短暫陣雨或雷雨有霧", "多雲局部短暫雷陣雨有霧", "多雲局部雷陣雨有霧", "多雲陣雨或雷雨有霧", "多雲短暫陣雨或雷雨有霧", "多雲短暫雷陣雨有霧", "多雲雷陣雨有霧", "多雲時晴短暫陣雨或雷雨有霧"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲時陰有陣雨或雷雨有霧", "多雲時陰有雷陣雨有霧", "多雲時陰有霧有陣雨或雷雨", "多雲時陰有霧有雷陣雨", "多雲時陰局部陣雨或雷雨有霧", "多雲時陰局部短暫陣雨或雷雨有霧", "多雲時陰局部短暫雷陣雨有霧", "多雲時陰局部雷陣雨有霧", "多雲時陰陣雨或雷雨有霧", "多雲時陰短暫陣雨或雷雨有霧", "多雲時陰短暫雷陣雨有霧", "多雲時陰雷陣雨有霧", "陰局部陣雨或雷雨有霧", "陰局部短暫陣雨或雷雨有霧", "陰局部短暫雷陣雨有霧", "陰局部雷陣雨有霧", "陰時多雲有陣雨或雷雨有霧", "陰時多雲有雷陣雨有霧", "陰時多雲有霧有陣雨或雷雨", "陰時多雲有霧有雷陣雨", "陰時多雲局部陣雨或雷雨有霧", "陰時多雲局部短暫陣雨或雷雨有霧", "陰時多雲局部短暫雷陣雨有霧", "陰時多雲局部雷陣雨有霧", "陰時多雲陣雨或雷雨有霧", "陰時多雲短暫陣雨或雷雨有霧", "陰時多雲短暫雷陣雨有霧", "陰時多雲雷陣雨有霧", "陰短暫陣雨或雷雨有霧", "陰短暫雷陣雨有霧", "雷陣雨有霧"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["多雲局部雨或雪有霧", "多雲時陰局部雨或雪有霧", "陰時多雲局部雨或雪有霧", "陰局部雨或雪有霧", "短暫雨或雪有霧", "有雨或雪有霧"],
  "icon_img": get_img_url('weather/snow.png'),
  "night_icon_img": get_img_url('weather/snow.png')
}, {
  "desc": ["短暫陣雨有霧", "短暫陣雨晨霧", "短暫雨有霧", "短暫雨晨霧"],
  "icon_img": get_img_url('weather/rainy.png'),
  "night_icon_img": get_img_url('weather/rainy.png')
}, {
  "desc": ["有雨有霧", "陣雨有霧"],
  "icon_img": get_img_url('weather/rainy.png'),
  "night_icon_img": get_img_url('weather/rainy.png')
}, {
  "desc": ["短暫陣雨或雷雨有霧", "陣雨或雷雨有霧"],
  "icon_img": get_img_url('weather/thunder_shower.png'),
  "night_icon_img": get_img_url('weather/thunder_shower.png')
}, {
  "desc": ["下雪", "積冰", "暴風雪"],
  "icon_img": get_img_url('weather/snow.png'),
  "night_icon_img": get_img_url('weather/snow.png')
}, {
  "desc": ["不明"],
  "icon_img": get_img_url('unknown.png'),
  "night_icon_img": get_img_url('unknown.png')
}]

const WSIconTable = [
  {
    'level': [0],
    'icon_img': get_img_url('direction_level/ws_0.png'),
  },
  {
    'level': [1, 2],
    'icon_img': get_img_url('direction_level/ws_1.png'),
  },
  {
    'level': [3],
    'icon_img': get_img_url('direction_level/ws_2.png'),
  },
  {
    'level': [4],
    'icon_img': get_img_url('direction_level/ws_3.png'),
  },
  {
    'level': [5],
    'icon_img': get_img_url('direction_level/ws_4.png'),
  },
  {
    'level': [6, 7],
    'icon_img': get_img_url('direction_level/ws_5.png'),
  },
  {
    'level': ExceptValues,
    'icon_img': get_img_url('unknown.png'),
  },
]

export {
  ExceptValues,
  WeatherColorTable,
  WeatherIconTable,
  WSIconTable,
}
