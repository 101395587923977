import { watch } from 'vue'

export const useCctvVLayer = (map, DetailStore, CctvStore) => {
  const layer_group = L.featureGroup().addTo(map)

  const Icon = L.Icon.extend({
    options: {
      iconUrl: require('@/assets/imgs/CCTV_WRA.png'),
      iconSize: [36, 36],
      iconAnchor: [18, 18],
    }
  })

  const CollectionsIcon = Icon.extend({
    options: {
      shadowUrl: require('@/assets/imgs/check_hook.png'),
      shadowSize: [20, 20],
      iconSize: [36, 36],
      shadowAnchor: [0, 20],
      iconAnchor: [18, 18],
    }
  })

  const icon = new Icon()
  const icon_in_collections = new CollectionsIcon()

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-cctv/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-cctv text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      features.forEach(element => {
        const marker = L.marker({
          lat: element.lat,
          lon: element.lon
        }, {
          icon: get_icon(element.unique_key),
          shadowPane: 'favoritesPane'
        }).on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return
          if (is_checked(element.unique_key)) {
            DetailStore.remove_temp_selected_features(element)
            marker.setIcon(icon)
          } else {
            DetailStore.add_temp_selected_features(element)
            marker.setIcon(icon_in_collections)
          }
        }).addTo(cluster_group)
      })

      layer_group.addLayer(cluster_group)
    }
  }

  if (!CctvStore.state.features) {
    CctvStore.get_features()
  }

  const is_checked = (unique_key) => {
    return DetailStore.state.temp_unique_key_properties_mapping[unique_key] ? true : false
  }

  const get_icon = unique_key => {
    if (is_checked(unique_key)) {
      return icon_in_collections
    }
    return icon
  }

  watch(() => CctvStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate:true
  })
}
