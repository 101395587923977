import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { RainStationRepo } from '@/repositories/rain_station'

export const useRainStationStore = (LayerStore) => {
  const state = reactive({
    rain_obs_data: [],
    accumulate_rain_obs_data: {},
    is_getting_rain_obs_data: false,
    features: null,
  })

  const get_rain_obs_data = async (station_id) => {
    state.is_getting_rain_obs_data = true
    state.rain_obs_data = []
    let date = new Date().zero_minute()
    try {
      let payload = await RainStationRepo.obs.get({
        station_id: station_id,
        // 取７天觀測雨量資料
        dt_from: date.addDays(-7).isostrz(),
        dt_to: date.isostrz()
      })
      payload.data = datetime_format(payload.data)
      state.rain_obs_data = payload.data
    } catch (error) {
      console.warn(error)
    }
    state.is_getting_rain_obs_data = false
  }

  const get_accumulate_rain_obs_data = async () => {

    state.accumulate_rain_obs_data = []
    let date = new Date().zero_minute()
    try {
      let payload = await RainStationRepo.obs.get({
        // bubble marker 只要 3 小時觀測雨量資料就可以
        dt_from: date.addHours(-3).isostr(),
        dt_to: date.isostrz()
      })
      payload.data.forEach(element => {
        element.dtime = new Date(element.dtime)
        state.accumulate_rain_obs_data[element.station_id] = state.accumulate_rain_obs_data[element.station_id] || []
        state.accumulate_rain_obs_data[element.station_id].push(element)
      })
    } catch (error) {
      console.warn(error)
    }
  }

  const datetime_format = (info) => {
    for (let detail_info of info) {
      if (detail_info.dtime) {
        detail_info.dtime = new Date(detail_info.dtime)
      }
    }
    return info
  }

  const get_features = async () => {
    set_layer_loading(true)
    try {
      let payload = await RainStationRepo.station_info.get()
      state.features = markRaw(payload.data)
    }
    finally {
      set_layer_loading(false)
    }
  }

  const set_layer_loading = (val) => LayerStore.set_loading(val, 'rain_station')

  /* watch */
  watch(() => LayerStore.state.checked_layers.rain_station, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    get_rain_obs_data,
    get_accumulate_rain_obs_data,
    get_features
  }
  provide('RainStationStore', store)
  return store
}
