<template>
  <div
    v-show="visible"
    class="fixed w-full h-full inset-0 z-modal flex text-dark-50"
    :class="{ 'bg-black/30': !mask_penetrable }"
    @click.self="mask_closable ? toggle(false) : null"
  >
    <div
      class="relative w-11/12 border border-gray-200 rounded-2xl bg-white shadow p-4 m-auto md:w-1/2 lg:w-1/3"
      :class="card_class"
    >
      <header class="text-center mb-4">
        <slot name="header">
          <h3 class="text-lg font-bold tracking-wider">
            {{ title }}
          </h3>
        </slot>
      </header>

      <i
        v-show="closable"
        class="icon-symbol_close text-xl font-bold text-gray-400 absolute top-4 right-4 cursor-pointer"
        @click="toggle(false)"
      >
      </i>

      <main>
        <div
          class="modal-content text-left my-2 overflow-y-scroll scrollbar-hidden"
        >
          <slot>
            <div v-if="html_content" v-html="html_content"></div>
            <div
              v-else
              class="text-center text-lg top-2 bottom-4 tracking-wider"
            >
              {{ content }}
            </div>
          </slot>
        </div>

        <slot name="footer">
          <div class="w-full flex flex-wrap pt-2">
            <button
              v-if="cancel_btn_required"
              class="btn_cancel flex-1 mx-2"
              @click="cancel"
            >
              {{ cancel_text }}
            </button>
            <slot name="ok_btn">
              <button
                class="btn_primary flex-1 mx-2 flex justify-center items-center gap-2"
                :disabled="loading || disabled"
                :loading="loading"
                @click="ok"
              >
                <span>
                  {{ ok_text }}
                </span>
                <div v-show="loading" class="loader_rotate"></div>
              </button>
            </slot>
          </div>
        </slot>
      </main>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, watch } from '@vue/runtime-core'

export default {
  props: {
    // 顯示/隱藏 modal，外層用 v-model:visible 控制
    visible: {
      type: Boolean,
      default: false,
    },

    // 標題
    title: {
      type: String,
      default: '標題',
    },

    // 內容
    content: {
      type: String,
      default: '內容',
    },
    // html 格式的內容
    html_content: {
      type: String,
      default: null,
    },
    // P.S. 傳入內容的優先度：slot > html_content > content

    // 是否需要顯示取消按鈕
    cancel_btn_required: {
      type: Boolean,
      default: false,
    },

    // 確認按鈕的文字
    ok_text: {
      type: String,
      default: '確定',
    },

    // 取消按鈕的文字
    cancel_text: {
      type: String,
      default: '取消',
    },

    // 是否有右上角關閉按鈕
    closable: {
      type: Boolean,
      default: false,
    },

    // 是否能點擊背景關閉
    mask_closable: {
      type: Boolean,
      default: false,
    },

    // 若能穿透，背景會是透明的、使用者可以滾動頁面；若不能穿透，背景會是半透明黑、使用者無法滾動頁面。
    mask_penetrable: {
      type: Boolean,
      default: false,
    },

    // 需要覆蓋目前 model 本身的卡片樣式
    card_class: {
      type: String,
      default: null,
    },

    // 確認按鈕 loading 的狀態
    // 若有傳入 null 以外的值，使用者點擊確認後不會直接關閉，需由外部控制
    // 此外，若不希望點選 ok 直接關閉，也可以將 loading 設為 false，由外部關閉
    loading: {
      type: Boolean,
      default: null,
    },

    // 確認按鈕 disabled 的狀態。通常用在 content 中有必填欄位的情況
    disabled: {
      type: Boolean,
      default: false,
    },

    // 是否能按 esc 關閉
    esc_closable: {
      type: Boolean,
      default: false,
    },

    // 是否能按 enter 確定
    enter_enabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const ok = () => {
      emit('confirm', true)
      if (props.loading === null) {
        // 外層沒使用到 loading，自動關閉 modal
        toggle(false)
      }
    }

    const cancel = () => {
      emit('confirm', false)
      toggle(false)
    }

    const toggle = (visible) => {
      emit('update:visible', visible)
    }

    // esc 關閉
    const esc_close = (e) => {
      if (e.key === 'Escape' && props.visible) {
        toggle(false)
      }
    }
    if (props.esc_closable) {
      document.addEventListener('keyup', esc_close)
    }

    // enter 確定
    const enter_ok = (e) => {
      if (e.key === 'Enter' && props.visible) {
        ok()
      }
    }
    if (props.enter_enabled) {
      document.addEventListener('keyup', enter_ok)
    }

    // 控制背景是否可滾動
    watch(
      () => props.visible,
      (visible) => {
        if (visible && !props.mask_penetrable) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      }
    )

    onBeforeUnmount(() => {
      document.body.style.overflow = ''

      // esc 關閉
      if (props.esc_closable) {
        document.removeEventListener('keyup', esc_close)
      }
      // enter 確定
      if (props.enter_enabled) {
        document.removeEventListener('keyup', enter_ok)
      }
    })

    return {
      ok,
      cancel,
      toggle,
    }
  },
}
</script>

<style scoped>

.modal-content {
  max-height: calc(
    100vh - 320px
  ); /* 減去header+footer以及其margin，還有ios手機可能長出的footer */
}

</style>
>
