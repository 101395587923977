import { useCctvVLayer } from "./CctvVLayer"
import { useWaterStationVLayer } from "./WaterStationVLayer"
import { useRainStationVLayer } from "./RainStationVLayer"
import { useTidalVLayer } from "./TidalVLayer"
import { useInshoreVLayer } from "./InshoreVLayer"
import { useVSelectorMap } from "./VSelectorMap"
import { useBuoyVLayer } from "./BuoyVLayer"
import { useTideVLayer } from "./TideVLayer"

export default {
  useVSelectorMap,
  useCctvVLayer,
  useWaterStationVLayer,
  useTidalVLayer,
  useRainStationVLayer,
  useInshoreVLayer,
  useBuoyVLayer,
  useTideVLayer
}