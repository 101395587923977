import { reactive, readonly, provide, markRaw } from 'vue'

import { AlertRepo } from '@/repositories/alert'


export const useAlertStore = (LayerStore) => {
  const state = reactive({
    alert_messages: [],
    alerts: null
  })

  const ALERT_LEVEL = ['Minor', 'Moderate', 'Severe', 'Extreme']

  const set_alert_messages = (messages) => {
    state.alert_messages = messages
  }

  const set_loading = (val) => {
    LayerStore.set_loading(val, 'rainfall')
    LayerStore.set_loading(val, 'thunderstorm')
  }

  const fetch_alerts = async () => {
    set_loading(true)
    let rainfall_messages = [], thunderstorm_messages = []
    try {
      let payload = await AlertRepo.alert.get()
      state.alerts = markRaw(payload.data)
      payload.data.forEach(alert => {
        if (alert.event_code === 'rainfall') {
          // 豪大雨特報 因會有很多一樣的報，所以特別做以下處理，只抓最嚴重的出來
          if (rainfall_messages.length === 0) {
            // 下雨的警報整理過後，只要顯示一個
            rainfall_messages.push({
              title: alert.alert_title,
              description: alert.description,
              effective: new Date(alert.effective).toLST(),
              notification_type: 'alert'
            })
          } else {
            // 利用 ALERT_LEVEL 的 idx 來找出最嚴重的程度，再根據這程度找 title 顯示出來
            let severity_idx = -1
            severity_idx = payload.data.filter(alert => alert.event_code === 'rainfall').reduce((accumulator, alert) => {
              if (alert.event_code === 'rainfall') {
                const _severity_idx = ALERT_LEVEL.findIndex(severity => severity === alert.severity)
                if (_severity_idx > accumulator && alert.event_code === 'rainfall') {
                  return _severity_idx
                }
              }
              return accumulator
            }, severity_idx)
            rainfall_messages[0].title = payload.data.find(alert => alert.severity === ALERT_LEVEL[severity_idx])['alert_title']
          }
        }
        else if (alert.event_code === 'thunderstorm') {
          // 雷雨即時訊息 目前把有的都顯示出來，之後再看狀況要不要做跟上面（豪大雨特報）類似的處理
          thunderstorm_messages.push({
            title: alert.alert_title,
            description: alert.description,
            effective: new Date(alert.effective).toLST(),
            notification_type: 'alert'
          })
        }
      })
      set_alert_messages([...rainfall_messages, ...thunderstorm_messages])
    }
    finally {
      set_loading(false)
    }
  }

  fetch_alerts()

  const store = {
    state: readonly(state),
    fetch_alerts  // for focus refresh
  }

  provide('AlertStore', store)

  return store
}
