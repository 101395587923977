/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const CctvRepo = {
  cctv: {
    get: (params) => req("get", 'v6/cctv/', params)
  },

  cctv_imgs: {
    get: (cctv_id) => req("get", `v5/cctv/${cctv_id}/imgs/`)
  }
}
