import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet-src.js'

import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.markercluster"

import './libs/cb-static/cb.util'
import './libs/cb-static/sprintf.min'
import './libs/cb-static/cb.date'

import '@/libs/cb-chart/index.css'

// 以下順序需固定
import 'virtual:windi-base.css';
import 'virtual:windi-components.css';
import cb_theme from './libs/cb-theme'
import './index.css'
import 'virtual:windi-utilities.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.component('Datepicker', Datepicker);

app.use(cb_theme)
app.use(router)

app.mount('#app')
