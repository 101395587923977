import { req } from "@/axios/fwm"

export const MarineRepo = {
  station: {
    get: (params) => req("get", 'v6/marine/station/', params)
  },

  station_data: {
    get: (params) => req("get", 'v5/marine/obs/', params)
  },
}