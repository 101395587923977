/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"


export const WaterStationRepo = {
  station_info: {
    get: (params) => req("get", "v6/water_station/", params)
  },
  station_stage: {
    get: (params) => req("get", "water_station/stage/", params),
  },
}
