<template>
  <div class="safe_padding_top w-full h-full">
    <div class="bg-primary font-bold p-2 flex items-center justify-between">
      <div
        @click.stop="exit"
        class="py-1 px-4 text-lg cursor-pointer"
      >
        <i class="icon-symbol_back text-white"></i>
      </div>
      <div
        class="text-white"
      >{{ title }}</div>
      <!-- <div>我的最愛</div> -->
      <div class="">
        <detail-favorites-btn
          :latlon="latlon"
        ></detail-favorites-btn>
      </div>
    </div>
    <div class="h-full overflow-y-auto pb-80px">
      <!-- 天氣chart -->
      <div>
        <div
        class="flex h-[44px] bg-title flex items-center"
        >
        <div
          @click="DetailStore.set_weather_visible(!weather_visible)"
          :class="weather_visible ? 'icon-symbol_subtract' : 'icon-symbol_add'"
          class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
        >
          </div>
          <div class="w-full mt-4 mb-4 ml-4">
            14日天氣預報
          </div>
        </div>
        <div
          class="w-full overflow-x-auto scrollbar-hidden px-1 flex bg-gray-100"
          v-show="weather_visible"
        >
          <router-link
            :to="{
              name: 'HistoryWeather',
              params: { lat: latlon[0], lon: latlon[1] },
            }"
            class="date_block !p-2 font-bold"
          >
            <i class="icon-symbol_left p-0"></i>
          </router-link>
          <div
            v-for="(date, idx) in date_list"
            :key="'date ' + idx"
            class="date_block"
            :class="{
              selected: date.getTime() === selected_date.zero_hour().getTime(),
              weekend: date.getDay() === 0 || date.getDay() === 6,
            }"
            @click="selected_date = date"
          >
            <div class="font-bold">
              {{ date.strftime('%-a') }}
            </div>
            <div>
              {{ parse_date(date) }}
            </div>
          </div>
        </div>
        <div
        v-show="weather_visible"
          class="absolute right-1 -mt-3 bg-primary-light p-2 rounded z-10 cursor-pointer"
          v-if="!tip_showed"
          @click="set_tip_showed(true)"
        >
          <i class="icon-info pr-1">可滑動日期列查看 2 週天氣預報</i>
        </div>
        <div
          v-show="weather_visible"
          class="relative h-[260px] shadow-chart">
          <div v-show="is_getting_weather_data" class="blink_loading"></div>
          <div
            id="weather_chart"
            v-if="selected_date_data?.length"
            class="h-full"
          ></div>
          <div v-else class="h-full flex items-center justify-center">
            暫無資料
          </div>
        </div>
      </div>
      <!-- 鄰近圖資 -->
      <div
        v-for="(feature, idx) in total_station_data_feature"
        :key="feature.unique_key"
      >
        <DetailStationStageChart
          v-if="feature.type_en === 'water_station'"
          :feature="feature"
        ></DetailStationStageChart>
        <DetailRainChart
          v-if="feature.type_en === 'rain_station'"
          :feature="feature"
        ></DetailRainChart>
        <DetailTidalChart
          v-if="feature.type_en === 'tidal_station'"
          :feature="feature"
        ></DetailTidalChart>
        <DetailTideInfo
          v-if="feature.type_en === 'tide_station'"
          :feature="feature"
        ></DetailTideInfo>
        <DetailInshoreChart
          v-if="feature.type_en === 'inshore_area'"
          :feature="feature"
        ></DetailInshoreChart>
        <DetailBuoyInfo
          v-if="feature.type_en === 'buoy_station'"
          :feature="feature"
        ></DetailBuoyInfo>
        <DetailCctv
          v-if="feature.type_en === 'cctv'"
          :feature="feature"
        ></DetailCctv>
      </div>
    </div>
    <marker-selector-map
      v-if="add_to_collections_visible"
      v-model:visible="add_to_collections_visible"
    ></marker-selector-map>
    <div class="w-full left-0 bottom-0 fixed items-center z-60">
      <button class="w-full bg-gray-700 bg-opacity-70 text-white icon-symbol_add" @click="add_to_collections_visible = true"></button>
    </div>
  </div>
</template>

<script>
import { watch, inject, onMounted, ref, markRaw, computed, watchEffect } from 'vue'
import { onBeforeRouteLeave } from 'vue-router';
import { cb_alert } from '@/libs/cb-theme'
import { useWeatherChart } from '@/compositions/chart/weather_chart'
import DetailStationStageChart from '../components/DetailStationStageChart.vue'
import DetailRainChart from '../components/DetailRainChart.vue'
import DetailTidalChart from '../components/DetailTidalChart.vue'
import DetailTideInfo from '../components/DetailTideInfo.vue'
import DetailInshoreChart from '../components/DetailInshoreChart.vue'
import DetailBuoyInfo from '../components/DetailBuoyInfo.vue'
import DetailCctv from '../components/DetailCctv.vue'
import DetailFavoritesBtn from '../components/DetailFavoritesBtn.vue'
import MarkerSelectorMap from '../components/MarkerSelectorMap.vue'
import { GeoRepo } from '@/repositories/map'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'Details',

  components: {
    DetailStationStageChart,
    DetailRainChart,
    DetailTidalChart,
    DetailTideInfo,
    DetailInshoreChart,
    DetailBuoyInfo,
    DetailCctv,
    MarkerSelectorMap,
    DetailFavoritesBtn
  },

  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const DetailStore = inject('DetailStore')
    const WeatherDataStore = inject('WeatherDataStore')
    const FavoritesStore = inject('FavoritesStore')

    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const weather_visible = computed(() => DetailStore.state.weather_visible)
    const weather_chart = useWeatherChart('weather_chart')

    const add_to_collections_visible = ref(false)

    const weather_raw_data = ref(null)
    const town_72hr_fcst_raw_data = ref(null) // 這個資料目前只取降雨機率 (pop6h) 和從天氣描述 (weather) 中取出打雷預報

    const selected_date = ref(null)
    const refresh_selected_date = () => {
      let _date =
        new Date().getHours() >= 18
          ? new Date().zero_hour().addDays(1)
          : new Date().zero_hour() // 超過當天下午18點則預設看隔天的資料
      if (
        !selected_date.value ||
        _date.getTime() !== selected_date.value.getTime()
      )
        selected_date.value = _date
    }

    const date_list = ref([])
    const refresh_date_list = () => {
      let start_at = new Date().zero_hour()

      if (
        date_list.value.length &&
        date_list.value[0].getTime() === start_at.getTime()
      )
        return

      let end_at = start_at.addDays(14)

      let _date_list = []
      for (let dtime = start_at; dtime < end_at; dtime = dtime.addDays(1)) {
        _date_list.push(dtime)
      }
      date_list.value = _date_list
    }

    const is_getting_weather_data = ref(false)
    const get_weather_raw_data = async (lat, lon) => {
      is_getting_weather_data.value = true
      // 從當天0時開始取得天氣觀測資料
      const fetchers = [
        WeatherDataStore.fetch_obs_data(
          lat,
          lon,
          new Date().zero_hour(),
          new Date().zero_minute()
        ),
        WeatherDataStore.fetch_fcst_data(lat, lon),
        WeatherDataStore.fetch_town_72hr_fcst_data(lat, lon),
      ]

      try {
        let [
          _obs_data_list,
          _fcst_data_list,
          _town_72hr_fcst_data_list,
        ] = await Promise.all(fetchers)
        if (_obs_data_list.length) {
          weather_raw_data.value = markRaw(
            _obs_data_list.concat(
              // 天氣預報資料和降雨機率接續觀測資料最後一筆資料時間
              _fcst_data_list.filter(
                (data) =>
                  data.dtime > _obs_data_list[_obs_data_list.length - 1].dtime
              )
            )
          )
          town_72hr_fcst_raw_data.value = markRaw(
            _town_72hr_fcst_data_list.filter(
              (data) =>
                data.dtime > _obs_data_list[_obs_data_list.length - 1].dtime
            )
          )
        } else {
          // 如果無天氣觀測資料，取目前時間後的天氣預報資料和降雨機率
          weather_raw_data.value = markRaw(
            _fcst_data_list.filter((data) => data.dtime > new Date())
          )
          town_72hr_fcst_raw_data.value = markRaw(
            _town_72hr_fcst_data_list.filter((data) => data.dtime > new Date())
          )
        }
      } catch (error) {
        cb_alert('天氣資料載入失敗')
      }
      is_getting_weather_data.value = false
    }

    const tip_showed = ref(localStorage.getItem('tip_showed')) || ref(false)
    const set_tip_showed = (is_click) => {
      if (is_click) {
        gtag('event', 'close_tip_by_click')
        tip_showed.value = true
        localStorage.setItem('tip_showed', true)
      }
      else {
        setTimeout(() => {
          gtag('event', 'close_tip_by_timeout')
          tip_showed.value = true
          localStorage.setItem('tip_showed', true)
        }, 3000)
      }
    }

    const parse_date = (date) => {
      let diff_days = date.diffDays(new Date().zero_hour())
      if (diff_days === 0) {
        return '今'
      } else if (diff_days === 1) {
        return '明'
      } else {
        return date.strftime('%d')
      }
    }

    const selected_date_data = computed(() => {
      if (selected_date.value.diffDays(new Date().zero_hour()) >= 7) {
        // gtag('event', 'select_day_of_2nd_week')
      }
      return weather_raw_data.value?.filter((data) => {
        return (
          data.dtime >= selected_date.value &&
          data.dtime <= selected_date.value.addHours(23)
        )
      })
    })

    DetailStore.reset_default_store()
    refresh_selected_date()
    refresh_date_list()
    DetailStore.set_pin_latlon(route.query.lat, route.query.lon)

    onMounted(async () => {
      get_weather_raw_data(route.query.lat, route.query.lon)
      set_tip_showed()
      const data = await DetailStore.check_collections(route.query.lat, route.query.lon)
      const features = []
      if (data?.features) {
        data.features.forEach(f => {
          DetailStore.set_station_control_config(f.unique_key, f.visible)
          features.push(f)
        })
        DetailStore.set_total_station_data_feature(features)
      } else {
        DetailStore.refresh_all_features(route.query.lat, route.query.lon)
      }
    })

    watch(
      [selected_date_data, town_72hr_fcst_raw_data, weather_visible],
      ([_selected_date_data, _town_72hr_fcst_raw_data, visible]) => {
        if (visible) {
          weather_chart.refresh_chart(
            _selected_date_data,
            _town_72hr_fcst_raw_data
          )
        }
      }
    )

    watch(() => new Set(Object.keys(FavoritesStore.state.favorites_mapping)), favorites_mapping_set => {
      const query = route.query
      if (!favorites_mapping_set.has(`pin,${query.lat},${query.lon}`)) {
        // pass~~
      } else {
        DetailStore.check_collections(query.lat, query.lon)
      }
    })

    const total_station_data_feature = computed(() => {
      return DetailStore.state.total_station_data_feature
    })

    const _feature_name = ref(null)
    const get_town_village = async (lat, lon) => {
      // 因為 GeoRepo.town_village 已改用 WD API, 儘量用應該還好, 而且有 cache
      let townvillage_res = await GeoRepo.town_village.get(lat, lon)
      if (townvillage_res?.data?.town && townvillage_res?.data?.village) {
        _feature_name.value = townvillage_res.data.town + townvillage_res.data.village
      }
    }

    const exit = async () => {
      DetailStore.save_collections()
      router.push({name: 'Home'})
    }

    return {
      title: computed(() => {
        const feature = FavoritesStore.state.favorites_mapping[`pin,${route.query.lat},${route.query.lon}`]
        if (feature) {
          return feature.name
        }
        if (_feature_name.value === null) {
          get_town_village(route.query.lat, route.query.lon)
          return `${route.query.lat},${route.query.lon}`
        }
        return _feature_name.value
      }),
      date_list,
      selected_date,
      tip_showed,
      set_tip_showed,
      parse_date,
      selected_date_data,
      is_getting_weather_data,
      DetailStore,
      total_station_data_feature,
      exit,
      total_station_visible,
      weather_visible,
      add_to_collections_visible,
      latlon: [route.query.lat, route.query.lon]
    }
  },
}
</script>
<style lang="scss" scoped>
.date_block {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 0 4px 0 var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
}
@media (min-width: 640px) {
  .date_block {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}
.date_block.selected {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 149, 166, var(--tw-bg-opacity));
  color: white !important;
}
.date_block.weekend {
  --tw-text-opacity: 1;
  color: rgba(246, 180, 85, var(--tw-text-opacity));
}
.date_block.weekday {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
</style>