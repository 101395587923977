import { nextTick } from 'vue'

function mix_weather_with_large_pop(wx) {
  if (wx) {
    if (!wx.includes('雨')) {
      if (wx.includes('陰')) {
        return "陰短暫雨"
      }
      if (wx.includes('晴')) {
        return "多雲時晴陣雨"
      }
      else {
        return "多雲陣雨"
      }
    }
  }
  return wx
}

function mix_weather_with_little_pop(wx) {
  if (wx) {
    if (wx === '大雨') {
      return "陣雨"
    }
    if (wx === '中雨') {
      return "陰陣雨"
    }
  }
  return wx
}


export const useWeatherChart = (chart_id) => {
  let chart = null
  let dt_from, dt_to
  let weather_raw_data, town_fcst_raw_data

  const draw_chart = () => {
    let data_artists = get_data_artists()
    let chart_options = get_chart_options()

    chart = new FwmWeatherChart(chart_id,
      data_artists,
      chart_options
    )
  }

  const get_data_artists = () => {
    let datasets = {
      weather_icon: [],
      tx: [],
      tx_min_max: [],
      precp: [],
      wswd: [],
      pop: [],
      pres: [],
      thunder: []
    }

    for (let data of weather_raw_data) {
      let datetime = new Date(data.dtime)

      datasets.tx.push({
        x: datetime, y: data.tx
      })
      datasets.precp.push({
        x: datetime, y: data.precp
      })
      datasets.weather_icon.push({
        x: datetime, y: data.wx
      })
      datasets.wswd.push({
        x: datetime, y: { ws: data.ws, wd: data.wd }
      })
      datasets.pres.push({
        x: datetime, y: data.pres
      })
    }

    /* 準備最高、最低溫資訊 */
    // 移除遺失資料，避免影響最低溫數值
    let clean_tx = datasets.tx.filter(e => e.y !== -999)
    let _time_and_tx_filter = (tx_datasets, start_hr, end_hr, minmax) => {
      let time_filtered_sets = tx_datasets.filter(e => e.x.getHours() >= start_hr && e.x.getHours() <= end_hr)
      if (time_filtered_sets.length > 0) {
        if (minmax === "max") return time_filtered_sets.reduce((a, b) => a.y > b.y ? a : b)
        else if (minmax === "min") return time_filtered_sets.reduce((a, b) => a.y < b.y ? a: b)
        else console.error("No min or max specified.")
      }
      return null
    }
    // 準備清晨最低溫（0~5點）
    let late_night_tx_min = _time_and_tx_filter(clean_tx, 0, 5, "min")
    if (late_night_tx_min !== null) datasets.tx_min_max.push({...late_night_tx_min, is_max:false})
    // 準備晚上最低溫（18~23點）
    let night_tx_min = _time_and_tx_filter(clean_tx, 18, 23, "min")
    if (night_tx_min !== null) datasets.tx_min_max.push({...night_tx_min, is_max:false})
    // 準備白天最高溫（6~17點）
    let day_tx_max = _time_and_tx_filter(clean_tx, 6, 17, "max")
    if (day_tx_max !== null) datasets.tx_min_max.push({...day_tx_max, is_max:true})
    // 若 有白天最高溫 且 白天最高溫 低於 清晨最低溫或晚上最低溫，則顯示白天最低溫。
    if (
        (day_tx_max !== null) && (
          (late_night_tx_min !== null && late_night_tx_min.y > day_tx_max.y) ||
          (night_tx_min !== null && night_tx_min.y > day_tx_max.y)
        )
      ) {
      let day_tx_min = _time_and_tx_filter(clean_tx, 6, 17, "min")
      datasets.tx_min_max.push({...day_tx_min, is_max:false})
    }

    let weather_icon_idx = 0
    let weather_icon_length = datasets.weather_icon.length

    // 增加 pop dataset
    for (let data of town_fcst_raw_data) {
      let datetime = new Date(data.dtime)

      if (datetime.getTime() >= dt_from.getTime() && datetime.getTime() <= dt_to.getTime()) {
        datasets.pop.push({
          x: datetime, y: data.pop6h
        })
        /*
        Jimmy@2021-08-09
        增加了打雷預報的dataset
        目前處理打雷預報的演算法，是根據天氣描述中如果有包含「雷」這個關鍵字
        就當作可能發生打雷，例如：短暫陣雨或雷雨、陣雨或雷雨
        */
        if (data.weather && data.weather.includes('雷')) {
          datasets.thunder.push({
            x: datetime, y: data.weather
          })
        }

        if (data.pop6h >= 60 || data.pop6h <= 20) {
          /*
          lwsu@2022-01-22
            這裏把氣象局 pop 加上 weather_icon 的演算
            1. 有時看到 weather icon 是晴天, 但 pop 又很大 (這裏先假設 >=60 的門檻)
            2. 有時看到 weather icon 是大雨, 但 pop 又很小 (這裏先假設 <=20 的門檻)
          */
          for (; weather_icon_idx < weather_icon_length; weather_icon_idx++) {
            let wx_data = datasets.weather_icon[weather_icon_idx]
            if (wx_data.x.equal(datetime)) {
              if (data.pop6h >= 60) {
                wx_data.y = mix_weather_with_large_pop(wx_data.y)
              }
              else if (data.pop6h <= 20) {
                wx_data.y = mix_weather_with_little_pop(wx_data.y)
              }
              break
            }
          }
        }
      }
    }
    return {
      weather_icon: new FWMWeatherIconDataArtist(
        datasets.weather_icon,
        new CBIconChartPaint()
      ),
      thunder_icon: new ThunderIconDataArtist(
        datasets.thunder,
        new CBIconChartPaint({
          icon_size: 30,
          transform: 'translateX(-10%) translateY(-32%)'
        })
      ),
      tx: new BufferDataArtist(
        datasets.tx,
        new CBTxAreaPaint({
          opacity: 0.6,
          fade_required: 0.6
        }),
        {
          max_base_number: 3,
          min_base_number: 10
        }
      ),
      tx_min_max: new CBChartDataArtist(
        datasets.tx_min_max,
        new TempMinMaxPaint()
      ),
      wswd: new WSWDDataArtist(
        datasets.wswd,
        [
          new CBIconChartPaint({
            icon_size: 30
          }),
          new CBWSCirclePaint({
            circle_size: 30
          })
        ]
      ),
      precp: new CBNotNegativeDataArtist(
        datasets.precp,
        new PrecpPaint()
      ),
      pop: new CBNotNegativeDataArtist(
        datasets.pop,
        new CBTextChartPaint({
          format: v => v + '%',
          class_name: 'text-[rgb(48,149,149)]'
        })
      ),
      pres: new CBNotNegativeDataArtist(
        datasets.pres,
        new CBTextChartPaint({
          format: v => v.toFixed(0),
          class_name: 'text-gray-400 font-bold'
        })
      ),
      pres_line: new CBNotNegativeDataArtist(
        datasets.pres,
        new CBLineChartPaint({
          line_dash: [5, 5],
          color: 'rgb(127, 127, 127)'
        })
      ),
    }
  }

  const get_chart_options = () => {
    const chart_data_width = 50

    let clip_width = document.getElementById(chart_id).offsetWidth
    let canvas_width = (dt_to.diffHours(dt_from) +1) * chart_data_width
    if (canvas_width < clip_width) {
      canvas_width = clip_width
    }

    return {
      canvas_size: {
        width: canvas_width,
      },
      canvas_margin: chart_data_width / 2,
      tooltip_required: true,
      axis_x: {
        data_range: [dt_from, dt_to],
        paint: new FWMAxisXPaint({
          tick_format: (v) => v.strftime("%H:00"),
          tick_interval: 60
        }),
      },
      axes_y: {
        weather_icon: {
          canvas_range: 45
        },
        thunder_icon: {
          canvas_range: 45
        },
        tx: {
          data_range: "auto",
          canvas_range: [260, 150],
          format: (v) => { return v + '°C' }
        },
        tx_min_max: {
          canvas_range: 150,
        },
        wswd: {
          canvas_range: 120,
          format: (v) => {
            if (v < 0) {
              return '--'
            }
            return Math.round(v * 3.6) + 'km/h (' + ws_ms_to_beaufort(v) + '級)'
          },
        },
        precp: {
          data_range: [0, 8],
          canvas_range: [100, 25],
          format: (v) => { return v + 'mm' }
        },
        pop: {
          canvas_range: 80,
        },
        pres: {
          canvas_range: 235
        },
        pres_line: {
          data_range: "auto",
          canvas_range: [225, 195]
        }
      }
    }
  }

  const refresh_chart = async (_weather_raw_data, _town_fcst_raw_data) => {
    if (chart) { chart = chart.destroy() }

    await nextTick()

    weather_raw_data = _weather_raw_data? _weather_raw_data : []
    town_fcst_raw_data = _town_fcst_raw_data? _town_fcst_raw_data : []

    // 如果沒資料根本不要畫chart 避免前端出錯
    if (!weather_raw_data.length) { return }
    dt_from = weather_raw_data[0].dtime
    dt_to = weather_raw_data[weather_raw_data.length-1].dtime

    nextTick(() => {
      if (!document.getElementById(chart_id)) {
        console.warn("The chart container does not exist.");
        return
      }

      draw_chart()

      // Set chart offset so that 6:00 is at the beginning if
      // the screen isn't enough to display
      let cb_chart_clip = document.getElementsByClassName("cb_chart-clip")[0]
      let canvas_container = document.getElementsByClassName("cb_chart-canvas_container")[0]
      if (cb_chart_clip.offsetWidth < canvas_container.offsetWidth * 4 / 3) {
        cb_chart_clip.scrollLeft = canvas_container.offsetWidth / 4
      }
    })
  }

  return {
    refresh_chart
  }
}

import { CBTimeChart } from '@/libs/cb-chart/cb_chart.js'
import { CBScaleFix } from '@/libs/cb-chart/cb_scale.js'
import { CBChartDataArtist } from '@/libs/cb-chart/cb_data_artist.js'
import { CBChartPaint, CBBarChartPaint, CBIconChartPaint, CBTextChartPaint, CBLineChartPaint } from '@/libs/cb-chart/cb_chart_paint.js'
import { CBNotNegativeDataArtist, CBTxAreaPaint } from '@/libs/cb-chart/cb_weather_chart.js'

import { ws_ms_to_beaufort, BufferDataArtist, FWMWeatherIconDataArtist, WSWDDataArtist, FWMAxisXPaint } from '@/libs/fwm_chart'
import { CBIconDataArtist } from '../../libs/cb-chart/cb_data_artist'


class FwmWeatherChart extends CBTimeChart {
  _on_mouse_event(e, tooltip, tooltip_point) {
    let target_info = this._get_data_by_xy(e.offsetX, e.offsetY)
    if (target_info) {
      let target_elem = target_info.elem
      let target_data = target_info.data
      if (target_elem === 'pop') {
        return
      }
      if (target_elem || target_data) {
        let format = (v) => { return v }
        if (this.axes_y[target_elem].format) {
          format = this.axes_y[target_elem].format
        }
        tooltip.innerHTML = format(target_data.y)
        tooltip.style.display = 'block'

        let data_x_position = this.scale_x.position(target_data.x)
        let data_y_position = this.scale_y[target_elem].position(target_data.y)

        if (data_y_position < this.scale_y[target_elem].canvas_range[1]) {
          data_y_position = this.scale_y[target_elem].canvas_range[1]
        }

        // --- 微調 hint 所在位置，避免超出 canvas 範圍
        let tooltip_x_position = data_x_position
        let tooltip_y_position = data_y_position < 0? 0 : data_y_position

        // 會超出左緣，顯示在右方
        if (data_x_position < tooltip.clientWidth/2) {
          tooltip_x_position += tooltip.clientWidth/2
        }
        // 會超出右緣，顯示在左方
        else if (data_x_position > this.canvas.clientWidth - tooltip.clientWidth/2) {
          tooltip_x_position -= tooltip.clientWidth/2
        }

        // 會超出上緣，顯示在下方
        if (tooltip_y_position < (tooltip.clientHeight/2 + 12)) {
          tooltip_y_position += tooltip.clientHeight/2 + 12
        }
        // 預設顯示在上方
        else {
          tooltip_y_position -= tooltip.clientHeight/2 + 12
        }

        tooltip.style.left = css_unit_join(tooltip_x_position, 'px')
        tooltip.style.top = css_unit_join(tooltip_y_position, 'px')
        // ---

        // 若是顯示在固定高度的資料，不要顯示 tooltip_point
        if (!(this.scale_y[target_elem] instanceof CBScaleFix)) {
          tooltip_point.style.left = css_unit_join(data_x_position, 'px')
          tooltip_point.style.top = css_unit_join(data_y_position, 'px')
          tooltip_point.style.display = 'block'
        }
        else {
          tooltip_point.style.display = 'none'
        }
      }
    }
    else {
      tooltip.style.display = 'none'
      tooltip_point.style.display = 'none'
    }
  }
}

class CBWSCirclePaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let top = scale_y.position()
    let circle_size = this.options.circle_size || 30

    let ctx = canvas.getContext("2d")
    ctx.globalAlpha = this.options.opacity || 0.9

    if (dataset.length > 0) {
      for (let idx=0; idx<dataset.length; idx++) {
        let data = dataset[idx]
        ctx.beginPath()
        ctx.arc(
          scale_x.position(data.x),
          top,
          circle_size / 2,
          0,
          2 * Math.PI
        )
        ctx.lineWidth = 4
        ctx.strokeStyle = data.color
        ctx.stroke()
        ctx.closePath()
      }
    }
  }
}


class TempMinMaxPaint extends CBTextChartPaint {
  constructor(options={}) {
    options.format = v => v + '℃'
    super(options)
  }

  get_text_div(data) {
    let text_div = super.get_text_div(data)
    text_div.style.fontWeight = 'bold'
    if (data.is_max) {
      text_div.style.color = 'rgb(212, 121, 57)'
    }
    else {
      text_div.style.color = 'rgb(96, 193, 193)'
    }
    return text_div
  }
}

class PrecpPaint extends CBBarChartPaint {
  constructor(options) {
    super(options)
    this.options.bar_width = 40
  }

  draw(dataset, canvas, scale_x, scale_y) {
    let ctx = canvas.getContext("2d")

    let gradient = ctx.createLinearGradient(0, scale_y.canvas_range[0], 0, scale_y.canvas_range[1])

    gradient.addColorStop(0, 'rgba(96, 193, 193, 0.2)')
    gradient.addColorStop(1, 'rgba(96, 193, 193, 0.4)')
    if (dataset.length > 0) {
      ctx.beginPath()
      ctx.fillStyle = gradient
      let bar_width = this.options.bar_width

      for (let data of dataset) {
        ctx.fillRect(
          (scale_x.position(data.x) - bar_width / 2),
          scale_y.position(data.y),
          bar_width,
          scale_y.canvas_range[0] - scale_y.position(data.y)
        )
      }
      ctx.closePath()
    }

    return this
  }
}
class ThunderIconDataArtist extends CBIconDataArtist {
  constructor(dataset, paint) {
    super(dataset, paint)
  }

  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (raw_data.y !== undefined) {
          dataset.push({
            x: raw_data.x,
            y: raw_data.y,
            icon: require('@/assets/imgs/weather_icon/thunder--chart2.png')
          })
        }
      }
    }
    return dataset
  }
}
