<template>
  <label class="flex items-center">
    <!-- 即使外部不傳入 checked，這邊將 checked = undefined 傳入原生 input 時，還是會當作 checked = false，進而影響 modelValue 傳入的初始值。因此這邊加 v-if 整個擋掉 -->
    <template v-if="checked === undefined">
      <input
        type="radio"
        class="peer hidden"
        v-model="selected"
        :value="value"
        :disabled="disabled"
        @click="check_clear"
      />
    </template>
    <template v-else>
      <input
        type="radio"
        class="peer hidden"
        v-model="selected"
        :value="value"
        :checked="checked"
        :disabled="disabled"
        @click="check_clear"
      />
    </template>
    <slot>
      <div :class="checkbox_class"></div>
      <span :class="text_class">
        {{ text !== null ? text : value ? value : '預設文字 (:text)' }}
      </span>
    </slot>
  </label>
</template>

<script>
import { computed } from 'vue'

/**
 * 用法：
 * <cb-radio><cb-radio>
 * 用法幾乎和原生 radio 一樣
 */
export default {
  props: {
    modelValue: {},
    value: {},
    checked: {},
    disabled: {},

    // 前面核取方塊的樣式
    checkbox_class: {
      type: String,
      default: 'checkbox_primary',
    },

    // 預設文字
    text: {
      type: String,
      default: null,
    },

    // text 樣式
    text_class: {
      type: String,
      default:
        'text-primary cursor-pointer select-none peer-disabled:cursor-not-allowed peer-disabled:text-gray-400',
    },

    // 是否要讓單選框可再次點擊以清除已選
    clear_required: {
      type: Boolean,
      default: false,
    },
  },

  emit: ['update:modelValue'],

  setup(props, { emit }) {
    const selected = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val)
      },
    })

    const check_clear = () => {
      if (props.clear_required && props.modelValue === props.value) {
        emit('update:modelValue', null)
      }
    }

    return {
      selected,
      check_clear,
    }
  },
}
</script>
