import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { RadarRepo } from '@/repositories/radar'


export const useRadarStore = (LayerStore) => {
  const state = reactive({
    radar_data: null,
    recent_radar_datas: null
  })

  const refetch_radar_data = () => {
    // 給 AppVState.fetch_all() 呼叫，檢查若已要過資料才再去撈資料
    if (state.radar_data) {
      get_radar_data()
    }
  }

  const get_radar_data = async () => {
    set_loading(true)
    // Reset recent_radar_datas, this will also stop the current radar animation
    state.recent_radar_datas = null

    try {
      let payload = await RadarRepo.radar.get()
      state.radar_data = markRaw(payload.data)
      set_disabled(false)
      get_recent_radar_datas()
    } catch (error) {
      console.warn(error)
      state.radar_data = null
      set_disabled(true)
      set_checked(false)
    }
    set_loading(false)
  }

  const get_recent_radar_datas = async () => {
    set_loading(true)
    try {
      let payload = await RadarRepo.recent_radar.get()
      state.recent_radar_datas = markRaw(payload.data)
    }
    finally {
      set_loading(false)
    }
  }

  const set_loading = (val) => LayerStore.set_loading(val, 'radar')
  const set_disabled = (val) => LayerStore.set_disabled(val, 'radar')
  const set_checked = (val) => LayerStore.set_checked(val, 'radar')

  watch(() => LayerStore.state.checked_layers.radar, (is_checked) => {
    if (is_checked && !state.radar_data) {
      get_radar_data()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    refetch_radar_data  // for focus refresh
  }

  provide('RadarStore', store)

  return store
}
