<template>
  <button
    class="flex items-center justify-center gap-2"
    :class="class"
    :loading="loading"
    :disabled="disabled || loading"
  >
    <template v-if="!loading_replace_content || !loading">
      <slot>
        預設文字
      </slot>
    </template>
    <div v-show="loading" class="w-4 h-4 border-4 rounded-full border-gray-50 border-t-blue-400 animate-spin"></div>
  </button>
</template>

<script>
export default {
  props: {
    class: {
      type: String,
      default: 'btn_primary'
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading_replace_content: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>


</style>
