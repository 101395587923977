<template>
  <div
    class="flex h-[44px] bg-title flex items-center"
    >
    <div
      class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
      @click="DetailStore.set_station_visible(feature.unique_key, !total_station_visible[feature.unique_key])"
      :class="total_station_visible[feature.unique_key] ? 'icon-symbol_subtract' : 'icon-symbol_add'"
      >
    </div>
    <img class="ml-4 h-7 w-7" :src="get_icon_class(feature.type_en)" />
    <div class="w-full my-4 mx-2">
      {{ feature.name }}&nbsp;{{ last_obs_stage }}
    </div>
    <div
      class="mr-4 icon-delete cursor-pointer"
      @click="DetailStore.delete_feature(feature.unique_key)"
    ></div>
  </div>
  <div class="relative">
    <div v-show="total_station_loading[feature.unique_key]" class="blink_loading"></div>
    <div
      v-show="total_station_visible[feature.unique_key]"
      :id="feature.unique_key + '_station_stage_container'"
      class=" relative w-full h-[300px] overflow-x-auto my-4"
    >
      <canvas
        :id="feature.unique_key + '_station_stage_chart'"
        width="1400"
        height="260"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import { Tooltip } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { ref, watch, inject, nextTick, computed, onBeforeUnmount, onMounted } from 'vue'

Chart.register(annotationPlugin)

// Consider stage data below -900m as invalid value
const INVALID_VALUE = -900


export default {
  name: 'DetailStationStageChart',

  props: ['feature'],
  setup(props) {
    const feature = props.feature
    const DetailStore = inject('DetailStore')
    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const total_station_loading = computed(() => DetailStore.state.total_station_loading)

    let chart = null

    const station_stage_data = computed({
      get: () => DetailStore.state.total_station_data.find(station_data => station_data.unique_key === feature.unique_key)?.data
    })

    const last_obs_stage = computed(() => {
        let obs_stage_data = station_stage_data
        if (
          obs_stage_data &&
          obs_stage_data.value.obs &&
          obs_stage_data.value.obs.length > 0
        ) {
          const {
            is_display,
            last_obs_dtime,
            last_obs_stage,
          } = check_obs_stage_valid_within_3hrs(obs_stage_data.value.obs)
          if (is_display) {
            // 超過 3 小時以前就不顯示了
            return `-- ${last_obs_stage}m (${last_obs_dtime.strftime('%H:%M')})`
          }
          return '-- 資料中斷'
        }
        return '--'
      },
    )

    watch(
      [station_stage_data, () => total_station_visible.value[feature.unique_key]], async ([station_stage, visible]) => {
        if (visible) {
          if (station_stage) {
            await nextTick()
            draw_chart(station_stage)

            // 手機版的水位資料預設到今天的位置
            // 因每站的水位資料長度不同，所以要找到今天的資料是在obs資料裡的第幾個
            // ratio = 第幾個/總長度，然後設定scrollLeft到這個位置
            let station_stage_container = document.getElementById(
              `${feature.unique_key}_station_stage_container`
            )
            let station_stage_chart = document.getElementById(
              `${feature.unique_key}_station_stage_chart`
            )

            let obs = station_stage?.obs
            if (obs) {
              let today_index = obs.findIndex(function(item) {
                return item.dtime.getDate() === new Date().getDate()
              })
              let ratio = today_index / obs.length
              station_stage_container.scrollLeft =
                station_stage_chart.offsetWidth * ratio
            }
          }
        }
      },{
        deep: true,
      }
    )

    // 確認3小時內的水位資料是否包含有效值，並找出最新有效觀測時間對應的水位。
    const check_obs_stage_valid_within_3hrs = (datas) => {
      for (let i = datas.length - 1; i >= 0; i--) {
        if (datas[i].dtime > new Date().addHours(-3)) {
          if (datas[i].stage > INVALID_VALUE) {
            return {
              is_display: true,
              last_obs_dtime: datas[i].dtime,
              last_obs_stage: datas[i].stage,
            }
          }
        } else {
          return {
            is_display: false,
            last_obs_dtime: null,
            last_obs_stage: null,
          }
        }
      }
    }

    const draw_chart = (datas) => {
      if (document.getElementById(`${feature.unique_key}_station_stage_chart`) === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 station_stage_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 station_stage_chart element 不存在的狀況，防止 console 跳錯。
        return
      }
      // 畫圖前須先清空 chart
      if (chart !== null) {
        chart.destroy()
      }
      // 將chart清空之後 看datas裡面是否有obs 若沒有就不畫chart 避免 chart js 出錯
      if (!datas.obs?.length) {
        return
      }

      let ctx = document.getElementById(`${feature.unique_key}_station_stage_chart`).getContext('2d')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth

      let mode = {
        type: 'line',
        borderColor: 'black',
        borderWidth: 1.5,
        scaleID: 'y',
        value: get_chart_stage_mode(),
        borderDash: [6, 6],
      }

      let warning_lv1 = {
        type: 'line',
        borderColor: '#EA0000',
        borderWidth: 2,
        scaleID: 'y',
        value: feature.warning_lv1,
      }

      let warning_lv2 = {
        type: 'line',
        borderColor: '#FF8000',
        borderWidth: 2,
        scaleID: 'y',
        value: feature.warning_lv2,
      }

      let warning_lv3 = {
        type: 'line',
        borderColor: '#F9F900',
        borderWidth: 2,
        scaleID: 'y',
        value: feature.warning_lv3,
      }

      let obs_data = _get_chart_obs_data()

      let config = {
        type: 'line',
        data: _get_chart_data(),

        options: {
          layout: {
            padding: {
              left: 10,
            },
          },
          responsive: false,
          interaction: {
            intersect: false,
          },
          plugins: {
            tooltip: {
              caretSize: 0,
              position: 'custom',
              callbacks: {
                title: () => {
                  return ''
                },
                label: function(tooltipItem) {
                  let time = new Date(tooltipItem.label).strftime('%H:%M')
                  let stage = tooltipItem.formattedValue
                  return `${time} ${stage}m`
                },
              },
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                mode,
                warning_lv1,
                warning_lv2,
                warning_lv3,
              },
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: 'time',
              time: {
                displayFormats: {
                  day: 'MM/DD ddd',
                },

                unit: 'day',
              },
            },
            y: {
              suggestedMin: _get_yaxis_min(obs_data),
              suggestedMax: _get_yaxis_max(obs_data),
              grid: {
                color: '#ccc',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              position: 'right',
              stacked: true,
            },
          },
        },
      }

      define_tooltips_position()
      DetailStore.set_chart_loading(feature.unique_key, false)
      chart = new Chart(ctx, config)
    }

    const define_tooltips_position = () => {
      Tooltip.positioners.custom = function(elements, position) {
        if (!elements.length) {
          return false
        }
        return {
          x: position.x,
          y: 0,
        }
      }
    }

    const _get_chart_data = () => {
      let datasets = []
      let options = {
        obs: {
          backgroundColor: '#4285F4',
          borderColor: '#4285F4',
        }
      }
      if (station_stage_data.value.obs && station_stage_data.value.obs.length) {
        datasets.push({
          tension: 0.5, // Bezier curve tension of the line(value during 0~1)
          backgroundColor: options.obs.backgroundColor,
          borderColor: options.obs.borderColor,
          data: _get_chart_obs_data(),
          fill: false,
          pointRadius: 0,
          borderWidth: 3,
          pointHitRadius: 5,
        })
      }
      return {
        datasets: datasets,
      }
    }

    const _get_chart_obs_data = () => {
      let data = []
      if (station_stage_data.value.obs.length) {
        station_stage_data.value.obs.forEach((obs) => {
          if (obs.stage > INVALID_VALUE) {
            data.push({
              x: obs.dtime,
              y: obs.stage,
            })
          }
        })
      }
      return data
    }

    const get_chart_stage_mode = () => {
      let mode = null
      if (station_stage_data.value.stage_mode) {
        mode = station_stage_data.value.stage_mode
      }
      return mode
    }

    const _get_yaxis_min = (datas) => {
      let stage_mode = station_stage_data.value.stage_mode
      let warning_lv1 = feature.warning_lv1
      let warning_lv2 = feature.warning_lv2
      let warning_lv3 = feature.warning_lv3
      let total_stage_list = [
        warning_lv1,
        warning_lv2,
        warning_lv3,
        stage_mode,
      ]

      // 將觀測與計算水位的最小值進行比較，取得y軸的最小值
      let min_value = null
      if (datas.length) {
        total_stage_list.push(datas.reduce((a, b) => (a.y < b.y ? a : b)).y)
      }

      // 年平均水位需求恆顯示， 所以再跟最小值做比較 如果小於最小值就取代
      if (total_stage_list.length) {
        let _total_stage_list = total_stage_list.filter((x) => x)
        min_value = Math.min(..._total_stage_list)
      }

      if (min_value !== null) {
        let float_value = min_value % 1
        if (min_value > 0) {
          return min_value - float_value
        } else {
          let mod = 1 + float_value
          return min_value - mod
        }
      }
      return 0
    }

    const _get_yaxis_max = (datas) => {
      let stage_mode = station_stage_data.stage_mode
      // 將觀測與計算水位的最大值進行比較，取得y軸的最大值
      let max_value = null
      if (datas.length) {
        max_value = datas.reduce((a, b) => (a.y > b.y ? a : b)).y
      }
      // 年平均水位需求恆顯示，所以再跟最大值比較 如果超過最大值就取代
      if (max_value < stage_mode) {
        max_value = stage_mode
      }

      if (max_value !== null) {
        let float_value = max_value % 1
        let mod = 1 - float_value
        return max_value + mod
      }
      return 0
    }


    onBeforeUnmount(() => {
      // chart 須跟著元件被清空
      if (chart !== null) {
        chart.destroy()
      }
    })

    const get_icon_class = (type) => {
      if (type === 'pin') {
        return require('@/assets/imgs/map_pin--click.png')
      } else if (type === 'cctv') {
        return require('@/assets/imgs/CCTV.png')
      } else if (type === 'tidal') {
        return require('@/assets/imgs/tidal.png')
      } else if (type === 'water_station') {
        return require('@/assets/imgs/water_stage.png')
      } else if (type === 'rain_station') {
        return require('@/assets/imgs/precipitation.png')
      } else if (type === 'tide') {
        return require('@/assets/imgs/tide_station.png')
      } else if (type === 'buoy') {
        return require('@/assets/imgs/buoy.png')
      }
    }

    return {
      DetailStore,
      feature,
      total_station_visible,
      total_station_loading,
      last_obs_stage,
      get_icon_class,
      station_stage_data
    }
  }
}
</script>

<style lang="postcss" scoped>

</style>
