import { CBScaleFix } from './cb_scale'

class CBAxisPaint {
  constructor(options) {
    this.options = Object.deepAssign({}, options)
    this.container = null

    if (this.options.container_id) {
      let axis_space = document.getElementById(this.options.container_id)
      if (axis_space) {
        this.set_container(axis_space)
      }
      else {
        console.warn(`No dom element with this id '${this.options.container_id}'.`);
      }
    }
  }

  destroy() {
    if (this.container) {
      this.container.innerHTML = ""
    }
  }

  set_container(container) {
    this.container = container
    this.container.classList.add('cb_chart-axis_container')
    return this
  }

  draw(scale, canvas) {
    if (scale) {
      if (scale instanceof CBScaleFix) {
        console.debug('CBScaleFix should not be drawn in axis_paint')
        return
      }

      if (this.container == null) {
        this.container = canvas.parentNode
      }

      let tick_values = this.options.tick_values
      if (!tick_values) {
        tick_values = scale.paint_tick_list(this.options)
      }

      for (let tick_value of tick_values) {
        this.draw_single_tick(scale, tick_value)
      }
    }
    return this
  }

  draw_single_tick(scale, tick_value) {
    let text_div = document.createElement('div')

    this.place_tick_value(text_div, css_unit_join(scale.position(tick_value)))

    let tick_format = this.options.tick_format
    if (!tick_format) tick_format = (v) => v
    text_div.innerHTML = tick_format(tick_value)

    this.container.appendChild(text_div)
  }
}


class CBAxisXPaint extends CBAxisPaint {
  place_tick_value(text_div, left_position) {
    if (this.options.text_align === 'top') {
      text_div.style.top = '0px'
    }
    else if (this.options.text_align === 'bottom') {
      text_div.style.bottom = '0px'
    }
    text_div.style.left = left_position
    text_div.style.transform = 'translateX(-50%)'
    text_div.className = 'cb_chart-text_div'
  }
}


class CBAxisYPaint extends CBAxisPaint {
  place_tick_value(text_div, position) {
    if (this.options.text_align === 'left') {
      text_div.style.left = '5px'
    }
    else if(this.options.text_align === 'right') {
      text_div.style.right = '5px'
    }
    text_div.style.top = position
    text_div.style.transform = 'translateY(-50%)'
    text_div.className = 'cb_chart-text_div'
  }
}

export {
  CBAxisXPaint, CBAxisYPaint
}
