/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"


export const MarkerCollectionsRepo = {
  marker_collections: {
    get: (params) => req("get", "v6/marker_collections/", params),
    post: (data) => req('post', "v6/marker_collections/", data),
    patch: (id, data) => req('patch', `v6/marker_collections/${id}/`, data),
    delete: (id) => req('delete', `v6/marker_collections/${id}/`),
  }
}