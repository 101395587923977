<template>
  <div class="my-2 text-center">
    <!-- 顯示雨量測站名稱與即時資料 -->
    {{ selected_target.name }}&nbsp;{{ obs_pccu_24h }}
  </div>
  <div class="relative">
    <div v-show="is_getting_rain_obs_data" class="blink_loading"></div>
    <div id="rain_container" class=" relative w-full h-[300px] overflow-x-auto">
      <canvas
        id="rain_chart"
        width="1400"
        height="260"
        class=" my-4 mx-2"
      ></canvas>
    </div>
  </div>
</template>

<script>
import { watch, inject, nextTick, computed, onBeforeUnmount } from 'vue'
import Chart from 'chart.js/auto'
import { Tooltip } from 'chart.js'
import 'chartjs-adapter-moment'
import annotationPlugin from 'chartjs-plugin-annotation'

Chart.register(annotationPlugin)

export default {
  name: 'RainChart',

  setup(props) {
    const LayerStore = inject('LayerStore')
    const RainStationStore = inject('RainStationStore')

    let chart = null
    const selected_target = computed(() => LayerStore.state.selected_target)
    const is_getting_rain_obs_data = computed(
      () => RainStationStore.state.is_getting_rain_obs_data
    )
    const rain_obs_data = computed(() => RainStationStore.state.rain_obs_data)
    const obs_pccu_24h = computed(() => {
      let obs_rain_data = RainStationStore.state.rain_obs_data
      if (obs_rain_data && obs_rain_data.length > 0) {
        let last_obs = obs_rain_data[obs_rain_data.length - 1]
        if (last_obs.dtime > new Date().addHours(-3)) {
          let obs_data_24h = obs_rain_data.filter(obs_data => obs_data.dtime >= new Date().addHours(-24))
          let obs_pccu_24h = obs_data_24h.map(obj => obj.precp).reduce((a,b) => a+b)
          return `-- ${obs_pccu_24h}mm / 24hr`
        }
        // 超過 3 小時以前就不顯示了
        return '-- 資料中斷'
      }
      return '--'
    })
    const draw_chart = (datas) => {
      gtag('event', 'view_rain_chart')

      const get_x_gridline = () => {
        let gridline = {}
        const dates = datas
          .filter((_) => _.dtime.getHours() === 0)
          .map((_) => _.dtime)
        for (let date of dates) {
          gridline[date.strftime('%Y%m%d')] = {
            type: 'line',
            borderColor: '#d3d3d3',
            borderWidth: 1,
            scaleID: 'x',
            value: date.toString(),
          }
        }
        return gridline
      }

      if (document.getElementById('rain_chart') === null) {
        // X: 當快速切換不同站點時（e.x. 雨量／潮位），有可能因為 rain_chart 已經被消滅，
        //    但 draw_chart() 仍被執行，導致 getElement 回傳 null 進而使 getContext 失敗，
        //    這裡先處理 rain_chart element 不存在的狀況，防止 console 跳錯。
        return
      }
      // 畫圖前須先清空 chart
      if (chart !== null) {
        chart.destroy()
      }

      let ctx = document.getElementById('rain_chart').getContext('2d')
      let background = ctx.createLinearGradient(0, 0, 0, 300)
      background.addColorStop(0, 'rgba(96,193,193,0.8)')
      background.addColorStop(1, 'rgba(96,193,193,0.3)')
      ctx.canvas.style.width = ctx.canvas.parentNode.offsetWidth

      let config = {
        type: 'bar',
        data: get_chart_data(background),

        options: {
          layout: {
            padding: {
              left: 10,
            },
          },
          responsive: false,
          interaction: {
            intersect: false,
          },
          plugins: {
            tooltip: {
              caretSize: 0,
              position: 'custom',
              callbacks: {
                title: () => {
                  return ''
                },
                label: function(tooltipItem) {
                  let time = new Date(tooltipItem.label).strftime('%H:%M')
                  let obs = tooltipItem.formattedValue
                  return `${time} ${obs}mm`
                },
              },
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: get_x_gridline(),
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              // 因為bar chart的x gridline會畫在12:00，所以這邊關掉手動自己畫
              grid: {
                display: false,
              },
              type: 'time',
              time: {
                displayFormats: {
                  day: 'MM/DD ddd',
                },
                unit: 'day',
              },
              barThickness: 6,
            },
            y: {
              grid: {
                color: '#ccc',
                display: true,
                drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              },
              position: 'right',
              max: (function() {
                let _maxy = _get_yaxis_max_label()
                if (_maxy < 10) {
                  _maxy = 10
                } else if (_maxy > 10) {
                  // 讓軸線好看, 避免擠在一起
                  _maxy = Math.ceil(_maxy / 2) * 2
                }
                return _maxy
              })(),
            },
          },
        },
      }
      define_tooltips_position()
      chart = new Chart(ctx, config)
    }

    const _get_xaxis_min_label = () => {
      if (rain_obs_data.value && rain_obs_data.value.length) {
        // 雨量長條圖抓第一筆觀測資料時間
        let first_obs_dt = rain_obs_data.value[0].dtime
        return first_obs_dt
      }
      // 沒資料的話，取當天零時，避免後面程序出錯
      return new Date().zero_hour()
    }

    const _get_xaxis_max_label = () => {
      if (rain_obs_data.value && rain_obs_data.value.length) {
        // 抓最後一筆觀測時間當 max_label
        let last_obs_dt =
          rain_obs_data.value[rain_obs_data.value.length - 1].dtime
        return last_obs_dt
      }
      // 沒資料的話，抓現在時間，避免後面程序出錯
      return new Date()
    }

    const _get_yaxis_max_label = () => {
      if (rain_obs_data.value && rain_obs_data.value.length) {
        return Math.max(..._get_chart_data().map((value) => value.y))
      }
      return 0
    }

    const get_chart_data = (backgroundColor) => {
      let datasets = []
      if (rain_obs_data.value && rain_obs_data.value.length) {
        datasets.push({
          backgroundColor: backgroundColor,
          hoverBackgroundColor: 'rgb(246,180,85)',
          data: _get_chart_data(),
          fill: false,
          borderWidth: 0,
        })
      }
      return {
        datasets: datasets,
      }
    }

    const _get_chart_data = () => {
      let data = []
      if (rain_obs_data.value && rain_obs_data.value.length) {
        rain_obs_data.value.forEach((obs) => {
          data.push({
            x: obs.dtime,
            y: obs.precp < 0 ? 0 : obs.precp,
          })
        })
      }
      return data
    }

    const define_tooltips_position = () => {
      Tooltip.positioners.custom = function(elements, position) {
        if (!elements.length) {
          return false
        }
        return {
          x: position.x,
          y: 0,
        }
      }
    }

    const set_chart_position = (datas) => {
      let rain_container = document.getElementById('rain_container')
      let rain_chart = document.getElementById('rain_chart')

      let today_index = datas.findIndex(function(item) {
        return item.dtime.getDate() === new Date().getDate()
      })
      let ratio = today_index / datas.length
      rain_container.scrollLeft = rain_chart.offsetWidth * ratio
    }

    watch(
      () => RainStationStore.state.rain_obs_data,
      async (datas) => {
        await nextTick
        draw_chart(datas)
        set_chart_position(datas)
      }
    )

    onBeforeUnmount(() => {
      // chart 須跟著元件被清空
      if (chart !== null) {
        chart.destroy()
      }
    })

    /** 模板有使用到的需 return 出去 */
    return {
      is_getting_rain_obs_data,
      selected_target,
      obs_pccu_24h,
    }
  },
}
</script>
