import { watch, computed, ref } from 'vue'
import { round_to_five } from '../../libs/utils'

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `pin,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `pin,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}


export const usePinVLayer = (map, LayerStore, PinStore, MapStore, FavoritesStore, SearchingStore) => {
  const tmp_icon = L.icon({
    iconUrl: require('@/assets/imgs/map_pin--focus.png'),
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  })
  const unactive_icon = L.icon({
    iconUrl: require('@/assets/imgs/map_pin--normal.png'),
    iconSize: [48, 48],
    iconAnchor: [24, 48],
  })
  const active_icon = L.icon({
    iconUrl: require('@/assets/imgs/map_pin--click.png'),
    iconSize: [52, 52],
    iconAnchor: [26, 52],
  })
  const unactive_icon_in_favorites = L.icon({
    iconUrl: require('@/assets/imgs/map_pin--normal.png'),
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    shadowUrl: require('@/assets/imgs/love--click.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 45],
  })
  const active_icon_in_favorites = L.icon({
    iconUrl: require('@/assets/imgs/map_pin--click.png'),
    iconSize: [52, 52],
    iconAnchor: [26, 52],
    shadowUrl: require('@/assets/imgs/love--click.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 45],
  })

  const marker_layer_map = new Map()

  let tmp_marker = null
  const remove_tmp_marker = () => {
    if (tmp_marker) {
      map.removeLayer(tmp_marker)
    }
  }

  let selected_marker = ref(null)
  watch(selected_marker, (new_marker, old_marker) => {
    if (old_marker) {
      refresh_favorites_icons(old_marker, false)
    }
    if (new_marker) {
      refresh_favorites_icons(new_marker, true)
    }
  }, {
    immediate: true
  })

  const add_temp_pin = (latlon) => {
    if (tmp_marker) {
      // 地圖上一次只會有一個 temp_marker
      remove_tmp_marker()
    }
    let _marker = L.marker({
      lat: latlon.lat,
      lng: latlon.lon
    }, {
      icon: tmp_icon,
      shadowPane: 'favoritesPane',
    }).on('click', (e) => {
      // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
      if (!e.originalEvent?.isTrusted) return
      on_marker_click(e.target, true)
    }).addTo(map)

    tmp_marker = _marker
  }

  const add_pin_marker = (pin) => {
    let _marker = L.marker({
      lat: pin.lat,
      lon: pin.lon
    }, {
      icon: unactive_icon,
      shadowPane: 'favoritesPane',
    }).on('click', (e) => {
      // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
      if (!e.originalEvent?.isTrusted) return
      on_marker_click(e.target, false)
    }).addTo(map)
    // 增加 pin attr 只是為了 在 PinStore 能夠延長 pin 的生存時間
    _marker.pin = pin
    marker_layer_map.set(get_unique_key(pin), _marker)
    return _marker
  }

  const remove_pin_marker = (unique_key) => {
    const layer = marker_layer_map.get(unique_key)
    if (layer) {
      if (layer === selected_marker.value) {
        selected_marker.value = null
      }
      map.removeLayer(layer)
      marker_layer_map.delete(unique_key)
    }
  }

  const draw_record_pins = (pins) => {
    for (let pin of pins) {
      add_pin_marker(pin)
    }
  }

  const on_marker_click = (marker, click_temp) => {
    const latlng = marker.getLatLng()
    const latlon = {
      lat: round_to_five(latlng.lat),
      lon: round_to_five(latlng.lng)
    }

    if (click_temp) {
      PinStore.create_record_pin(latlon)
      selected_marker.value = add_pin_marker(latlon)
    }
    else {
      PinStore.refresh_pin_effective_time(marker.pin)
      selected_marker.value = marker
    }
    remove_tmp_marker()

    const unique_key = get_unique_key(latlon)
    let name = `自選點 ${latlon.lat.toFixed(5)}, ${latlon.lon.toFixed(5)}`
    LayerStore.set_selected_target({
      'type': 'pin',
      'lat': latlon.lat,
      'lon': latlon.lon,
      'name': name,
      unique_key
    })
  }

  const refresh_favorites_icons = (marker, is_active) => {
    const favorites_mapping = FavoritesStore.state.favorites_mapping
    const key = get_unique_key(marker.getLatLng())
    const _marker = marker_layer_map.get(key)
    if (_marker) {
      if (favorites_mapping[key]) {
        if (is_active) {
          _marker.setIcon(active_icon_in_favorites)
        } else {
          _marker.setIcon(unactive_icon_in_favorites)
        }
      } else {
        if (is_active) {
          _marker.setIcon(active_icon)
        } else {
          _marker.setIcon(unactive_icon)
        }
      }
    }
  }

  const get_record_pins = () => PinStore.state.record_pins
  draw_record_pins(get_record_pins())

  const get_favorites = () => FavoritesStore.state.favorites
  watch(get_favorites, (new_favorites, old_favorites) => {
    const favorites_mapping = FavoritesStore.state.favorites_mapping
    for (let key in favorites_mapping) {
      if (key.startsWith('pin')) {
        const marker = marker_layer_map.get(key)
        if (!marker) {
          const latlon = {
            'lat': favorites_mapping[key].lat,
            'lon': favorites_mapping[key].lon
          }
          PinStore.create_record_pin(latlon)
          const new_pin_marker = add_pin_marker(latlon)
          refresh_favorites_icons(new_pin_marker, false)
        } else {
          const same_marker_flag = selected_marker.value ?
            marker.getLatLng().equals(selected_marker.value.getLatLng()) : false

          refresh_favorites_icons(marker, same_marker_flag)
        }
      }
    }

    // 確定是哪一個 favorites 被移除，移掉他
    if (new_favorites?.length < old_favorites?.length) {
      const new_favorites_set = new Set(new_favorites)
      const difference_favorite = old_favorites.filter(x => !new_favorites_set.has(x))

      difference_favorite.forEach(favorite => {
        PinStore.remove_record_pin({
          lat: favorite.lat,
          lon: favorite.lon
        })
        remove_pin_marker(favorite.unique_key)
      })
    }
  }, {
    immediate: true
  })

  watch(() => LayerStore.state.selected_target, (selected_target) => {
    if (selected_target.type === 'pin') {
      selected_marker.value = marker_layer_map.get(selected_target.unique_key)
    }
  })

  watch(() => PinStore.state.temp_point, (point) => {
    add_temp_pin(point)
  })

  map.on('click', (e) => {
    // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
    if (!e.originalEvent?.isTrusted) return
    add_temp_pin({lat: e.latlng.lat, lon: e.latlng.lng})
  })
}
