import { reactive, readonly, provide, watch, markRaw } from 'vue'

import { WindyRepo } from '@/repositories/windy'


export const useWindyStore = (LayerStore) => {
  const state = reactive({
    windy_data: null
  })

  const refetch_windy_data = () => {
    // 給 AppVState.fetch_all() 呼叫，檢查若已要過資料才再去撈資料
    if (state.windy_data) {
      get_windy_data()
    }
  }

  const get_windy_data = async () => {
    set_loading(true)
    try {
      let payload = await WindyRepo.windy.get()
      state.windy_data = markRaw(payload.data)
    } catch (error) {
      console.warn(error)
      state.windy_data = null
    }
    set_loading(false)
  }

  const set_loading = (val) => LayerStore.set_loading(val, 'windy')

  watch(() => LayerStore.state.checked_layers.windy, (is_checked) => {
    if (is_checked && !state.windy_data) {
      get_windy_data()
    }
  }, {
    immediate: true
  })

  const store = {
    state: readonly(state),
    refetch_windy_data  // for focus refresh
  }

  provide('WindyStore', store)

  return store
}
