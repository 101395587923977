<template>
  <div>
    <Modal
      v-model:visible="confirm_visible"
      :content="content"
      :html_content="html_content"
      @confirm="user_click"
      :cancel_btn_required="dialog_type === 'confirm'"
      :mask_penetrable="false"
    >
      <template v-slot:header>
        <div v-if="html_title" v-html="html_title"></div>
        <div
          v-else
          class="text-lg font-bold tracking-wider"
        >
          <i
            v-if="title_icon_class"
            class=" mr-1 inline-block align-middle"
            :class="title_icon_class"
          >
          </i>
          <i
            v-else-if="title_icon_required"
            class=" mr-1 inline-block align-middle"
            :class="dialog_type === 'confirm'? 'icon-warning text-red-500' : 'icon-info text-blue-400'"
          >
          </i>
          <h3 class=" inline-block align-middle">
            {{ title }}
          </h3>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'

export default {
  name: 'Dialog',

  components: {
    Modal
  },

  // 目前只能改title, content，之後需要改其他的再接
  props: {
    // 標題
    title: {
      type: String,
      default: '標題'
    },
    // html格式的標題
    html_title: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度： html_title > title

    // 內容
    content: {
      type: String,
      default: null
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度： html_content > content

    // dialog類型, "confirm" | "alert"
    dialog_type: {
      type: String,
      default: 'confirm'
    },

    // 是否需要在標題前顯示 icon
    title_icon_required: {
      type: Boolean,
      default: true
    },

    // 標題前的 icon class，應傳入 font icon class
    title_icon_class: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      confirm_visible: false,
      promise_status: null,
    }
  },

  methods: {
    launch() {
      this.confirm_visible = true

      let _this = this
      return new Promise((resolve, reject) => {
        _this.promise_status = {resolve, reject}
      })
    },

    user_click(ok) {
      if (ok) {
        if (this.promise_status) {
          this.promise_status.resolve()
        }
      }
      else {
        if (this.promise_status) {
          this.promise_status.reject()
        }
      }
      this.$nextTick(() => {
        this.$unmount()
      })
    }
  }
}
</script>
