/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"

export const ReferenceRepo = {
  legal_fishing_port: {
    get: () => req("get", 'legal_fishing_port/')
  }
}
