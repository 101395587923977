
const WeatherIconTable =[
  {
    "desc": ["晴天", "晴"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_clear.png')
  }, {
    "desc": ["晴時多雲", "晴有雲"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear.png')
  }, {
    "desc": ["多雲時晴"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear.png')
  }, {
    "desc": ["晴時多雲偶陣雨", "多雲時晴短暫陣雨", "多雲時晴短暫雨", "晴時多雲短暫陣雨", "晴短暫陣雨", "晴午後陰短暫雨", "晴午後陰短暫陣雨", "晴時多雲陣雨", "多雲時晴陣雨", "多雲短暫雨"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear_with_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear_with_shower.png')
  }, {
    "desc": ["多雲"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_partly_cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_partly_cloudy.png')
  }, {
    "desc": ["多雲時陰"],
    "icon_img": require('@/assets/imgs/weather_icon/cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/cloudy.png')
  }, {
    "desc": ["陰時多雲"],
    "icon_img": require('@/assets/imgs/weather_icon/cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/cloudy.png')
  }, {
    "desc": ["陰天", "陰"],
    "icon_img": require('@/assets/imgs/weather_icon/cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/cloudy.png')
  }, {
    "desc": ["多雲陣雨", "多雲短暫陣雨", "午後短暫陣雨", "短暫陣雨", "短暫雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["多雲時陰短暫雨", "多雲時陰短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["陰時多雲短暫雨", "陰時多雲短暫陣雨", "陰短暫雨", "陰短暫陣雨", "陰午後短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    // Irene 2020/11/25 新增小雨、中雨、大雨
    "desc": ["大雨", "雨天", "陣雨", "有雨", "午後陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png')
  }, {
    "desc": ["中雨"],
    "icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png')
  }, {
    "desc": ["小雨", "毛毛雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["多雲時陰有雨", "多雲時陰陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["陰時多雲有雨", "陰時多雲有陣雨", "陰時多雲陣雨", "陰時多雲午後短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png')
  }, {
    "desc": ["陰有雨", "陰有陣雨", "陰雨", "陰陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png')
  }, {
    "desc": ["多雲陣雨或雷雨", "多雲短暫陣雨或雷雨", "多雲短暫雷陣雨", "多雲雷陣雨", "短暫陣雨或雷雨後多雲", "短暫雷陣雨後多雲", "短暫陣雨或雷雨", "晴時多雲短暫陣雨或雷雨", "晴短暫陣雨或雷雨", "多雲時晴短暫陣雨或雷雨", "午後短暫雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲時陰陣雨或雷雨", "多雲時陰短暫陣雨或雷雨", "多雲時陰短暫雷陣雨", "多雲時陰雷陣雨", "晴陣雨或雷雨", "晴時多雲陣雨或雷雨", "多雲時晴陣雨或雷雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["陰時多雲有雷陣雨", "陰時多雲陣雨或雷雨", "陰時多雲短暫陣雨或雷雨", "陰時多雲短暫雷陣雨", "陰時多雲雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["陰有陣雨或雷雨", "陰有雷陣雨", "陰陣雨或雷雨", "陰雷陣雨", "晴午後陰短暫陣雨或雷雨", "晴午後陰短暫雷陣雨", "陰短暫陣雨或雷雨", "陰短暫雷陣雨", "雷雨", "陣雨或雷雨後多雲", "陰陣雨或雷雨後多雲", "陰短暫陣雨或雷雨後多雲", "陰短暫雷陣雨後多雲", "陰雷陣雨後多雲", "雷陣雨後多雲", "陣雨或雷雨", "雷陣雨", "午後雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["晴午後多雲局部雨", "晴午後多雲局部陣雨", "晴午後多雲局部短暫雨", "晴午後多雲局部短暫陣雨", "晴午後多雲短暫雨", "晴午後多雲短暫陣雨", "晴午後局部雨", "晴午後局部陣雨", "晴午後局部短暫雨", "晴午後局部短暫陣雨", "晴午後陣雨", "晴午後短暫雨", "晴午後短暫陣雨", "晴時多雲午後短暫陣雨", "多雲時晴午後短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear_with_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear_with_shower.png')
  }, {
    "desc": ["多雲午後局部雨", "多雲午後局部陣雨", "多雲午後局部短暫雨", "多雲午後局部短暫陣雨", "多雲午後陣雨", "多雲午後短暫雨", "多雲午後短暫陣雨", "多雲時陰午後短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["晴午後多雲陣雨或雷雨", "晴午後多雲雷陣雨", "晴午後陣雨或雷雨", "晴午後雷陣雨", "晴午後多雲局部陣雨或雷雨", "晴午後多雲局部短暫陣雨或雷雨", "晴午後多雲局部短暫雷陣雨", "晴午後多雲局部雷陣雨", "晴午後多雲短暫陣雨或雷雨", "晴午後多雲短暫雷陣雨", "晴午後局部短暫雷陣雨", "晴午後局部雷陣雨", "晴午後短暫雷陣雨", "晴雷陣雨", "晴時多雲雷陣雨", "晴時多雲午後短暫雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲午後局部陣雨或雷雨", "多雲午後局部短暫陣雨或雷雨", "多雲午後局部短暫雷陣雨", "多雲午後局部雷陣雨", "多雲午後陣雨或雷雨", "多雲午後短暫陣雨或雷雨", "多雲午後短暫雷陣雨", "多雲午後雷陣雨", "多雲時晴雷陣雨", "多雲時晴午後短暫雷陣雨", "多雲時陰午後短暫雷陣雨", "陰時多雲午後短暫雷陣雨", "陰午後短暫雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲局部陣雨或雪", "多雲時陰有雨或雪", "多雲時陰短暫雨或雪", "多雲短暫雨或雪", "陰有雨或雪", "陰時多雲有雨或雪", "陰時多雲短暫雨或雪", "陰短暫雨或雪", "多雲時陰有雪", "多雲時陰短暫雪", "多雲短暫雪", "陰有雪", "陰時多雲有雪", "陰時多雲短暫雪", "陰短暫雪", "有雨或雪", "有雨或短暫雪", "陰有雨或短暫雪", "陰時多雲有雨或短暫雪", "多雲時陰有雨或短暫雪", "多雲有雨或短暫雪", "多雲有雨或雪", "多雲時晴有雨或雪", "晴時多雲有雨或雪", "晴有雨或雪", "短暫雨或雪", "多雲時晴短暫雨或雪", "晴時多雲短暫雨或雪", "晴短暫雨或雪", "有雪", "多雲有雪", "多雲時晴有雪", "晴時多雲有雪", "晴有雪", "短暫雪", "多雲時晴短暫雪", "晴時多雲短暫雪", "晴短暫雪"],
    "icon_img": require('@/assets/imgs/weather_icon/snow.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/snow.png')
  }, {
    "desc": ["晴有霧", "晴晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_clear.png')
  }, {
    "desc": ["晴時多雲有霧", "晴時多雲晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear.png')
  }, {
    "desc": ["多雲時晴有霧", "多雲時晴晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear.png')
  }, {
    "desc": ["多雲有霧", "多雲晨霧", "有霧", "晨霧", "多雲時陰有霧", "多雲時陰晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_partly_cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_partly_cloudy.png')
  }, {
    "desc": ["陰有霧", "陰晨霧", "陰時多雲有霧", "陰時多雲晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/cloudy.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/cloudy.png')
  }, {
    "desc": ["多雲局部雨", "多雲局部陣雨", "多雲局部短暫雨", "多雲局部短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["晴午後陰局部雨", "晴午後陰局部陣雨", "晴午後陰局部短暫雨", "晴午後陰局部短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/daytime_mostly_clear_with_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/night_mostly_clear_with_shower.png')
  }, {
    "desc": ["多雲時陰局部雨", "多雲時陰局部陣雨", "多雲時陰局部短暫雨", "多雲時陰局部短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["陰局部雨", "陰局部陣雨", "陰局部短暫雨", "陰局部短暫陣雨", "陰時多雲局部雨", "陰時多雲局部陣雨", "陰時多雲局部短暫雨", "陰時多雲局部短暫陣雨", "陰有霧有陣雨", "陰局部雨有霧", "陰局部陣雨有霧", "陰局部短暫陣雨有霧", "陰時多雲有霧有局部雨", "陰時多雲有霧有局部陣雨", "陰時多雲有霧有局部短暫雨", "陰時多雲有霧有局部短暫陣雨", "陰時多雲有霧有陣雨", "陰時多雲有霧有短暫雨", "陰時多雲有霧有短暫陣雨", "陰時多雲局部雨有霧", "陰時多雲局部陣雨有霧", "陰時多雲局部短暫雨有霧", "陰時多雲局部短暫陣雨有霧", "陰時多雲陣雨有霧", "陰時多雲短暫雨有霧", "陰時多雲短暫雨晨霧", "陰時多雲短暫陣雨有霧", "陰時多雲短暫陣雨晨霧", "陰陣雨有霧", "陰短暫雨有霧", "陰短暫雨晨霧", "陰短暫陣雨有霧", "陰短暫陣雨晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/moderate_rain.png')
  }, {
    "desc": ["多雲有霧有局部雨", "多雲有霧有局部陣雨", "多雲有霧有局部短暫雨", "多雲有霧有局部短暫陣雨", "多雲有霧有陣雨", "多雲有霧有短暫雨", "多雲有霧有短暫陣雨", "多雲局部雨有霧", "多雲局部雨晨霧", "多雲局部陣雨有霧", "多雲局部陣雨晨霧", "多雲局部短暫雨有霧", "多雲局部短暫雨晨霧", "多雲局部短暫陣雨有霧", "多雲局部短暫陣雨晨霧", "多雲陣雨有霧", "多雲短暫雨有霧", "多雲短暫雨晨霧", "多雲短暫陣雨有霧", "多雲短暫陣雨晨霧", "有霧有短暫雨", "有霧有短暫陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["多雲時陰有霧有局部雨", "多雲時陰有霧有局部陣雨", "多雲時陰有霧有局部短暫雨", "多雲時陰有霧有局部短暫陣雨", "多雲時陰有霧有陣雨", "多雲時陰有霧有短暫雨", "多雲時陰有霧有短暫陣雨", "多雲時陰局部雨有霧", "多雲時陰局部陣雨有霧", "多雲時陰局部短暫雨有霧", "多雲時陰局部短暫陣雨有霧", "多雲時陰陣雨有霧", "多雲時陰短暫雨有霧", "多雲時陰短暫雨晨霧", "多雲時陰短暫陣雨有霧", "多雲時陰短暫陣雨晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/light_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/light_rain.png')
  }, {
    "desc": ["多雲局部陣雨或雷雨", "多雲局部短暫陣雨或雷雨", "多雲局部短暫雷陣雨", "多雲局部雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲時陰局部陣雨或雷雨", "多雲時陰局部短暫陣雨或雷雨", "多雲時陰局部短暫雷陣雨", "多雲時陰局部雷陣雨", "晴午後陰局部陣雨或雷雨", "晴午後陰局部短暫陣雨或雷雨", "晴午後陰局部短暫雷陣雨", "晴午後陰局部雷陣雨", "陰局部陣雨或雷雨", "陰局部短暫陣雨或雷雨", "陰局部短暫雷陣雨", "陰局部雷陣雨", "陰時多雲局部陣雨或雷雨", "陰時多雲局部短暫陣雨或雷雨", "陰時多雲局部短暫雷陣雨", "陰時多雲局部雷陣雨"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲有陣雨或雷雨有霧", "多雲有雷陣雨有霧", "多雲有霧有陣雨或雷雨", "多雲有霧有雷陣雨", "多雲局部陣雨或雷雨有霧", "多雲局部短暫陣雨或雷雨有霧", "多雲局部短暫雷陣雨有霧", "多雲局部雷陣雨有霧", "多雲陣雨或雷雨有霧", "多雲短暫陣雨或雷雨有霧", "多雲短暫雷陣雨有霧", "多雲雷陣雨有霧", "多雲時晴短暫陣雨或雷雨有霧"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲時陰有陣雨或雷雨有霧", "多雲時陰有雷陣雨有霧", "多雲時陰有霧有陣雨或雷雨", "多雲時陰有霧有雷陣雨", "多雲時陰局部陣雨或雷雨有霧", "多雲時陰局部短暫陣雨或雷雨有霧", "多雲時陰局部短暫雷陣雨有霧", "多雲時陰局部雷陣雨有霧", "多雲時陰陣雨或雷雨有霧", "多雲時陰短暫陣雨或雷雨有霧", "多雲時陰短暫雷陣雨有霧", "多雲時陰雷陣雨有霧", "陰局部陣雨或雷雨有霧", "陰局部短暫陣雨或雷雨有霧", "陰局部短暫雷陣雨有霧", "陰局部雷陣雨有霧", "陰時多雲有陣雨或雷雨有霧", "陰時多雲有雷陣雨有霧", "陰時多雲有霧有陣雨或雷雨", "陰時多雲有霧有雷陣雨", "陰時多雲局部陣雨或雷雨有霧", "陰時多雲局部短暫陣雨或雷雨有霧", "陰時多雲局部短暫雷陣雨有霧", "陰時多雲局部雷陣雨有霧", "陰時多雲陣雨或雷雨有霧", "陰時多雲短暫陣雨或雷雨有霧", "陰時多雲短暫雷陣雨有霧", "陰時多雲雷陣雨有霧", "陰短暫陣雨或雷雨有霧", "陰短暫雷陣雨有霧", "雷陣雨有霧"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["多雲局部雨或雪有霧", "多雲時陰局部雨或雪有霧", "陰時多雲局部雨或雪有霧", "陰局部雨或雪有霧", "短暫雨或雪有霧", "有雨或雪有霧"],
    "icon_img": require('@/assets/imgs/weather_icon/snow.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/snow.png')
  }, {
    "desc": ["短暫陣雨有霧", "短暫陣雨晨霧", "短暫雨有霧", "短暫雨晨霧"],
    "icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png')
  }, {
    "desc": ["有雨有霧", "陣雨有霧"],
    "icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/heavy_rain.png')
  }, {
    "desc": ["短暫陣雨或雷雨有霧", "陣雨或雷雨有霧"],
    "icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/thunder_shower.png')
  }, {
    "desc": ["下雪", "積冰", "暴風雪"],
    "icon_img": require('@/assets/imgs/weather_icon/snow.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/snow.png')
  }, {
    "desc": ["不明"],
    "icon_img": require('@/assets/imgs/weather_icon/unknow.png'),
    "night_icon_img": require('@/assets/imgs/weather_icon/unknow.png')
  }
]

const WaveColorTable = {
  '-4': '118, 183, 226',
  '-3': '118, 183, 226',
  '-2': '118, 183, 226',
  '-1': '118, 183, 226',
  '0': '118, 183, 226',
  '1': '105, 173, 220',
  '2': '95, 163, 214',
  '3': '82, 152, 208',
  '4': '71, 143, 198',
  '5': '71, 148, 181',
  '6': '73, 154, 163',
  '7': '71, 159, 145',
}

export {
  WeatherIconTable,
  WaveColorTable
}
