<template>
  <Layout>
    <template v-slot:title>
      關於釣魚天氣
    </template>
    <template v-slot:main>
      <article class="p-4">
        創作者喜歡釣魚，釣魚需要查天氣，水位的高低也是漁獲量的關鍵，
        因此查天氣的同時也會想一併查詢河川水位，加上個人覺得一般查天氣的網頁或
        APP 都不是很好用， 所以就自己做一個方便給釣魚人使用的工具。<br />如有任何問題或意見，歡迎至<a
          :href="get_fb_link()"
          >釣魚天氣FB粉絲團</a
        >反應。
        <div class="horizontal_center">
          <br />
          <img src="../assets/imgs/logo/TW-fishing_logo_footer.png" />
          <br />
          <img src="../assets/imgs/logo/TCANF_logo_footer.png" />
          <br />
          <img src="../assets/imgs/logo/fishing-fun-news_logo_footer.png" />
        </div>
      </article>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/menu/Layout'

export default {
  components: {
    Layout,
  },
  setup() {
    const device = (() => {
      if (/android/i.test(navigator.userAgent)) {
        return 'Android'
      }
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        return 'iOS'
      }
      return ''
    })()

    const get_fb_link = () => {
      if (device === 'Android') {
        return 'fb://page/117336863727440'
      } else if (device === 'iOS') {
        return 'fb://page/?id=117336863727440'
      } else {
        return 'https://facebook.com/117336863727440'
      }
    }

    return {
      get_fb_link: get_fb_link,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
