<template>
  <div
    class="flex h-[44px] bg-title flex items-center"
  >
    <div
      class="bg-white items-center h-20px w-20px ml-4 border-1 rounded-full cursor-pointer"
      @click="DetailStore.set_station_visible(feature.unique_key, !total_station_visible[feature.unique_key])"
      :class="total_station_visible[feature.unique_key] ? 'icon-symbol_subtract' : 'icon-symbol_add'"
      >
    </div>
    <img class="ml-4 h-7 w-7" :src="get_icon_class(feature.type_en)" />
    <div class="w-full my-4 mx-2">
      {{ feature.name }}
    </div>
    <div
      class="mr-4 icon-delete cursor-pointer"
      @click="DetailStore.delete_feature(feature.unique_key)"
    ></div>
  </div>
  <div
    class="flex gap-1 text-sm justify-center"
    v-show="total_station_visible[feature.unique_key]"
  >
    <i class="icon-map_pin"></i>
    {{ feature.address }}
  </div>
  <template v-if="tide_data">
    <div v-show="total_station_visible[feature.unique_key]">
      <div class="font-bold text-sm text-center">
        <span>資料觀測時間：</span>
        <span>{{ tide_data?.data.dtime?.strftime('%Y-%m-%d %H:%M') }}</span>
      </div>
      <hr class="my-2" />
      <div class="flex justify-center mb-4 gap-8 md:gap-16">
        <div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">氣溫：</span>
            <span>{{ display_text(tide_data?.data.tx) }}℃</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">浪高：</span>
            <span>{{ display_text(tide_data?.data.wave_height) }}公尺</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">浪向方位：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(tide_data?.data.wave_direction)
              )
            }}</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">波浪週期：</span>
            <span>{{ display_text(tide_data?.data.wave_period) }} 秒</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">潮高：</span>
            <span>{{ display_text(tide_data?.data.tide_height) }} 公尺</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">潮高等級：</span>
            <span>{{ display_text(tide_data?.data.tide_level) }}</span>
          </div>
        </div>
        <div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海溫：</span>
            <span>{{ display_text(tide_data?.data.sea_temperature) }}℃</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">風速：</span>
            <span>{{ display_text(tide_data?.data.ws) }} m/s</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">風向方位：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(
                  tide_data?.data.wind_direction_description
                )
              )
            }}</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">陣風風速：</span>
            <span>{{ display_text(tide_data?.data.gust_speed) }} m/s</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海流流速(節)：</span>
            <span
              >{{ display_text(tide_data?.data.current_speed_in_knots) }} 節</span
            >
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海流流向：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(
                  tide_data?.data.current_direction_description
                )
              )
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div v-else class="text-center text-sm text-gray-400 my-8">
    暫無資料
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { inject, watch } from '@vue/runtime-core'
export default {
  props: ['feature'],
  setup(props) {
    const feature = props.feature
    const DetailStore = inject('DetailStore')
    const total_station_visible = computed(() => DetailStore.state.total_station_visible)
    const total_station_loading = computed(() => DetailStore.state.total_station_loading)

    const tide_data = computed({
      get: () => DetailStore.state.total_station_data.find(station_data => station_data.unique_key === feature.unique_key)
    })

    const transform_en_direction_to_ch = (wd) => {
      // 英文的方位轉成中文..
      // 暫時寫在這
      let _wd = ''
      if (wd === 'N') {
        _wd = '北'
      } else if (wd === 'NNE') {
        _wd = '北北東'
      } else if (wd === 'NE') {
        _wd = '東北'
      } else if (wd === 'ENE') {
        _wd = '東北東'
      } else if (wd === 'E') {
        _wd = '東'
      } else if (wd === 'ESE') {
        _wd = '東南東'
      } else if (wd === 'ES') {
        _wd = '東南'
      } else if (wd === 'SSE') {
        _wd = '南南東'
      } else if (wd === 'S') {
        _wd = '南'
      } else if (wd === 'SSW') {
        _wd = '南南西'
      } else if (wd === 'WS') {
        _wd = '西南'
      } else if (wd === 'WSW') {
        _wd = '西南西'
      } else if (wd === 'W') {
        _wd = '西'
      } else if (wd === 'WNW') {
        _wd = '西北西'
      } else if (wd === 'WN') {
        _wd = '西北'
      } else if (wd === 'NNW') {
        _wd = '北北西'
      }
      return _wd
    }

    const display_text = (value) => {
      if (value !== null && value !== '' && value !== undefined) {
        return value
      }

      return '- -'
    }

    const get_icon_class = (type) => {
      if (type === 'pin') {
        return require('@/assets/imgs/map_pin--click.png')
      } else if (type === 'cctv') {
        return require('@/assets/imgs/CCTV.png')
      } else if (type === 'tidal') {
        return require('@/assets/imgs/tidal.png')
      } else if (type === 'water_station') {
        return require('@/assets/imgs/water_stage.png')
      } else if (type === 'rain_station') {
        return require('@/assets/imgs/precipitation.png')
      } else if (type === 'tide_station') {
        return require('@/assets/imgs/tide_station.png')
      } else if (type === 'buoy') {
        return require('@/assets/imgs/buoy.png')
      }
    }

    return {
      tide_data,
      DetailStore,
      feature,
      total_station_visible,
      total_station_loading,
      transform_en_direction_to_ch,
      display_text,
      get_icon_class
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
