import { watch } from 'vue'

export const useInshoreVLayer = (map, DetailStore, InshoreStore) => {
  const layer_group = L.featureGroup().addTo(map)

  const draw_marker = (features) => {
    layer_group.clearLayers()
    if (features) {
      features.forEach(element => {
        let inshore_marker = L.marker({
          lat: element.lat,
          lon: element.lon
        }, {
          icon: get_icon(element),
          shadowPane: 'favoritesPane',
        }).addTo(layer_group)
        inshore_marker.on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return
          if (is_checked(element.unique_key)) {
            DetailStore.remove_temp_selected_features(element)
            inshore_marker.setIcon(create_icon(false, element.name))
          } else {
            DetailStore.add_temp_selected_features(element)
            inshore_marker.setIcon(create_icon(true, element.name))
          }
        })
      })
    }
  }

  if (!InshoreStore.state.features) {
    InshoreStore.get_inshore_areas()
  }

  const is_checked = (unique_key) => {
    return DetailStore.state.temp_unique_key_properties_mapping[unique_key] ? true : false
  }

  const get_icon = (feature) => {
    const name = feature.name
    if (is_checked(feature.unique_key)) {
      return create_icon(true, name)
    }
    return create_icon(false, name)
  }

  const create_icon = (checked, name) => {
    if (checked) {
      return L.divIcon({
        iconSize: null,
        iconAnchor: [50, 50],
        className: null,
        html: `
        <div class="btn_primary rounded-full py-2 px-4 whitespace-nowrap border border-solid border-white/50">${name}
          <div class="absolute -top-[5px] -right-[5px]">
            <img class="w-[20px] h-[20px]" src=${require('@/assets/imgs/check_hook.png')}>
          </div>
        </div>
        `
      })
    }
    return L.divIcon({
      iconSize: null,
      iconAnchor: [50, 50],
      className: null,
      html: '<div class="btn_primary rounded-full py-2 px-4 whitespace-nowrap border border-solid border-white/50">'+name+'</div>',
    })
  }

  watch(() => InshoreStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate:true
  })

}
