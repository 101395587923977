import { CBTimeChart } from './cb_chart.js'
import { CBChartDataArtist, CBIconDataArtist } from './cb_data_artist.js'
import { CBChartPaint, CBAreaChartPaint, CBBarChartPaint } from './cb_chart_paint.js'
import { WeatherIconTable, WSIconTable, WeatherColorTable } from './settings'

const get_img_url = (path) => {
  return require(`@/libs/cb-chart/imgs/${path}`)
}


class CBWeatherChart extends CBTimeChart {
  draw_reference() {
    super.draw_reference()
    if (this.options.night_bg_required) {
      this.draw_night_bg()
    }
  }

  draw_night_bg() {
    let tick_list = this.scale_x.paint_tick_list(this.options)
    let average_width = (this.scale_x.canvas_range[1] - this.scale_x.canvas_range[0]) / (tick_list.length-1)
    let ctx = this.canvas.getContext("2d")

    ctx.globalAlpha = 1

    for (let idx in tick_list) {
      let tick_value = tick_list[idx]
      if (tick_value.getHours() >= 18 || tick_value.getHours() <= 5) {
        ctx.fillStyle = 'rgba(69, 136, 177, 0.05)'
        ctx.fillRect(this.scale_x.position(tick_value) - average_width / 2, 0, average_width, this.canvas.height);
      }
    }
  }
}

class CBWeatherIconDataArtist extends CBIconDataArtist {
  constructor(dataset, paint, options) {
    super(dataset, paint, Object.deepAssign({}, {
      icon_table: WeatherIconTable
    }, options))
  }

  get_icon(desc, dtime) {
    let icon = ''

    let icon_table = this.options.icon_table

    if (desc === null || desc === '') {
      desc = '不明'
    }

    let weather_info = icon_table.find(info => info.desc.includes(desc))
    if (!weather_info) {
      console.debug(`Can't find weather icon of this weather desc (wx: ${desc})`)
      weather_info = icon_table.find(info => info.desc.includes('不明'))
    }
    if (weather_info) {
      if (dtime && (dtime.getHours() <= 6 || dtime.getHours() > 18)) {
        icon = weather_info.night_icon_img
      }
      else {
        icon = weather_info.icon_img
      }
    }

    return icon
  }
}

// Irene@2022/05/18: 很久沒用了，不確定是否正常
class CBPeriodWeatherIconDataArtist extends CBWeatherIconDataArtist {
  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      let prev_x = null
      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (prev_x !== null) {
          dataset.push({
            x: prev_x.addHours(raw_data.x.diffHours(prev_x) / 2.0),
            y: raw_data.y,
            icon: this.get_icon(raw_data.y)
          })
        }

        prev_x = raw_data.x
      }
    }

    return dataset
  }
}


class CBValidDataArtist extends CBChartDataArtist {
  // 排除無效值，小於 -990 的都跳過
  make_drawing_data(dataset) {
    this.raw_dataset = dataset

    if (dataset.length > 0) {
      dataset = dataset.filter(d => d.y !== undefined && d.y !== null && d.y > -990)
    }

    return dataset
  }
  /* */
}


class CBNotNegativeDataArtist extends CBChartDataArtist {
  // 排除負值
  make_drawing_data(dataset) {
    this.raw_dataset = dataset

    if (dataset.length > 0) {
      dataset = dataset.filter(d => d.y !== undefined && d.y !== null && d.y >= 0)
    }

    return dataset
  }
  /* */
}

class CBTxAreaPaint extends CBAreaChartPaint {
  constructor(options) {
    super(Object.deepAssign({}, {
      color_table: WeatherColorTable.tx
    }, options))
  }
}

class CBPrecpBarPaint extends CBBarChartPaint {
  constructor(options) {
    super(Object.deepAssign({}, {
      fill: 'rgb(136, 209, 224)'
    }, options))
  }

  draw(dataset, canvas, scale_x, scale_y) {
    let ctx = canvas.getContext("2d")
    ctx.globalAlpha = this.options.opacity || 1

    if (dataset.length > 0) {
      ctx.beginPath()
      let bar_width = this.options.bar_width || 15

      let work_space = canvas.parentNode
      if (work_space && canvas) {
        for (let data of dataset) {
          // 若資料值超過上限，只畫到上限，並加上波浪省略符號
          let y_position
          let ellipsis_required = false
          if (data.y > scale_y.data_range[1]) {
            y_position = scale_y.position(scale_y.data_range[1])
            ellipsis_required = true
          }
          else {
            y_position = scale_y.position(data.y)
          }

          ctx.rect(
            (scale_x.position(data.x) - bar_width / 2) - 1,
            y_position,
            bar_width,
            scale_y.canvas_range[0] - y_position
          )

          // 加波浪省略符號
          if (ellipsis_required) {
            let tilde_ellipsis_img = new Image()
            tilde_ellipsis_img.src = get_img_url('tilde_ellipsis.png')
            tilde_ellipsis_img.className = `cb_chart-icon_chart_img`
            tilde_ellipsis_img.style.left = css_unit_join(scale_x.position(data.x) - 1 + 'px')
            tilde_ellipsis_img.style.top = css_unit_join(y_position + 15 + 'px')
            tilde_ellipsis_img.style.width = css_unit_join(bar_width * 1.3 + 'px')
            work_space.appendChild(tilde_ellipsis_img)
          }

          ctx.fillStyle = this.options.fill
          ctx.fill();
        }
      }
      ctx.closePath()
    }

    return this
  }
}

class CBWSDataArtist extends CBChartDataArtist {
  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (raw_data.y.ws != null && raw_data.y.ws >= 0) {
          dataset.push({
            x: raw_data.x,
            y: raw_data.y.ws
          })
        }
      }
    }

    return dataset
  }
}


class CBWDDataArtist extends CBChartDataArtist {
  get NO_WIND_THRESHOLD() {
    return 0.3
  }

  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (raw_data.y.ws === null || raw_data.y.ws === undefined ||
            raw_data.y.wd === null || raw_data.y.wd === undefined) {
          continue
        }


        dataset.push({
          x: raw_data.x,
          y: raw_data.y.wd,
          icon: this.get_icon(raw_data.y.ws, raw_data.y.wd),
          rotate: this.get_rotate(raw_data.y.ws, raw_data.y.wd)
        })
      }
    }

    return dataset
  }

  get_rotate(ws, wd) {
    let cwb_rotate = null
    if (ws < this.NO_WIND_THRESHOLD || wd < 0 || ws === null || wd === null) {
      cwb_rotate = 0
    } else {
      // 和氣象局表示法一致, 指向風的去向
      cwb_rotate = wd + 180
      while (cwb_rotate >= 360) {
        cwb_rotate -= 360
      }
    }
    return cwb_rotate
  }

  get_icon(ws, wd) {
    let icon = ''
    if (ws !== null && wd !== null && ws >= 0 && wd >= 0) {
      if (ws < this.NO_WIND_THRESHOLD) {
        icon = get_img_url('arrow/direction--0.png')
      } else {
        icon = get_img_url('arrow/direction--B.png')
      }
    } else {
      icon = get_img_url('unknown.png')
    }

    return icon
  }
}

// 帶有風級風向圖示、風級顏色的資料
class CBWSWDDataArtist extends CBWDDataArtist {
  constructor(dataset, paint, options) {
    super(dataset, paint, Object.deepAssign({}, {
      icon_table: WSIconTable,
      color_table: WeatherColorTable.ws
    }, options))
  }

  make_drawing_data(dataset) {
    if (dataset.length > 0) {
      this.raw_dataset = dataset
      dataset = []

      for (let idx=0; idx<this.raw_dataset.length; idx++) {
        let raw_data = this.raw_dataset[idx]

        if (raw_data.y.ws === null || raw_data.y.ws === undefined ||
          raw_data.y.wd === null || raw_data.y.wd === undefined) {
            continue
        }

        dataset.push({
          x: raw_data.x,
          y: raw_data.y.ws,
          icon: this.get_icon(raw_data.y.ws),
          color: this.get_color(raw_data.y.ws),
          rotate: this.get_rotate(raw_data.y.ws, raw_data.y.wd)
        })
      }
    }

    return dataset
  }

  get_icon(ws) {
    let icon = ''
    let icon_table = this.options.icon_table
    let missing_value = this.options.missing_value || -999

    if (ws > 7) { ws = 7 }
    if (ws < 0) { ws = missing_value }

    let wind_info = icon_table.find(info => info.level.includes(Math.round(ws)))
    icon = wind_info.icon_img

    return icon
  }

  get_color(ws) {
    let color = 'transparent'
    let color_table = this.options.color_table

    let max_level = 17
    let key
    if (ws > max_level) {
      key = max_level
    }
    else {
      key = ws
    }

    color = 'rgb(' + color_table[Math.round(key).toString()] + ')'
    return color
  }
}


class CBWSCirclePaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let top = scale_y.position()
    let circle_size = this.options.circle_size || 30

    let ctx = canvas.getContext("2d")
    ctx.globalAlpha = this.options.opacity || 0.9

    if (dataset.length > 0) {
      for (let idx=0; idx<dataset.length; idx++) {
        let data = dataset[idx]
        ctx.beginPath()
        ctx.arc(
          scale_x.position(data.x),
          top,
          circle_size / 2,
          0,
          2 * Math.PI
        )
        ctx.lineWidth = 4
        ctx.strokeStyle = data.color
        ctx.stroke()
        ctx.closePath()
      }
    }
  }
}


export {
  CBWeatherChart,
  CBWeatherIconDataArtist, CBPeriodWeatherIconDataArtist,
  CBValidDataArtist, CBNotNegativeDataArtist, CBTxAreaPaint, CBPrecpBarPaint,
  CBWSDataArtist, CBWDDataArtist, CBWSWDDataArtist, CBWSCirclePaint
}
