<template>
  <div class="h-340px">
    <div v-show="is_getting_tide_data" class="blink_loading"></div>
    <div class="my-2">
      <!-- marine name -->
      <div class="text-xl font-bold text-center">
        {{ selected_target.name }}
      </div>

      <!-- marine address -->
      <div class="flex gap-1 text-sm justify-center">
        <i class="icon-map_pin"></i>
        {{ selected_target.address }}
      </div>
    </div>

    <!-- 目前警特報 -->
    <template v-if="tide_data">
      <div class="font-bold text-sm text-center">
        <span>資料觀測時間：</span>
        <span>{{ tide_data?.dtime?.strftime('%Y-%m-%d %H:%M') }}</span>
      </div>
      <hr class="my-2" />
      <div class="flex justify-center mb-4 gap-8 md:gap-16">
        <div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">氣溫：</span>
            <span>{{ display_text(tide_data?.tx) }}℃</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">浪高：</span>
            <span>{{ display_text(tide_data?.wave_height) }}公尺</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">浪向方位：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(tide_data?.wave_direction)
              )
            }}</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">波浪週期：</span>
            <span>{{ display_text(tide_data?.wave_period) }} 秒</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">潮高：</span>
            <span>{{ display_text(tide_data?.tide_height) }} 公尺</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">潮高等級：</span>
            <span>{{ display_text(tide_data?.tide_level) }}</span>
          </div>
        </div>
        <div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海溫：</span>
            <span>{{ display_text(tide_data?.sea_temperature) }}℃</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">風速：</span>
            <span>{{ display_text(tide_data?.ws) }} m/s</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">風向方位：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(
                  tide_data?.wind_direction_description
                )
              )
            }}</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">陣風風速：</span>
            <span>{{ display_text(tide_data?.gust_speed) }} m/s</span>
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海流流速(節)：</span>
            <span
              >{{ display_text(tide_data?.current_speed_in_knots) }} 節</span
            >
          </div>
          <div class="bg-opacity-20 text-sm py-1 px-2 rounded-lg">
            <span class="font-bold">海流流向：</span>
            <span>{{
              display_text(
                transform_en_direction_to_ch(
                  tide_data?.current_direction_description
                )
              )
            }}</span>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="text-center text-sm text-gray-400 my-8">
      暫無資料
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { inject, watch } from '@vue/runtime-core'
export default {
  setup() {
    const LayerStore = inject('LayerStore')
    const TideStore = inject('TideStore')

    const selected_target = computed(() => {
      let feature = TideStore.state.features.find(
        (_) => _.id === LayerStore.state.selected_target.id
      )
      return { ...feature, ...LayerStore.state.selected_target }
    })

    const tide_data = ref({})
    const is_getting_tide_data = ref(false)
    const get_tide_data_list = async (id) => {
      is_getting_tide_data.value = true

      try {
        let data = await TideStore.get_tide_station_data(id)
        tide_data.value = data
      } finally {
        is_getting_tide_data.value = false
      }
    }
    watch(
      selected_target,
      (tide) => {
        if (tide.type === 'tide') {
          get_tide_data_list(tide.id)
        }
      },
      {
        immediate: true,
      }
    )

    const transform_en_direction_to_ch = (wd) => {
      // 英文的方位轉成中文..
      // 暫時寫在這
      let _wd = ''
      if (wd === 'N') {
        _wd = '北'
      } else if (wd === 'NNE') {
        _wd = '北北東'
      } else if (wd === 'NE') {
        _wd = '東北'
      } else if (wd === 'ENE') {
        _wd = '東北東'
      } else if (wd === 'E') {
        _wd = '東'
      } else if (wd === 'ESE') {
        _wd = '東南東'
      } else if (wd === 'ES') {
        _wd = '東南'
      } else if (wd === 'SSE') {
        _wd = '南南東'
      } else if (wd === 'S') {
        _wd = '南'
      } else if (wd === 'SSW') {
        _wd = '南南西'
      } else if (wd === 'WS') {
        _wd = '西南'
      } else if (wd === 'WSW') {
        _wd = '西南西'
      } else if (wd === 'W') {
        _wd = '西'
      } else if (wd === 'WNW') {
        _wd = '西北西'
      } else if (wd === 'WN') {
        _wd = '西北'
      } else if (wd === 'NNW') {
        _wd = '北北西'
      }
      return _wd
    }

    const display_text = (value) => {
      if (value !== null && value !== '' && value !== undefined) {
        return value
      }

      return '- -'
    }

    return {
      is_getting_tide_data,
      tide_data,
      selected_target,
      transform_en_direction_to_ch,
      display_text,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
