import { reactive, readonly, provide } from 'vue'
import { ConsoleRepo } from '@/repositories/console'

export const useAnnouncementStore = () => {
  const state = reactive({
    announcements: []
  })

  const fetch_announcements = async () => {
    try {
      let payload = await ConsoleRepo.announcement.get()
      if (payload.data) {
        payload.data.forEach(announcement => {
          announcement['updated_at'] = new Date(announcement['updated_at']).toLST()
          announcement['notification_type'] = 'announcement'
        })
        state.announcements = payload.data
      }
      else {
        state.announcements = []
      }
    } catch (error) {
      state.announcements = []
    }
  }

  fetch_announcements()

  const store = {
    state: readonly(state),
    fetch_announcements  // for focus refresh
  }

  provide('AnnouncementStore', store)

  return store
}
