import { render_gradient_colors } from './cb_chart_util.js'

let default_paint_options = {
}
class CBChartPaint {
  constructor(options) {
    this.options = Object.deepAssign({}, default_paint_options, options)
  }

  draw(data, canvas, scale_x, scale_y) {
    console.error("Not Implemented")
    return this
  }
}

class CBLineChartPaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let ctx = canvas.getContext("2d")

    if (dataset.length > 0) {
      ctx.strokeStyle = this.options.color || 'darkgray'
      ctx.globalAlpha = this.options.opacity || 1
      ctx.lineWidth = this.options.line_width || 1

      if (this.options.line_dash) {
        ctx.setLineDash(this.options.line_dash)
      }
      else {
        ctx.setLineDash([]);
      }

      ctx.beginPath()

      // 畫第一個點
      let data = dataset[0]
      ctx.moveTo(
        scale_x.position(data.x),
        scale_y.position(data.y)
      )
      this.draw_single_data(ctx, scale_x.position(data.x), scale_y.position(data.y))

      // 之後的點
      if (dataset.length > 1) {
        for (let idx=0; idx<dataset.length; idx++) {
          data = dataset[idx]
          this.draw_single_data(ctx, scale_x.position(data.x), scale_y.position(data.y))
        }
      }
      ctx.stroke()
      ctx.closePath()
    }

    return this
  }

  draw_single_data(ctx, x, y) {
    ctx.lineTo(x, y)
  }
}


class CBAreaChartPaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let ctx = canvas.getContext("2d")
    ctx.globalAlpha = this.options.opacity || 0.5
    ctx.strokeStyle = "rgba(0,0,0,0)"

    if (dataset.length > 0) {
      ctx.beginPath()
      let data = dataset[0]

      ctx.moveTo(
        scale_x.position(data.x),
        scale_y.canvas_range[0]
      )
      ctx.lineTo(
        scale_x.position(data.x),
        scale_y.position(data.y)
      )
      if (dataset.length > 1) {
        for (let idx=1; idx<dataset.length; idx++) {
          data = dataset[idx]
          ctx.lineTo(
            scale_x.position(data.x),
            scale_y.position(data.y)
          )
        }
        ctx.lineTo(
          scale_x.position(data.x),
          scale_y.canvas_range[0]
        )

        if (this.options.color_table) {
          ctx.fillStyle = render_gradient_colors(
            ctx, this.options.color_table, scale_y, this.options.fade_required)
        }
        else if (this.options.fill_style) {
          ctx.fillStyle = this.options.fill_style
        }

      }
      ctx.closePath()
      ctx.fill()
    }

    return this
  }
}


class CBBarChartPaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let ctx = canvas.getContext("2d")
    ctx.globalAlpha = this.options.opacity || 1

    if (dataset.length > 0) {
      let bar_width = this.options.bar_width || 15

      for (let data of dataset) {
        ctx.beginPath()
        ctx.rect(
          (scale_x.position(data.x) - bar_width / 2) - 1,
          scale_y.position(data.y),
          bar_width,
          scale_y.canvas_range[0] - scale_y.position(data.y)
        )

        ctx.fillStyle = this.options.fill || 'darkgray'
        ctx.fill();
        ctx.closePath()
      }
    }

    return this
  }
}


class CBIconChartPaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let work_space = canvas.parentNode
    if (work_space) {
      let top = scale_y.position()
      if (top == null) {
        console.warn('Please set canvas range for icon chart.');
        top = 20
      }
      let icon_size = this.options.icon_size || 30
      let transform = this.options.transform || 'translateX(-50%) translateY(-50%)'

      for (let idx=0; idx<dataset.length; idx++) {
        let data = dataset[idx]

        let img_div = document.createElement('img')
        img_div.src = data.icon
        img_div.className = 'cb_chart-icon_chart_img'
        img_div.style.top = css_unit_join(top, 'px')
        img_div.style.left = css_unit_join(scale_x.position(data.x) + 'px')
        img_div.style.transform = transform
        if (data.rotate) {
          img_div.style.transform += 'rotate(' + data.rotate + 'deg)'
        }
        img_div.style.width = css_unit_join(icon_size, 'px')
        img_div.style.zIndex = 1

        work_space.appendChild(img_div)
      }
    }
  }
}


class CBTextChartPaint extends CBChartPaint {
  draw(dataset, canvas, scale_x, scale_y) {
    let work_space = canvas.parentNode
    if (work_space) {
      for (let idx=0; idx<dataset.length; idx++) {
        let data = dataset[idx]
        if (data != null) {
          let text_div = this.get_text_div(data)
          text_div.style.left = css_unit_join(scale_x.position(data.x), 'px')
          text_div.style.top = css_unit_join(scale_y.position(data.y), 'px')
          work_space.appendChild(text_div)
        }
      }
    }
  }

  get_text_div(data) {
    let text_format = this.options.format? this.options.format : (v) => v
    let class_name = 'cb_chart-text_div ' + this.options.class_name

    let text_div = document.createElement('div')
    text_div.innerHTML = text_format(data.y)
    text_div.style.pointerEvents = 'none'
    text_div.className = class_name
    return text_div
  }
}


export {
  CBChartPaint,
  CBLineChartPaint, CBIconChartPaint, CBAreaChartPaint, CBBarChartPaint, CBTextChartPaint
}
