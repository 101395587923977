<template>
  <label class="flex items-center">
    <input
      type="checkbox"
      class="peer hidden"
      v-model="selected"
      :value="value"
      :checked="checked"
      :disabled="disabled"
    />
    <slot>
      <div :class="checkbox_class"></div>
      <span :class="text_class">
        {{ text !== null ? text : value ? value : '預設文字 (:text)' }}
      </span>
    </slot>
  </label>
</template>

<script>
import { computed } from 'vue'

/**
 * 用法：
 * <cb-checkbox><cb-checkbox>
 * 用法幾乎和原生 checkbox 一樣
 */
export default {
  props: {
    modelValue: {},
    value: {},
    checked: {},
    disabled: {},

    // checkbox 樣式
    checkbox_class: {
      type: String,
      default: 'checkbox_primary',
    },

    // 欲顯示的文字
    text: {
      type: String,
      default: null,
    },

    // text 樣式
    text_class: {
      type: String,
      default:
        'text-primary cursor-pointer select-none peer-disabled:cursor-not-allowed peer-disabled:text-gray-400',
    },

    // 是否用於全選
    for_check_all: {
      type: Boolean,
      default: false,
    },

    // 全選會需要參考的依據
    item_list: {
      type: Array,
      default: null,
    },
  },

  emit: ['update:modelValue'],

  setup(props, { emit }) {
    let selected = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val)
      },
    })

    if (props.for_check_all) {
      selected = computed({
        get: () => props.modelValue.length === props.item_list?.length,
        set: (val) => {
          if (val) {
            emit('update:modelValue', [...props.item_list])
          } else {
            emit('update:modelValue', [])
          }
        },
      })
    }

    return {
      selected,
    }
  },
}
</script>
