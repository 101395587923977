import { watch } from 'vue'

export const useRainStationVLayer = (map, DetailStore, RainStationStore) => {
  const layer_group = L.featureGroup().addTo(map)

  const icon = L.icon({
    iconUrl: require('@/assets/imgs/precipitation.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
  })

  const icon_in_collections = L.icon({
    iconUrl: require('@/assets/imgs/precipitation.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, -10],
    shadowUrl: require('@/assets/imgs/check_hook.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 20],
  })

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-rain_station/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-rain_station text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    if (features) {
      layer_group.clearLayers()
      for (let stn of features) {
        let station_marker = L.marker(
          {lat: stn.lat, lon: stn.lon},
          {icon: get_icon(stn.unique_key), shadowPane: 'favoritesPane'}
        ).addTo(cluster_group)

        station_marker.on("click", (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return
          if (is_checked(stn.unique_key)) {
            DetailStore.remove_temp_selected_features(stn)
            station_marker.setIcon(icon)
          } else {
            DetailStore.add_temp_selected_features(stn)
            station_marker.setIcon(icon_in_collections)
          }
        })
      }
      layer_group.addLayer(cluster_group)
    }
  }

  if (!RainStationStore.state.features) {
    RainStationStore.get_features()
  }

  const is_checked = (unique_key) => {
    return DetailStore.state.temp_unique_key_properties_mapping[unique_key] ? true : false
  }

  const get_icon = unique_key => {
    if (is_checked(unique_key)) {
      return icon_in_collections
    }
    return icon
  }

  watch(() => RainStationStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate: true
  })
}
