/**
 * api:
 * 1. 管理 url
 * 2. 對接後端所開的 views
 */

import { req } from "@/axios/fwm"


export const InshoreRepo = {
  inshore_areas: {
    get: (params) => req("get", "v6/marine/inshore/areas/", params)
  },

  inshore_area_data: {
    get: (params) => req("get", "v5/marine/inshore/area_data/", params)
  }
}