<template>
  <div
    class="safe_padding_top absolute inset-0 z-2000 w-full h-full bg-gray-800 flex items-center justify-center"
  >
    <div class="relative w-full h-full">
      <button
        class="btn_primary absolute left-0 z-1 m-2 text-xl rounded-full flex"
        @click="clear_selected_cctv(); clear_interval()"
      >
        <i class="icon-symbol_back"></i>
      </button>
      <div class="absolute right-0 flex flex-1 overflow-x-auto w-[70%]">
        <div class="z-1 flex flex-1 justify-end">
          <button
            v-for="(url, idx) in image_urls"
            :key="'btn_' + idx"
            @click="selected_angle = idx"
            class="btn_primary--line flex items-center m-2 rounded-full"
            :class="{ 'bg-primary text-white': selected_angle === idx }"
          >
            <i class="icon-CCTV mr-[6px] text-xl"></i>{{ idx + 1 }}
          </button>
        </div>
      </div>
      <div
        v-show="is_getting_cctv_urls || is_downloading_image"
        class="blink_loading z-0"
      ></div>
      <img
        ref="img_elem"
        :src="image_urls[selected_angle]?.[selected_timestamp_order]"
        class="z-0 w-full h-full object-contain"
        @touchstart="img_touchstart"
        @touchmove="img_touchmove"
        @touchend="img_touchend"
        @touchcancel="img_touchend"
      />
      <div
        v-if="image_urls[selected_angle]?.length > 1"
      >
        <i
          class="prev icon-symbol_left text-2xl absolute cursor-pointer left-1/4 bottom-10 text-white"
          @click="plus_timestamp_order(-1)"
        >
        </i>
        <i
          class="next icon-symbol_right text-2xl absolute cursor-pointer right-1/4 bottom-10 text-white"
          @click="plus_timestamp_order(1)"
        >
        </i>
      </div>
    </div>
    <div class="absolute bottom-10 horizontal_center">
      <FavoritesBtnVue></FavoritesBtnVue>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, computed, inject, ref, watch } from 'vue'
import { useImgControl } from '@/compositions/img_control'
import FavoritesBtnVue from '../components/FavoritesBtn.vue'

export default {
  props: {
    panel_visible: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FavoritesBtnVue,
  },

  setup(props) {
    const CctvStore = inject('CctvStore')

    const selected_angle = ref(0)
    const selected_timestamp_order = ref(0)
    const image_urls = computed(() => CctvStore.state.image_urls)
    const url_method = computed(() => CctvStore.state.url_method)

    const clear_selected_cctv = () => {
      CctvStore.set_selected_cctv(null)
    }

    const clear_interval = () => {
      clearInterval(CctvStore.state.interval_id)
    }

    const plus_timestamp_order = (n) => {
      selected_timestamp_order.value += n
      if (selected_timestamp_order.value >= image_urls.value[selected_angle.value].length) {
        selected_timestamp_order.value = 0
      }
      if (selected_timestamp_order.value < 0) {
        selected_timestamp_order.value = image_urls.value[selected_angle.value].length - 1
      }
    }

    const { img_touchstart, img_touchmove, img_touchend } = useImgControl()

    // Handle cctv loading event

    const is_getting_cctv_urls = computed(
      () => CctvStore.state.is_getting_cctv_urls
    )
    const img_elem = ref(null)
    const is_downloading_image = ref(false)
    watch(img_elem, (img_elem) => {
      gtag('event', 'view_cctv')
      if (!img_elem.onload) {
        img_elem.onload = () => {
          is_downloading_image.value = false
        }
      }
      if (!img_elem.onerror) {
        img_elem.onerror = (e) => {
          console.debug("load image error")
          if (img_elem.src.indexOf("?nocache=") < 0) {
            setTimeout(()=>{
              if (img_elem.src) {
                img_elem.src = img_elem.src + "?nocache=" + new Date().getTime()
                console.debug("try reload image", img_elem.src)
              }
            }, 2000)
          }
          else {
            is_downloading_image.value = false
          }
        }
      }
    })

    watch([image_urls, selected_angle], () => {
      is_downloading_image.value = true
    })

    return {
      img_elem,
      selected_angle,
      selected_timestamp_order,
      image_urls,
      is_getting_cctv_urls,
      clear_selected_cctv,
      clear_interval,
      plus_timestamp_order,
      url_method,

      img_touchstart,
      img_touchmove,
      img_touchend,
      is_downloading_image
    }
  },
}
</script>

<style lang="scss" scoped>


</style>
