
export const LAYER_GROUPS = [
    {
      'layer_type': 'cctv',
      'icon': 'icon-CCTV',
    }, {
      'layer_type': 'water_station',
      'icon': 'icon-water_stage',
    }, {
      'layer_type': 'tidal',
      'icon': 'icon-tidal',
    }, {
      'layer_type': 'rain_station',
      'icon': 'icon-precipitation'
    }, {
      'layer_type': 'rainfall',
      'icon': 'icon-heavy_rain',
    }, {
      'layer_type': 'thunderstorm',
      'icon': 'icon-thunder-heavy_rain',
    }, {
      'layer_type': 'radar',
      'icon': 'icon-radar2',
    }, {
      'layer_type': 'windy',
      'icon': 'icon-wind_field',
    }, {
      'layer_type': 'inshore',
      'icon': 'icon-wave',
    }, {
      'layer_type': 'tide',
      'icon': 'icon-tide_station',
    }, {
      'layer_type': 'buoy',
      'icon': 'icon-buoy',
    }, {
      'layer_type': 'fishing_harbor',
      'icon': 'icon-port',
    }
  ]
