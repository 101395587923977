import { watch, computed } from 'vue'

import { round_to_five } from '../../libs/utils'

const get_unique_key = (latlon) => {
  if ('lng' in latlon) {
    return `tidal,${round_to_five(latlon.lat)},${round_to_five(latlon.lng)}`
  }
  return `tidal,${round_to_five(latlon.lat)},${round_to_five(latlon.lon)}`
}

export const useTidalVLayer = (map, LayerStore, TidalStore, FavoritesStore) => {
  const layer_group = L.featureGroup()

  const icon = L.icon({
    iconUrl: require('@/assets/imgs/tidal.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
  })

  const icon_in_favorites = L.icon({
    iconUrl: require('@/assets/imgs/tidal.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    shadowUrl: require('@/assets/imgs/love--click.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 20],
  })

  // 特殊潮汐站
  const wanted_sites = [
    {name: "新北市淡水區", lat: 25.0727, lon:121.6595},
    {name: "新北市淡水區", lat: 25.00247, lon:121.53299},
    {name: "新北市淡水區", lat: 25.0998, lon:121.5302},
  ]

  const marker_layer_map = new Map()

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-tidal/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-tidal text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      for (let feature of features) {
        let tidal_marker = L.marker({
          lat: feature.lat,
          lon: feature.lon,
        }, {
          shadowPane: 'favoritesPane',
          icon: icon
        }).addTo(cluster_group)

        const unique_key = get_unique_key(feature)
        tidal_marker.on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          let target = {
            'type': 'tidal',
            'id': feature.id,
            'lat': feature.lat,
            'lon': feature.lon,
            'name': feature.name,
            unique_key
          }
          LayerStore.set_selected_target(target)
          gtag('event', 'open_tidal_chart')
        })
        marker_layer_map.set(unique_key, tidal_marker)

        // 特殊潮汐站
        for (let wanted_site of wanted_sites) {
          if (feature.name === wanted_site.name) {
            let tidal_marker = L.marker({
              lat: wanted_site.lat,
              lon: wanted_site.lon,
            }, {
              shadowPane: 'favoritesPane',
              opacity: 0.6,
              icon: icon
            }).addTo(layer_group)

            const unique_key = get_unique_key(wanted_site)
            tidal_marker.on('click', (e) => {
              // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
              if (!e.originalEvent?.isTrusted) return

              let target = {
                'type': 'tidal',
                'id': feature.id,
                'lat': wanted_site.lat,
                'lon': wanted_site.lon,
                'name': wanted_site.name,
                'unique_key': unique_key
              }
              LayerStore.set_selected_target(target)
              gtag('event', 'open_tidal_chart')
            })
            // 若是虛擬的潮位站，暫時存的這個 marker 就不用 station_id 當作 KEY，改由經緯度
            marker_layer_map.set(unique_key, tidal_marker)
          }
        }
        layer_group.addLayer(cluster_group)
      }
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  const refresh_icons = () => {
    marker_layer_map.forEach((marker, key, map) => {
      const obj = favorites_mapping.value[key]
      if (obj) {
        marker.setIcon(icon_in_favorites)
      } else {
        marker.setIcon(icon)
      }
    })
  }

  const favorites_mapping = computed(() => FavoritesStore.state.favorites_mapping)

  watch(() => LayerStore.state.checked_layers.tidal, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => TidalStore.state.features, (_features) => {
    draw_marker(_features)
    refresh_icons()
  }, {
    immediate: true
  })

  watch(favorites_mapping, () => {
    refresh_icons()
  })
}
