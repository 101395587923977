import { createApp } from 'vue'

/** import style (tailwind) */
import './tw_style.css'

/** register components */
import CbModal from './Modal.vue'
import CbDialog from './Dialog.vue'
import CbSelect from './Select.vue'
import CbCheckbox from './Checkbox.vue'
import CbRadio from './Radio.vue'
import CbButton from './Button.vue'

const create_dialog = (options, default_options) => {
  let _options = options instanceof Object ? options : { content: options }
  const dialog = createApp(CbDialog, Object.assign(default_options, _options))

  const dialog_container = document.createElement("div")
  document.body.appendChild(dialog_container)
  dialog.config.globalProperties.$unmount = () => {
    document.body.removeChild(dialog_container)
    dialog.unmount()
  }

  return dialog.mount(dialog_container).launch()
}

export const cb_alert = (options) => {
  return create_dialog(options, {
    dialog_type: 'alert',
    title: '通知'
  })
}

export const cb_confirm = (options) => {
  return create_dialog(options, {
    dialog_type: 'confirm',
    title: '確認',
  })
}

export default {
  install(app) {
    /** register global component */
    app.component('cb-modal', CbModal)
    app.component('cb-select', CbSelect)
    app.component('cb-checkbox', CbCheckbox)
    app.component('cb-radio', CbRadio)
    app.component('cb-button', CbButton)

    /** register global Dialog function */
    app.config.globalProperties.$cb_alert = alert
    app.config.globalProperties.$cb_confirm = confirm
  }
}

