import { watch } from 'vue'

const ALERT_STYLE = {
  '綠色': {
    stroke: false,
    color: 'rgba(0, 140, 40, 0)'
  },
  '黃色': {
    stroke: false,
    color: 'rgba(255, 235, 0, 0.8)',
    opacity: 1
  },
  '橙色': {
    stroke: false,
    color: 'rgba(240, 148, 0, 1)',
  },
  '紅色': {
    stroke: false,
    color: 'rgba(255, 0, 0, 1)',
  }
}

export const useRainfallVLayer = (map, LayerStore, AlertStore) => {
  const layer_group = L.featureGroup()

  const draw_geojson = (alerts) => {
    layer_group.clearLayers()
    if (alerts && alerts.length) {
      let geojson = {
        "type": "FeatureCollection",
        "features": []
      }

      alerts.forEach(alert => {
        let areas_region_code = alert.area.map(function (item, index, array) {
          return item.region_code
        })

        geojson["features"] = TOWN_GEOJSON.filter(function (item, index, array) {
          return areas_region_code.includes(item.properties.region_code)
        })
        let county = COUNTY_GEOJSON.filter(function (item, index, array) {
          return areas_region_code.includes(item.properties.region_code)
        })
        geojson["features"].push(...county)

        L.geoJSON(geojson, {
          style: ALERT_STYLE[alert.alert_color],
        }).addTo(layer_group)
      })
    }
  }

  const toggle_layer = (_checked) => {
    if (_checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  watch(() => LayerStore.state.checked_layers.rainfall, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => AlertStore.state.alerts, (_alerts) => {
    draw_geojson(_alerts?.filter(alert => alert.event_code === 'rainfall'))
  }, {
    immediate: true
  })
}


const THUNDERSTORM_STYLE = {
  color: 'rgb(146, 120, 217)',
}

export const useThunderstormVLayer = (map, LayerStore, AlertStore) => {
  const layer_group = L.featureGroup()

  const draw_geojson = (alerts) => {
    layer_group.clearLayers()
    if (alerts && alerts.length) {
      alerts.forEach(alert => {
        let geojson = {
          "type": "FeatureCollection",
          "features": alert.area_geojson
        }

        L.geoJSON(geojson, {
          style: THUNDERSTORM_STYLE,
        }).addTo(layer_group)
      })
    }
  }

  const toggle_layer = (checked) => {
    if (checked) {
      map.addLayer(layer_group)
    }
    else {
      map.removeLayer(layer_group)
    }
  }

  watch(() => LayerStore.state.checked_layers.thunderstorm, (_checked) => {
    toggle_layer(_checked)
  }, {
    immediate: true
  })

  watch(() => AlertStore.state.alerts, (_alerts) => {
    draw_geojson(_alerts?.filter(alert => alert.event_code === 'thunderstorm'))
  }, {
    deep: true,
    immediate: true
  })
}
