import { FishingHarborRepo } from '@/repositories/fishing_harbor.js'

import { reactive, readonly, provide, watch, markRaw } from 'vue'

export const useFishingHarborStore = (LayerStore) => {
  const state = reactive({
    features: null,
  })

  const get_features = async () => {
    const payload = await FishingHarborRepo.allowed_fishing_harbor_fishing.get()
    state.features = markRaw(payload.data)
    set_layer_loading(false)
  }

  const set_layer_loading = (val) => LayerStore.set_loading(val, 'fishing_harbor')

  /* watch */
  watch(() => LayerStore.state.checked_layers.fishing_harbor, (is_checked) => {
    if (is_checked && !state.features) {
      get_features()
    }
  }, {
    immediate: true
  })


  const store = {
    state: readonly(state),
    get_features
  }

  provide('FishingHarborStore', store)

  return store
}
