import { watch, computed } from 'vue'

export const useTidalVLayer = (map, DetailStore, TidalStore) => {
  const layer_group = L.featureGroup().addTo(map)

  const icon = L.icon({
    iconUrl: require('@/assets/imgs/tidal.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
  })

  const icon_in_collections = L.icon({
    iconUrl: require('@/assets/imgs/tidal.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    shadowUrl: require('@/assets/imgs/check_hook.png'),
    shadowSize: [20, 20],
    shadowAnchor: [0, 20],
  })

  // 特殊潮汐站
  const wanted_sites = [
    {type_en: "tidal_station", name: "新北市淡水區", lat: 25.0727, lon:121.6595, unique_key: "潮汐站_新北市淡水區_25.07270_121.6595"},
    {type_en: "tidal_station", name: "新北市淡水區", lat: 25.00247, lon:121.53299, unique_key: "潮汐站_新北市淡水區_25.00247_121.53299"},
    {type_en: "tidal_station", name: "新北市淡水區", lat: 25.0998, lon:121.5302, unique_key: "潮汐站_新北市淡水區_25.09980_121.53020"},
  ]

  const create_cluster_group = () => {
    return L.markerClusterGroup({
      maxClusterRadius: 60,
      // Disable hover polygons
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: `<div class="relative bg-tidal/50 rounded-full h-full w-full flex items-center justify-center">
            <div class="bg-tidal text-white text-sm rounded-full h-4/5 w-4/5 flex items-center justify-center">
              ${count}
            </div>
          </div>`,
          className: '',
          iconSize: [36, 36],
        })
      }
    })
  }

  const draw_marker = (features) => {
    const cluster_group = create_cluster_group()
    layer_group.clearLayers()
    if (features) {
      for (let feature of features) {
        let tidal_marker = L.marker({
          lat: feature.lat,
          lon: feature.lon,
        }, {
          shadowPane: 'favoritesPane',
          icon: get_icon(feature.unique_key)
        }).addTo(cluster_group)

        tidal_marker.on('click', (e) => {
          // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
          if (!e.originalEvent?.isTrusted) return

          if (is_checked(feature.unique_key)) {
            DetailStore.remove_temp_selected_features(feature)
            tidal_marker.setIcon(icon)
          } else {
            DetailStore.add_temp_selected_features(feature)
            tidal_marker.setIcon(icon_in_collections)
          }
        })

        // 特殊潮汐站
        for (let wanted_site of wanted_sites) {
          if (feature.name === wanted_site.name) {
            wanted_site.id = feature.id
            let tidal_marker = L.marker({
              lat: wanted_site.lat,
              lon: wanted_site.lon,
            }, {
              opacity: 0.6,
              icon: icon
            }).addTo(layer_group)
            tidal_marker.on('click', (e) => {
              // 為了處理部分瀏覽器會觸發點擊兩次的問題，這裡擋掉其中一個
              if (!e.originalEvent?.isTrusted) return

              if (is_checked(wanted_site.unique_key)) {
                DetailStore.remove_temp_selected_features(wanted_site)
                tidal_marker.setIcon(icon)
              } else {
                DetailStore.add_temp_selected_features(wanted_site)
                tidal_marker.setIcon(icon_in_collections)
              }
            })
          }
        }
        layer_group.addLayer(cluster_group)
      }
    }
  }

  if (!TidalStore.state.features) {
    TidalStore.get_features()
  }

  const is_checked = (unique_key) => {
    return DetailStore.state.temp_unique_key_properties_mapping[unique_key] ? true : false
  }

  const get_icon = unique_key => {
    if (is_checked(unique_key)) {
      return icon_in_collections
    }
    return icon
  }

  watch(() => TidalStore.state.features, (_features) => {
    draw_marker(_features)
  }, {
    immediate: true
  })
}
